import dock from '../../modules/dock'
import '../../services/Menu'
import '../../services/Tab'
import './dockHeader.scss'
import headerTemplate from './dockHeader.html'

dock.directive('dockHeader', /* @ngInject */ function (Menu, Tab) {
    return {
        restrict: 'E',
        scope: {
            onTabSelect: '=',
        },
        transclude: true,
        template: headerTemplate,
        link(scope, element, attributes) {
            scope.borderless = 'borderless' in attributes
            scope.breadcrumbs = Menu.activeItem.getBreadcrumbs()
            scope.Tab = Tab
        },
        controller($scope, $transclude) {
            // Check if we have transclusion so we can prevent empty transclusion whitespace in the template
            $scope.hasTransclusion = false

            $transclude(function (transclusion) {
                if (transclusion.length > 0) {
                    $scope.hasTransclusion = true
                }
            })
        },
    }
})
