import '../../factories/api'
import '../../services/Retailer'
import dock from '../../modules/dock'

/* eslint-disable */
const controllerName = 'PrintLabelsCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    api,
    $uibModalInstance,
    Retailer,
    actionList,
) {
    $scope.sessionId = Retailer.getSessionId()
    $scope.actionList = actionList
    $scope.length = {
        value: $scope.actionList.length,
    }
    $scope.state = {
        default: true,
        processing: false,
        processed: false,
        error: false,
    }

    $scope.close = function() {
        $uibModalInstance.close()
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss()
    }

    $scope.escape = function(n) {
        return encodeURIComponent(n)
    }

    $scope.doPrintLabels = function() {
        var ids = []
        $scope.state['default'] = false
        $scope.state['processing'] = true

        angular.forEach($scope.actionList, function(item) {
            ids.push(item.id)
        })

        api.products
            .printLabels({
                productIds: ids,
            })
            .then(function(response) {
                $scope.state['processed'] = true
                $scope.state['error'] = false
                $scope.fileId = response.data.fileId
            })
            .catch(function() {
                $scope.state['processed'] = false
                $scope.state['error'] = true
            })
            .finally(function() {
                $scope.state['processing'] = false
            })
    }
})
