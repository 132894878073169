/* eslint-disable */
/**
 * A handler for a meta info field
 */
export default class MetaInfoField {
    constructor(metaInfoField) {
        this._originalProperties = metaInfoField
        this.code = this._originalProperties.code
        this.type = this._originalProperties.displayType
        this.mutable = this._originalProperties.mutable
        this.values = this._originalProperties.selectionValues
    }

    /**
     * Gets the possible values for a meta info field
     *
     * @return {Object[]} An array of the possible values of a meta info field
     */
    getValues() {
        return this.values.slice()
    }

    /**
     * Returns the selection value corresponding to a selection value code
     * @param {String} code The selection value code
     * @return {Object[]} The selection value corresponding with the code
     */
    getValue(code) {
        return this.getValues().find(value => value.code === code)
    }

    /**
     * Returns all selection values' codes
     *
     * @return {String[]} An array with all the codes
     */
    getCodes() {
        return this.getValues().map(value => value.code)
    }

    /**
     * Returns all selection values' names
     *
     * @return {String[]} An array with all the names
     */
    getNames() {
        return this.getValues().map(value => value.naam)
    }

    /**
     * Returns the name corresponding to a selection value code
     *
     * @param {String} code The selection value code
     * @return {String} The name corresponding with the code
     */
    getName(code) {
        return this.getValue(code).naam
    }

    /**
     * Function that gets called after the meta info constructor has ran, and thus all of the constructors for the
     * meta info fields were ran.
     */
    initialize() {}
}
