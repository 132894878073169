import dock from '../../../modules/dock'
import template from './photos.html'

dock.directive('productSectionPhotos', function () {
    return {
        restrict: 'A',
        scope: false,
        template,
    }
})
