import dock from '../../modules/dock'
import MetaInfo from './MetaInfo'
import { preferredLanguage } from '../../shared/constants/language'

/* eslint-disable */
/**
 * Angular factory for using the meta info fields from DOCK
 */
dock.factory('metaInfo', /* @ngInject */ function ($q, api) {
    let metaInfo = new Map()
    let pendingRequest = new Map()

    /**
     * Loads the meta info fields if they haven't been loaded yet
     *
     * @param {String} language The metaInfo language
     * @return {Promise} Always returns a promise that resolves when the request is finished. If the request already has
     * been finished it returns a promise that immediately resolves with the meta info fields. If a request is pending
     * and the fields have already been loaded, the pending request is returned
     */
    function load(language = preferredLanguage) {
        if (pendingRequest.has(language)) {
            return pendingRequest.get(language)
        }

        if (isLoaded(language)) {
            return $q.resolve(metaInfo.get(language))
        }

        return reload(language)
    }

    /**
     * Reloads the meta info fields
     *
     * @param {String} language The metaInfo language
     * @return {Promise} Returns a promise that resolves with the meta info
     */
    function reload(language = preferredLanguage) {
        return pendingRequest
            .set(language, api.metaInfo(language)
                .then(response => {
                    pendingRequest.delete(language)

                    return metaInfo
                        .set(language, new MetaInfo(response.data))
                        .get(language)
                })
            )
            .get(language)
    }

    /**
     * A synchronous version of {@link load} which returns the metaInfo object. If metaInfo isn't loaded it throws an
     * error
     *
     * @param {String} language The metaInfo language
     * @return {MetaInfo} Returns the metaInfo object
     */
    function getLoaded(language = preferredLanguage) {
        if (!isLoaded(language)) {
            throw new Error('Meta info has not been loaded yet.')
        }

        return metaInfo.get(language)
    }

    /**
     * Checks if metaInfo has been initialized already
     *
     * @param {String} language The metaInfo language
     * @return {Boolean} True if it has been loaded, false otherwise
     */
    function isLoaded(language = preferredLanguage) {
        return metaInfo.has(language)
    }

    /**
     * Deletes the registered metaInfo
     * @param {String} [language] The metaInfo language
     */
    function clear(language) {
        if (language) {
            metaInfo.delete(language)
        } else {
            metaInfo.clear()
        }
    }

    return {
        load,
        reload,
        getLoaded,
        isLoaded,
        clear,
    }
})
