import '../../factories/api'
import dock from '../../modules/dock'

/* eslint-disable */
const controllerName = 'DeleteCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    api,
    $uibModalInstance,
    actionList,
    changeTracker,
) {
    var constants = {
        STATE: {
            READY: 0,
            PROCESSING: 1,
            PROCESSED: 2,
            PROCESS_ERROR: 3,
        },
    }

    $scope.constants = constants
    $scope.state = constants.STATE.READY
    $scope.actionList = actionList

    $scope.errorInfo = {
        errors: [],
        products: [],
    }

    $scope.close = function() {
        $uibModalInstance.close()
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss()
    }

    var progressProduct = function(i, callback) {
        if ($scope.actionList[i]) {
            var product = angular.copy($scope.actionList[i]).getRequestProduct()

            $scope.progress = {
                product: product,
                index: i,
            }
            $scope.progressPerc = Math.floor(
                (i / $scope.actionList.length) * 100,
            )

            api.products
                .deleteProduct(product)
                .catch(function(error) {
                    $scope.errorInfo.errors.push(error)
                    $scope.errorInfo.products.push(product)
                })
                .finally(function() {
                    var j = Number(i) + 1
                    changeTracker.markChanged()
                    progressProduct(j, callback)
                })
        } else {
            callback()
        }
    }

    $scope.doDelete = function() {
        $scope.state = constants.STATE.PROCESSING
        $scope.progressPerc = 0

        // synchronise the behaviour so we can progress through the different products.
        progressProduct(0, function() {
            if ($scope.errorInfo.errors.length > 0) {
                $scope.state = constants.STATE.PROCESS_ERROR
            } else {
                $scope.state = constants.STATE.PROCESSED
            }
        })
    }
})
