import dock from '../modules/dock'
import { dutch, english } from '../shared/constants/language'

dock.config(/* @ngInject */ function (moment) {
    const momentLanguageDutch = {
        calendar: {
            lastDay: '[Gisteren]',
            sameDay: '[Vandaag]',
            nextDay: '[Morgen]',
            lastWeek: '[Afgelopen] dddd',
            nextWeek: '[Aankomende] dddd',
            sameElse: 'LL',
        },
    }

    const momentLanguageEnglish = {
        calendar: {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: '[Last] dddd',
            nextWeek: '[Next] dddd',
            sameElse: 'LL',
        },
    }

    // Let moment calender exclude certain items
    moment.updateLocale(dutch, momentLanguageDutch)
    moment.updateLocale(english, momentLanguageEnglish)
})
