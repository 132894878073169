const statusOnline = 'ONLINE'
const statusProcessing = 'PROCESSING'
const statusNew = 'NEW'
const statusAll = 'ALL'

export const ProductStatuses = Object.freeze({
    statusOnline,
    statusProcessing,
    statusNew,
    statusAll,
})

export const ProductStatusValues = Object.freeze({
    [statusOnline]: 'ONLINE',
    [statusProcessing]: 'FOTOGRAFIE',
    [statusNew]: 'NIEUW',
    [statusAll]: null,
})

export const ProductStatusTranslation = Object.freeze({
    [statusOnline]: 'products.status.online',
    [statusProcessing]: 'products.status.processing',
    [statusNew]: 'products.status.new',
    [statusAll]: 'products.status.all',
})
