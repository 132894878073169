import dock from '../modules/dock'

const storageKeys = {
    partialProduct: 'partial_product',
}

/* eslint-disable */
class PartialProductService {
    /**
     * Sets the partial product information and stores it in the session storage
     * @param {Object} partialProduct The partial product to save
     */
    set(partialProduct) {
        sessionStorage.setItem(
            storageKeys.partialProduct,
            angular.toJson(partialProduct),
        )
    }

    /**
     * Gets the partial product information from the session storage
     *
     * @return {Object|null} The saved partial product. Retunrs null if the partial product key did
     * not exist in the session storage
     */
    get() {
        return JSON.parse(sessionStorage.getItem(storageKeys.partialProduct))
    }

    /**
     * Clears the partial product from the session storage
     */
    clear() {
        sessionStorage.removeItem(storageKeys.partialProduct)
    }
}

dock.service('PartialProduct', function() {
    return new PartialProductService()
})
