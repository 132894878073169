export default function getMessagesFromObject(errorDataObject) {
    return Object.entries(errorDataObject).reduce((messages, [key, value]) => {
        if (value) {
            if (key === 'message' && typeof value === 'string') {
                messages.push(value)
            } else if (key === 'messages' && Array.isArray(value)) {
                messages.push(...value.filter((item) => typeof item === 'string'))
            } else if (typeof value === 'object') {
                messages.push(...getMessagesFromObject(value))
            }
        }
        return messages
    }, [])
}
