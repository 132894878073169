import angular from 'angular'
import dock from '../../modules/dock'

dock.directive('currencyInput', /* @ngInject */ function ($filter) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            const rawCurrencyFilter = $filter('currency')

            function stripCurrencyElements(string) {
                return string.replace('€', '').trim()
            }

            function correctThousandAndDecimal(string) {
                if (string === null) {
                    return ''
                }

                return (
                    string
                        // Replace comma's with dots
                        .replace(new RegExp(',', 'g'), '.')

                        // Split the string on dots
                        .split('.')

                        // Add a dot to only the last part
                        .map((value, index, array) =>
                            (index === array.length - 1 && array.length !== 1
                                ? `.${value}`
                                : value))

                        // Glue the string back together
                        .join('')
                )
            }

            function fromCurrency(string) {
                if (!string) {
                    return null
                }

                if (angular.isNumber(string)) {
                    return parseFloat(string)
                }

                return parseFloat(correctThousandAndDecimal(stripCurrencyElements(string)))
            }

            function currencyFilter(amount) {
                return correctThousandAndDecimal(rawCurrencyFilter(amount, '€'))
            }

            function toCurrency(string) {
                if (angular.isNumber(string)) {
                    return currencyFilter(string)
                }

                if (string === null) {
                    return ''
                }

                return currencyFilter(fromCurrency(string))
            }

            ngModel.$formatters.push(value => toCurrency(value))
            ngModel.$parsers.push(value => fromCurrency(value))

            ngModel.$formatters.push(value =>
                (value === null ? value : value / 100))
            ngModel.$parsers.push(value => Math.round(value * 100))

            element.on('focus', () => {
                ngModel.$setViewValue(ngModel.$viewValue === null
                    ? null
                    : stripCurrencyElements(ngModel.$viewValue))
                ngModel.$render()
            })

            element.on('blur', () => {
                ngModel.$setViewValue(toCurrency(ngModel.$viewValue))
                ngModel.$render()
            })
        },
    }
})
