import angular from 'angular'
import moment from 'moment'
import CombinedCategory from './CombinedCategory'
import noProductImage from '../../images/product_noimage.png'
import { normalizeSku, isContentApiSku } from '../functions/sku'
import { ProductVariant } from './ProductVariant'
import { privatePrefix, ResponseWrapper } from './ResponseWrapper'

const nonRequestProperties = ['variaties']

// Options for the PhotoSwipe galleries
export const defaultGalleryOptions = {
    history: false,
    shareEl: false,
    captionEl: false,
    barsSize: {
        top: 0,
        bottom: 'auto',
    },
}

export class Product extends ResponseWrapper {
    /**
     * Creates a new product model that can be used throughout DOCK
     *
     * @param {Object} data The data returned from a request that needs to be transferred to a product
     * @param {Boolean} wsnlProduct Whether the product is a wsnlProduct or not
     */
    constructor(data, wsnlProduct = false) {
        super(data)

        this[Product.getWsnlProductProperty()] = wsnlProduct
        this.setGalleryItems()
        this.setCombinedCategory()
        this.setLinkedProduct()
        this.setContentApiProperties()
        this.createVariants()
        this.setStock(data.amountInStock, data.amountOfVariants)
        this.calculatePriceStatus()
    }

    createVariants() {
        const variants = this[Product.getVariantsProductProperty()]

        if (angular.isUndefined(variants)) {
            return
        }

        this[Product.getVariantsProductProperty()] = variants.map((variant) => new ProductVariant(variant))
    }

    getRequestProduct() {
        return this.getRequestVersion(nonRequestProperties)
    }

    /**
     * Adds gallery options to the product for use with PhotoSwipe
     */
    setGalleryItems() {
        if (!this.media) {
            if (this.hoofdAfbeeldingMediumId) {
                this.media = [this.hoofdAfbeeldingMediumId]
            } else {
                this.media = []
            }
        }

        this[Product.getGalleryProperty()] = {}

        this[Product.getGalleryProperty()].items = this.media.map((image) => ({
            src: this.getImageSource(image, 0),
            msrc: this.getImageSource(image, 512),
            w: 920,
            h: 1380,
        }))

        this[Product.getGalleryProperty()].options = angular.copy(defaultGalleryOptions)
    }

    /**
     * Returns the source for a product image to be used within DOCK
     *
     * @param {String} filename The filename for the photo
     * @param {Number} [width=256] The width of the photo
     */
    getImageSource(filename = null, width = 256) {
        if (this.hoofdAfbeeldingMediumId) {
            let _filename = filename

            if (filename === null) {
                _filename = this.hoofdAfbeeldingMediumId
            }

            const resize = width !== 0

            return `/wsnl-rest/producten/${
                this.isWsnlProduct() ? 'wsnlproduct/' : ''
            }${this.pimProductId
                || this[Product.getIdProperty()]
                || this[Product.getLinkedProductProperty()]}/media/${_filename}?resize=${resize}&width=${width}`
        }

        return noProductImage
    }

    hasImage() {
        return this.media && this.media.length !== 0
    }

    /**
     * Gets the current gallery image source to display. It uses the gallery's index, so if a retailer uses the Photoswipe gallery and changes the image it
     * returns back to that image.
     *
     * @param {Number} width=256 The width of the photo
     */
    getGalleryImageSource(width = 256) {
        return this.getImageSource(
            this.media[this[Product.getGalleryProperty()].options.index],
            width,
        )
    }

    getSku() {
        return this[ProductVariant.getContentApiSkuProperty()]
    }

    getOriginalSku() {
        return this.SKU
    }

    getRegisterReference() {
        return this.retailerProductReferentie || '-'
    }

    /**
     * Returns whether the product is a WSNL product
     *
     * @return {Boolean}
     */
    isWsnlProduct() {
        return !!this[Product.getWsnlProductProperty()]
    }

    /**
     * Returns whether the product comes from the content API
     *
     * @return {Boolean}
     */
    fromContentApi() {
        return this[ProductVariant.getContentApiProperty()]
    }

    /**
     * Sets some content API related fields using the SKU to check if we're dealing with a content API product
     */
    setContentApiProperties() {
        this[ProductVariant.getContentApiProperty()] = isContentApiSku(this.SKU)
        this[ProductVariant.getContentApiSkuProperty()] = normalizeSku(this.SKU)
    }

    /**
     * Sets the combined category property
     */
    setCombinedCategory() {
        if (!(this.hoofdCategorie && this.categorie)) {
            return
        }

        this[Product.getCombinedCategoryProperty()] = new CombinedCategory(
            this.hoofdCategorie,
            this.categorie,
            this.subCategorie,
        ).generateTitleAndCode()
    }

    setLinkedProduct() {
        if (this.isWsnlProduct()) {
            this[Product.getLinkedProductProperty()] = this[
                Product.getIdProperty()
            ]
            delete this[Product.getIdProperty()]
        }
    }

    saleItem() {
        return this.activeSale() || this.expiredSale() || this.plannedSale()
    }

    activeSale() {
        // If we do have sale properties, but no range, we have an active sale
        if (this.hasSaleProperties() && this.saleKortingVan === null && this.saleKortingTot === null) {
            return true
        }

        if (this.hasSaleProperties()) {
            return moment().isBetween(this.saleKortingVan, this.saleKortingTot)
        }

        return false
    }

    plannedSale() {
        if (!this.hasSaleProperties()) {
            return false
        }

        return moment().isBefore(this.saleKortingVan)
    }

    expiredSale() {
        if (!this.hasSaleProperties()) {
            return false
        }

        return moment().isAfter(this.saleKortingTot)
    }

    hasSaleProperties() {
        if (this.priceRange?.highestPrice?.salePrice === null
            || this.priceRange?.highestPrice?.salePrice === undefined
            || this.priceRange?.lowestPrice?.salePrice === null
            || this.priceRange?.lowestPrice?.salePrice === undefined) {
            return false
        }

        return true
    }

    setStock(amountInStock = null, amountOfVariants = null) {
        this[Product.getAmountInStockProperty()] = amountInStock
        this[Product.getAmountOfVariantsProperty()] = amountOfVariants

        if (amountInStock === null || amountOfVariants === null) {
            this.setStockStatusNotLoaded()
        } else {
            this.setStockStatusLoaded()
        }
    }

    getStockStatus() {
        return this[Product.getStockStatusProperty()]
    }

    setStockStatus(status) {
        this[Product.getStockStatusProperty()] = status
    }

    setStockStatusNotLoaded() {
        this.setStockStatus('not-loaded')
    }

    stockNotLoaded() {
        return this.getStockStatus() === 'not-loaded'
    }

    setStockStatusLoading() {
        this.setStockStatus('loading')
    }

    stockLoading() {
        return this.getStockStatus() === 'loading'
    }

    setStockStatusError() {
        this.setStockStatus('error')
    }

    stockError() {
        return this.getStockStatus() === 'error'
    }

    setStockStatusLoaded() {
        this.setStockStatus('loaded')
    }

    stockLoaded() {
        return this.getStockStatus() === 'loaded'
    }

    setLastPriceEdit(lastEdit) {
        this[Product.getLastPriceEditProperty()] = lastEdit
    }

    getLastPriceEdit() {
        return this[Product.getLastPriceEditProperty()]
    }

    priceEditable() {
        const lastEdit = this[Product.getLastPriceEditProperty()]
        const thirtyDaysAgo = moment().subtract(30, 'days')

        if (!lastEdit) {
            return true
        }

        return moment(lastEdit).isSameOrBefore(thirtyDaysAgo)
    }

    priceChanged() {
        const original = this[Product.getOriginalProperty()]
        const variants = this[Product.getVariantsProductProperty()]

        const variantPriceChanged = variants.some((variant) => variant.priceChanged())

        const pricePropertyChanged = this.prijs !== original.prijs
                                     || this.manufacturerSuggestedRetailPrice !== original.manufacturerSuggestedRetailPrice

        const switchedToMsrp = original.prijs !== null
                               && this.prijs === null
                               && this.hasMsrp()

        const switchedFromMsrp = this.hadMsrp()
                                 && !this.hasMsrp()
                                 && this.prijs !== null

        // If the price properties have changes and we are not switching the MSRP setting we say the price has changed
        const baseProductPriceChanged = !switchedFromMsrp
                                        && !switchedToMsrp
                                        && pricePropertyChanged

        return baseProductPriceChanged || variantPriceChanged
    }

    toggleMsrp() {
        if (this.hasMsrp()) {
            this.prijs = this.manufacturerSuggestedRetailPrice
            delete this.manufacturerSuggestedRetailPrice
        } else if (angular.isNumber(this.prijs)) {
            this.manufacturerSuggestedRetailPrice = this.prijs
            this.prijs = null
        } else {
            this.manufacturerSuggestedRetailPrice = undefined
        }
    }

    hasMsrp(product = this) {
        return (
            Object.prototype.hasOwnProperty.call(
                product,
                'manufacturerSuggestedRetailPrice',
            ) && product.manufacturerSuggestedRetailPrice !== null
        )
    }

    hadMsrp() {
        return this.hasMsrp(this.getOriginal())
    }

    calculatePriceStatus() {
        if (this.priceRange?.lowestPrice !== undefined) {
            this.setPriceStatusLoaded()
            return
        }

        this.setPriceStatusNotLoaded()
    }

    getPrice() {
        if (!this.priceLoaded()) {
            return null
        }

        return {
            sale: this.saleItem() ? {
                percentage: this.saleKortingPercentage,
                from: this.saleKortingVan,
                to: this.saleKortingTot,
            } : null,
            range: this.priceRange,
        }
    }

    setPrice(priceProperties) {
        const keys = Object.keys(priceProperties)

        keys.forEach((key) => {
            this[key] = priceProperties[key]
        })

        if (this.priceRange?.regularPrice !== undefined) {
            this.setPriceStatusLoaded()
        }
    }

    getPriceStatus() {
        return this[Product.getPriceStatusProperty()]
    }

    setPriceStatus(status) {
        this[Product.getPriceStatusProperty()] = status
    }

    setPriceStatusNotLoaded() {
        this.setPriceStatus('not-loaded')
    }

    priceNotLoaded() {
        return this.getPriceStatus() === 'not-loaded'
    }

    setPriceStatusLoading() {
        this.setPriceStatus('loading')
    }

    priceLoading() {
        return this.getPriceStatus() === 'loading'
    }

    setPriceStatusError() {
        this.setPriceStatus('error')
    }

    priceError() {
        return this.getPriceStatus() === 'error'
    }

    setPriceStatusLoaded() {
        this.setPriceStatus('loaded')
    }

    priceLoaded() {
        return this.getPriceStatus() === 'loaded'
    }

    static getCombinedCategoryProperty() {
        return `${privatePrefix}combinedCategory`
    }

    static getGalleryProperty() {
        return `${privatePrefix}gallery`
    }

    static getWsnlProductProperty() {
        return `${privatePrefix}wsnlProduct`
    }

    static getVariantsProductProperty() {
        return 'variaties'
    }

    static getContentApiProperty() {
        return `${privatePrefix}fromContentApi`
    }

    static getContentApiSkuProperty() {
        return `${privatePrefix}contentApiSku`
    }

    static getLinkedProductProperty() {
        return 'gekoppeldProduct'
    }

    static getIdProperty() {
        return 'id'
    }

    static getAmountInStockProperty() {
        return 'amountInStock'
    }

    static getAmountOfVariantsProperty() {
        return 'amountOfVariants'
    }

    static getStockStatusProperty() {
        return `${privatePrefix}stockStatus`
    }

    static getLastPriceEditProperty() {
        return `${privatePrefix}lastPriceEdit`
    }

    static getCalculatedPriceProperty() {
        return `${privatePrefix}calculatedPrice`
    }

    static getPriceStatusProperty() {
        return `${privatePrefix}priceStatus`
    }
}
