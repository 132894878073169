export default class Environment {
    constructor() {
        this.environment = process.env.NODE_ENV
        this.devServer = process.env.LOCAL_DEVELOPMENT_SERVER
    }

    isDevelopment() {
        return this.environment === 'development'
    }

    isLocalDevelopmentServer() {
        return this.devServer
    }
}
