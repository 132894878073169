import dock from '../../modules/dock'
import loadingMessageTemplate from './dockLoadingMessage.html'

dock.directive('dockLoadingMessage', function () {
    return {
        restrict: 'E',
        replace: true,
        template: loadingMessageTemplate,
        scope: {
            requestCanceler: '=?',
            text: '=?',
            cancelText: '=?',
        },
    }
})
