/**
 * The MenuItem class defines a menu item and includes all information necessary to display it
 */
export default class MenuItem {
    /**
     * @param {String} translateKey The translation key used by angular-translate
     * @param {String} icon The Font Awesome icon identifier (including "fa-" prefix)
     * @param {String} route The route to link to when the user clicks the menu item
     * @param {Tab[]} tabs The tabs belonging to the menu item
     * @param {Object} [options] Additional options for the menuItem
     * @param {Boolean} [options.divider] Whether the menu item has a divider above it
     * @param {Boolean} [options.hidden] Whether the menu item is hidden
     * @param {String} [options.include] What template to include after the menu text
     * @param {MenuItem} [options.parent] The parent menu item
     * @param {String} [options.badge] The badge the menu item should display
     */
    constructor(translateKey, icon, route, tabs = [], options = {}) {
        this.translateKey = translateKey
        this.icon = icon
        this.name = route
        this.route = route ? `/#/${route}` : ''
        this.tabs = tabs
        this.options = options || {}
    }

    /**
     * Returns the root menu item by recursively checking if the menu item has a parent.
     *
     * @return {MenuItem} The root menu item
     */
    getRoot() {
        return this.options.parent ? this.options.parent.getRoot() : this
    }

    /**
     * Returns all parent items in order from root -> current menu item
     *
     * @return {MenuItem[]} An array of MenuItems from root -> current MenuItem
     */
    getBreadcrumbs() {
        const breadcrumbs = this.options.parent
            ? this.options.parent.getBreadcrumbs()
            : []
        breadcrumbs.push(this)
        return breadcrumbs
    }

    /**
     * Returns this menu item's tabs
     * @return {Tab[]}
     */
    getTabs() {
        return this.tabs
    }
}
