/**
 * A class that creates a timer (timeout or interval) that is automatically canceled when it gets
 * created. Helpful for services that expect a timeout or interval to exist and to be cancellable.
 */
export default class CanceledTimer {
    /**
     *
     * @param $timingProvider Either $interval or $timeout (or another service that has a cancel
     * API function
     * @returns {Promise} Returns the cancelled timer
     */
    constructor($timingProvider) {
        const timeout = $timingProvider()
        $timingProvider.cancel(timeout)
        return timeout
    }
}
