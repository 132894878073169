import dock from '../../modules/dock'

dock.directive('cannotContainMultipleCommasInSentence', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            ngModel.$validators.cannotContainMultipleCommasInSentence = function (modelValue) {
                // First we split on a dot, so we get all sentences and then we split on a comma to get all comma separated parts. If there are more than two
                // of those parts in one sentence we know there is more than one comma in a sentence
                return (
                    !modelValue ||
                    !modelValue
                        .split('.')
                        .some(sentence => sentence.split(',').length > 2)
                )
            }
        },
    }
})
