import angular from 'angular'
import dock from '../../modules/dock'
import filterBarTemplate from './dockFilterBar.html'
import './dockFilterBarItem'
import './dockFilterBar.scss'

dock.directive('dockFilterBar', function () {
    return {
        restrict: 'E',
        scope: {
            filterController: '=',
            allDisabled: '=?',
            options: '=?',
        },
        template: filterBarTemplate,
        link(scope) {
            /**
             * @typedef {Object} Options
             * @type {Object}
             * @property {Boolean} [deleteButton=True]
             * @property {Boolean} [searchButton=?] Whether the filter bar has a search button. Default is the inverse of the filterOnSelect config property for the filterController
             * @property {Boolean} [executeButton=?] Whether the filter bar has an execute button.
             * @property {Function} [onSearch=filterController.filter] What to do when the user presses the search button. Defaults to the 'filterController.filter' function
             */
            const defaultOptions = {
                deleteButton: true,
                searchButton: !scope.filterController.config.filterOnSelect,
                executeButton: false,
                onSearch: scope.filterController.filter,
            }

            // element.addClass('dock-filter-bar');
            scope.filters = scope.filterController.filters
            scope.options = angular.merge({}, defaultOptions, scope.options)
        },
    }
})
