/* global sessionStorage localStorage */
import angular from 'angular'
import dock from '../modules/dock'
import trimPath from '../shared/functions/trimPath'

dock.service('Pagination', /* @ngInject */ function ($location, Filters) {
    function getStorageKey(identifier) {
        const path = trimPath($location.path())
        return `pagination_${identifier}_${path}`
    }

    /**
     * Controller for pagination state
     *
     * @class
     * @param {PaginationControllerConfig} [config={}] The config for the pagination controller. If no config is
     * given it falls back to the defaults. The config is described in the typedef below
     * @constructor
     */
    function PaginationController(config) {
        this.data = null
        this.dataPaginated = null
        this.pageModel = null
        this.paginatedPage = null

        let itemsPerPageFilter = null
        const onItemsPerPageChangeCallbacks = []
        const onPageChangeCallbacks = []

        const currentPageIdentifier = 'current_page'
        const itemsPerPageIdentifier = 'items_per_page'

        /**
         * @typedef {Object} PaginationControllerConfig
         * @type {Object}
         * @property {Number} [maxItemsPerPage=10] The maximum amount of items to display per page. Note that this
         * property is overwritten with by the local storage once the user has selected one of the options
         * @property {Number[]} [itemsPerPageOptions=[5, 10, 20]] The options the user has as to how many
         * items should be displayed per page
         */
        const configDefaults = {
            maxItemsPerPage: 20,
            itemsPerPageOptions: [5, 10, 20],
        }

        this.config = angular.extend({}, configDefaults, config)

        /**
         * Paginates the given data using the current page
         *
         * @return {Object[]} The data paginated
         */
        this.paginate = function () {
            if (this.data === null) {
                return []
            }

            this.correctForMax()
            this.dataPaginated = this.getDataForPage(this.getPage())

            if (this.paginatedPage !== this.getPage()) {
                this.notifyPageChange(this.getPage())
                this.paginatedPage = this.getPage()
            }

            return this.dataPaginated
        }

        /**
         * Returns the data for a specific page.
         *
         * @param {Number} page The page number, starting with 1
         * @return {Object[]} The data for the given page
         */
        this.getDataForPage = function (page = 1) {
            return this.data.slice(
                (page - 1) * this.config.maxItemsPerPage,
                page * this.config.maxItemsPerPage,
            )
        }

        /**
         * Sets the page number in the session storage
         *
         * @param {Number} pageNumber The page number
         * @returns {PaginationController} Returns this to allow for chaining
         */
        this.setPage = function (pageNumber) {
            let _pageNumber = pageNumber

            if (pageNumber === null) {
                _pageNumber = 1
            }

            sessionStorage.setItem(
                getStorageKey(currentPageIdentifier),
                _pageNumber.toString(),
            )

            return this
        }

        /**
         * Gets the page number from the session storage
         *
         * @returns {Number|null} Returns the page number
         */
        this.getPage = function () {
            return (
                parseInt(
                    sessionStorage.getItem(getStorageKey(currentPageIdentifier)),
                    10,
                ) || null
            )
        }

        /**
         * Sets the data to paginate on
         *
         * @param {Object[]} data What data to set it to. If no array is given the data will be reset
         * @returns {PaginationController} Returns this to allow for chaining
         */
        this.setData = function (data) {
            if (!Array.isArray(data)) {
                this.data = null
                return this
            }

            this.data = data
            return this
        }

        /**
         * Deletes the search parameter for pagination
         *
         * @return {PaginationController} Returns this to allow for chaining
         */
        this.unset = function () {
            sessionStorage.removeItem(getStorageKey(currentPageIdentifier))
            return this
        }

        /**
         * Gets the filter object for the items per page filter
         *
         * @return {Filter} The items per page filter
         */
        this.getItemsPerPageFilter = function () {
            if (itemsPerPageFilter === null) {
                const itemsPerPageFilterConfig = {
                    options: {
                        default: this.config.maxItemsPerPage,
                        fixed: true,
                    },
                    search: false,
                    allowClear: false,
                    savePreference: false,
                }

                itemsPerPageFilter = new Filters.Filter(
                    'itemsPerPage',
                    'pagination.itemsPerPage',
                    null,
                    this.config.itemsPerPageOptions,
                    itemsPerPageFilterConfig,
                )

                itemsPerPageFilter.onSelect(this.notifyItemsPerPageChange)
            }

            return itemsPerPageFilter
        }

        /**
         * Creates a return value for callback registrations that enables
         * the removal of the registration
         */
        this.createOffSelect = function (callbacks, callback) {
            return {
                offSelect() {
                    const callbackIndex = callbacks.indexOf(callback)

                    if (callbackIndex === -1) {
                        return
                    }

                    callbacks.splice(callbackIndex, 1)
                },
            }
        }

        /**
         * Register a callback that gets fired when the items per page option is changed
         *
         * @param {Function} callback The function that gets called
         * @return {Object} An object with the offSelect function to unregister the callback
         */
        this.onItemsPerPageChange = function (callback) {
            onItemsPerPageChangeCallbacks.push(callback)

            return this.createOffSelect(onItemsPerPageChangeCallbacks, callback)
        }

        /**
         * Notifies all callback listeners of a items per page change
         */
        this.notifyItemsPerPageChange = function (itemsPerPage) {
            onItemsPerPageChangeCallbacks.forEach(function (callback) {
                callback(itemsPerPage)
            })
        }

        /**
         * Register a callback that gets fired when the current page is changed
         *
         * @param {Function} callback A callback that gets called when the current
         * page is changed. It's called with the current page as argument
         * @return {Object} An object with the offSelect function to unregister the callback
         */
        this.onPageChange = function (callback) {
            onPageChangeCallbacks.push(callback)

            return this.createOffSelect(onPageChangeCallbacks, callback)
        }

        /**
         * Notifies all callback listeners of a page change
         */
        this.notifyPageChange = function (page) {
            onPageChangeCallbacks.forEach(function (callback) {
                callback(page)
            })
        }

        /**
         * Checks if the current page is higher than the max page, and if so, sets it to the max page.
         *
         * @return {PaginationController} Returns this to allow for chaining
         */
        this.correctForMax = function () {
            const maxPage = Math.ceil(this.data.length / this.config.maxItemsPerPage)
            const minPage = 1

            if (this.getPage() < minPage) {
                this.setPage(minPage)
            }

            if (this.getPage() > maxPage) {
                this.setPage(maxPage)
            }

            return this
        }

        /**
         * Gets the preference value for a certain identifier
         *
         * @param {String} identifier The property identifier to get the preference for
         * @return {Object} The value saved in the local storage. Returns null if no value was set
         */
        this.getPreference = function (identifier) {
            return JSON.parse(localStorage.getItem(getStorageKey(identifier)))
        }

        /**
         * Sets the preference value for a certain identifier
         *
         * @param {String} identifier The property identifier to get the preference for
         * @param {Object} value The value to save in the local storage
         */
        this.setPreference = function (identifier, value) {
            if (value === undefined) {
                return
            }

            localStorage.setItem(getStorageKey(identifier), value.toString())
        }

        if (this.getPage() === null) {
            this.setPage(1)
        }

        this.pageModel = this.getPage()

        this.config.maxItemsPerPage = this.getPreference(itemsPerPageIdentifier)
            || this.config.maxItemsPerPage

        this.onItemsPerPageChange((itemsPerPage) => {
            this.config.maxItemsPerPage = itemsPerPage
            this.setPreference(itemsPerPageIdentifier, itemsPerPage)
        })
    }

    this.Controller = PaginationController
})
