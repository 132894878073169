import dock from '../../modules/dock'

/* eslint-disable object-shorthand */
dock.directive('setReadOnly', function () {
    return {
        restrict: 'A',
        controller: /* @ngInject */ function ($scope, $attrs, $parse) {
            function isReadOnly() {
                return !!$parse($attrs.setReadOnly)($scope)
            }

            $scope.readOnly = isReadOnly()

            return { isReadOnly }
        },
    }
})
