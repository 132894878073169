import dock from '../../modules/dock'

dock.directive('cannotContainEan', /* @ngInject */ function ($parse) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            const ean = $parse(attributes.cannotContainEan)

            ngModel.$validators.cannotContainEan = function (modelValue) {
                return (
                    !modelValue ||
                    !ean(scope) ||
                    !modelValue.includes(ean(scope))
                )
            }
        },
    }
})
