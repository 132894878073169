import dock from '../../modules/dock'

dock.directive('includeReplace', function () {
    return {
        require: 'ngInclude',
        restrict: 'A',
        link(scope, el) {
            el.replaceWith(el.children())
        },
    }
})
