import dock from '../../modules/dock'
import selectionBarTemplate from './dockSelectionBar.html'
import './dockSelectionBar.scss'
import { Product } from '../../shared/classes/Product'

dock.directive('dockSelectionBar', function () {
    return {
        restrict: 'E',
        template: selectionBarTemplate,
        scope: {
            controller: '=selectionController',
            autoCollapse: '<?',
        },
        link: (scope) => {
            scope.autoCollapse = !!scope.autoCollapse
        },
    }
})
