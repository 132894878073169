export class ChangeTracker {
    constructor() {
        this.changed = false
    }

    markChanged() {
        this.changed = true
    }

    wasChanged() {
        return this.changed
    }
}
