import dock from '../../modules/dock'
import searchableTemplate from './searchable.html'
import './searchable.scss'

/* eslint-disable */
dock.directive('searchable', function() {
    return {
        restrict: 'A',
        transclude: true,
        scope: {
            searchFilter: '=',
            searchPlaceholder: '=',
        },
        template: searchableTemplate,
        link(scope) {
            scope.$watch(
                () => scope.searchFilter.getSelected(false, false),
                searchString => {
                    if (searchString !== undefined) {
                        scope.searchFilter.setSelected(searchString)
                    }
                },
            )
        },
    }
})
