export default class Version {
    constructor(versionString) {
        try {
            const versionParts = versionString
                .split('.')
                .map(numberString => parseInt(numberString, 10))

            if (versionParts.length !== 3) {
                throw new Error('Version number must contain three parts separated by a dot')
            }

            [this.major, this.minor, this.patch] = versionParts
        } catch (error) {
            const versionError = new Error(`Unable to parse version "${versionString.toString()}" due to ${error}`)
            console.error(versionError)
            throw versionError
        }
    }

    toString() {
        return [this.major, this.minor, this.patch].join('.')
    }

    equals(version) {
        return this.toString() === version.toString()
    }
}
