import MetaInfoField from './MetaInfoField'
import registry from '../registry'

const DEPRECATED_FIELD = 'seizoen.vervallen'

export class Season {
    constructor(season) {
        this.code = season.code
        this.name = season.naam
    }

    getSeasonCode() {
        return this.code.substring(0, 2)
    }

    isSpringSummerSeason() {
        return this.getSeasonCode() === 'ss'
    }

    isAutumnWinterSeason() {
        return this.getSeasonCode() === 'aw'
    }

    isUndefined() {
        return !this.code
    }
}

/**
 * A meta info handler for the season field. Adds useful functionality
 */
export class SeasonHandler extends MetaInfoField {
    constructor(metaInfoField) {
        super(metaInfoField)
        this.deprecated = []
    }

    initialize(metaInfo) {
        this._createSeasonMap()

        if (metaInfo.fieldExists(DEPRECATED_FIELD)) {
            this.deprecated = metaInfo
                .field(DEPRECATED_FIELD)
                .getValues()
                .map(({ code }) => code)
        }
    }

    getValues(deprecated = true) {
        return this.values
            .filter(({ code }) => deprecated || !this.deprecated.includes(code))
            .slice()
    }

    getValue(seasonCode) {
        return this.seasonMap.get(seasonCode)
    }

    getName(seasonCode) {
        if (seasonCode === null) {
            return ''
        }

        return this.getValue(seasonCode).name
    }

    getCodes(deprecated) {
        return this.getValues(deprecated).map(({ code }) => code)
    }

    getNonDeprecatedCodes() {
        return this.getCodes(false)
    }

    _createSeasonMap() {
        this.seasonMap = new Map()

        this.getValues().forEach((season) => {
            this.seasonMap.set(season.code, new Season(season))
        })
    }
}

registry.registerAlias('seizoen', 'season')
export default registry.registerHandler('season', SeasonHandler)
