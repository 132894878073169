import '../factories/api'
import { LoginError } from '../services/Retailer'
import dock from '../modules/dock'

const controllerName = 'LoginCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $rootScope,
    $scope,
    $translate,
    $location,
    Retailer,
) {
    $scope.validate = false
    $scope.loginError = false
    $scope.loading = false
    $scope.user = $scope.user || { username: '', password: '' }
    $scope.LoginError = LoginError

    $scope.login = function (username, password, keepLoggedIn) {
        $scope.validate = true

        if (!$scope.loginForm.$valid) {
            return
        }

        $scope.loading = true

        Retailer.login(username, password, keepLoggedIn)
            .then(() => {
                $scope.user.password = ''
                $location.path(Retailer.getBasePath())
            })
            .catch((error) => {
                $scope.loginError = error
            })
            .finally(() => {
                $scope.loading = false
            })
    }
})
