import angular from 'angular'

export const privatePrefix = '_'

export class ResponseWrapper {
    constructor(data) {
        // Copy attributes
        Object.keys(data).forEach((key) => {
            this[key] = data[key]
        })

        this.setOriginal(data)
        this.setMeta({})
    }

    getRequestVersion(excludeProperties = []) {
        const requestBody = angular.copy(this)

        Object.keys(requestBody).forEach((key) => {
            if (
                key.startsWith(privatePrefix)
                || excludeProperties.includes(key)
            ) {
                delete requestBody[key]
            }
        })

        return requestBody
    }

    setOriginal(data) {
        this[ResponseWrapper.getOriginalProperty()] = data
    }

    getOriginal() {
        return this[ResponseWrapper.getOriginalProperty()]
    }

    setMeta(meta) {
        this[ResponseWrapper.getMetaProperty()] = meta
    }

    setMetaProperties(properties) {
        const meta = this[ResponseWrapper.getMetaProperty()]

        this[ResponseWrapper.getMetaProperty()] = {
            ...meta,
            ...properties,
        }
    }

    getMeta() {
        return this[ResponseWrapper.getMetaProperty()]
    }

    static getOriginalProperty() {
        return `${privatePrefix}original`
    }

    static getMetaProperty() {
        return `${privatePrefix}meta`
    }
}
