import dock from '../modules/dock'
import { menuItems } from '../shared/constants/menuItems'

dock.service('Menu', /* @ngInject */ function () {
    this.items = menuItems
    this.activeItem = null

    this.update = function (newRoute) {
        this.activeItem = newRoute.$$route.menuItem
    }
})
