import MenuItem from '../classes/MenuItem'
import { OrdersTabs } from '../../controllers/OrdersCtrl'
import { ReturnsTabs } from '../../controllers/ReturnsCtrl'
import { ProductsTabs } from '../../controllers/ProductsCtrl'

const orders = new MenuItem('menu.orders', 'fa-truck', 'orders', [
    OrdersTabs.NEW,
    OrdersTabs.CLOSED,
    OrdersTabs.SEARCH,
], {
    badge: 'orders',
})

const returns = new MenuItem('menu.returns', 'fa-undo', 'returns', [
    ReturnsTabs.NEW,
    ReturnsTabs.CLOSED,
], {
    badge: 'returns',
})

const products = new MenuItem('menu.products', 'fa-tags', 'products', [
    ProductsTabs.ONLINE,
    ProductsTabs.PROCESSING,
    ProductsTabs.NEW,
    ProductsTabs.ALL,
])

const link = new MenuItem('menu.link', 'fa-link', 'link')
const product = new MenuItem('menu.addProduct', 'fa-plus', 'product')
const importProducts = new MenuItem('menu.import', 'fa-upload', 'import', [], { hidden: true })
const documentation = new MenuItem('menu.documentation', 'fa-book', 'documentation', [], { divider: true })
const editProduct = new MenuItem('menu.editProduct', 'fa-pencil', null, [], { parent: products })
const linkProduct = new MenuItem('menu.linkProduct', 'fa-recycle', null, [], { parent: link })
const reportReturn = new MenuItem('menu.reportReturn', 'fa-flag', null, [], { parent: returns })
const reportReturnOrder = new MenuItem('menu.reportReturn', 'fa-flag', null, [], { parent: orders })

// The DOCK menu items
export const menuItem = {
    orders,
    returns,
    reportReturn,
    reportReturnOrder,
    products,
    link,
    linkProduct,
    product,
    editProduct,
    importProducts,
    documentation,
}

// The DOCK menu items in an array
export const menuItems = Object.values(menuItem)
