import '../factories/api'
import '../factories/metaInfo'
import '../services/Update'
import '../services/Menu'
import '../services/Tab'
import '../services/Admin'
import '../services/Intercom'
import '../services/Analytics'
import '../services/Language'
import { RetailerEvent } from '../services/Retailer'
import dock from '../modules/dock'
import onTranslationsLoaded from '../factories/translateRaceLoader/translateRaceLoader'
import environment from '../shared/instances/environment'

/* eslint-disable */
const controllerName = 'AppCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $rootScope,
    $scope,
    $translate,
    $uibModal,
    $location,
    $routeParams,
    $timeout,
    amMoment,
    tmhDynamicLocale,
    api,
    metaInfo,
    Retailer,
    Menu,
    Tab,
    Admin,
    Intercom,
    Language,
    Analytics,
) {
    $scope.loading = {}
    $scope.emailSend = $routeParams.send

    $rootScope.retailer = Retailer
    $rootScope.menu = Menu
    $rootScope.admin = Admin
    $rootScope.language = Language
    $rootScope.user = {}
    $rootScope.user.filters = {}

    // Load all language assets
    Language.set(Language.get())

    // On a successful login, load meta info
    $rootScope.$on(RetailerEvent.LOGIN_SUCCESSFUL, ($event, { name }) => {
        loadMetaInfo()
        $rootScope.currentRetailer = name

        /* Enable Intercom and send user ID to Google Analytics
         */
        if (!environment.isLocalDevelopmentServer()) {
            Intercom.boot()
        }

        Analytics.sendUserId()

        /* Refresh the translations, because we now also need meta info translations
         * This calls the translateReferenceLoader again
         */
        $translate.refresh()
    })

    $rootScope.$on(RetailerEvent.LOGOUT_SUCCESSFUL, clearAndToLogin)
    $rootScope.$on(RetailerEvent.LOGGED_OUT, clearAndToLogin)

    // On a successful route change, request the order count if the user is not a brand owner and is logged in
    $rootScope.$on('$routeChangeSuccess', ($event, newRoute) => {
        Menu.update(newRoute)
        Tab.update()

        if (Retailer.isLoggedIn()) {
            getOrderCount()
            Intercom.update()
        }
    })

    // Refresh the translations when new translations are loaded
    onTranslationsLoaded(() => $translate.refresh())

    $scope.escape = function(n) {
        return encodeURIComponent(n)
    }

    function loadMetaInfo() {
        metaInfo.load(Language.get()).catch(error => {
            console.error(
                'Meta info failed to load. This can cause some parts of DOCK to work improperly. The following error was given:',
                error,
            )
        })
    }

    function getOrderCount() {
        api.orders.newOrderCount().then(function(response) {
            $rootScope.newOrderCount = response.data.newOrdersCount
            $rootScope.newReturnsCount = response.data.openRMAsCount
        })
    }

    // When the user is logged out, remove sensitive data from the scope
    function clearAndToLogin() {
        $rootScope.currentRetailer = null
        $rootScope.newOrderCount = null
        $rootScope.newReturnsCount = null
        metaInfo.clear()
        $location.path('login')
        Intercom.shutdown()
    }

    $scope.toggleMenu = function() {
        $scope.isMenuOpen = !$scope.isMenuOpen
    }
})
