import angular from 'angular'
import noUiSlider from 'angularjs-nouislider'
import 'nouislider/distribute/nouislider.css'
import 'angular-cookies'
import 'angular-route'
import 'angular-touch'
import 'angular-sanitize'
import 'angular-messages'
import 'angular-translate'
import 'angular-translate-storage-local'
import 'angular-translate-storage-cookie'
import 'angular-translate-loader-static-files'
import 'angular-dynamic-locale'
import 'angular-lazy-image'
import 'angular-lazy-image/dist/lazy-image-style.css'
import 'angular-ui-bootstrap'
import 'angular-toastr'
import 'angular-toastr/dist/angular-toastr.css'
import 'angulartics'
import 'angulartics-google-tag-manager'
import 'ng-file-upload'
import 'ng-focus-if'
import 'ui-select'
import 'ui-select/dist/select.css'
import 'autoheight/src/angular-autoheight'
import '../photoswipe'

export default angular.module('dock', [
    'ngTouch',
    'ngCookies',
    'ngRoute',
    'ngSanitize',
    'ngMessages',
    'ngFileUpload',
    'tmh.dynamicLocale',
    'pascalprecht.translate',
    'ui.bootstrap',
    'ui.select',
    'afkl.lazyImage',
    'angularMoment',
    'angulartics',
    'angulartics.google.tagmanager',
    'photoswipe',
    'focus-if',
    'toastr',
    'autoheight',
    noUiSlider,
])
