import dock from '../../modules/dock'

dock.directive('cannotContainBrand', /* @ngInject */ function ($parse) {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: false,
        link(scope, element, attributes, ngModel) {
            const brand = $parse(attributes.cannotContainBrand)

            ngModel.$validators.cannotContainBrand = function (modelValue) {
                return (
                    !modelValue ||
                    !brand(scope) ||
                    !modelValue
                        .toLowerCase()
                        .includes(brand(scope).toLowerCase())
                )
            }
        },
    }
})
