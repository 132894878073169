import angular from 'angular'
import dock from '../../modules/dock'
import fitField from '../../factories/metaInfo/handlers/fitHandler'
import productEditorTemplate from './productEditor.html'
import { Product } from '../../shared/classes/Product'
import '../checkbox/checkbox'
import '../productPrice/productPrice'

dock.directive('productEditor', () => ({
    require: ['^^productsEditor'],
    restrict: 'E',
    template: productEditorTemplate,
    scope: {
        product: '=',
        productSizeIds: '=',
    },
    link: {
        pre: (scope) => {
            scope.product = new Product(scope.product)
        },
        post: (scope, element, attributes, controllers) => {
            const productsEditor = controllers[0]
            element.addClass('dock-table-row dock-table-inline-rows')
            scope.showVariations = true

            scope.originalProduct = angular.copy(scope.product)

            // Register the get changed variations function
            productsEditor.registerGetChangedVariations(() => scope.product.variaties)
        },
    },
    /* eslint-disable-next-line object-shorthand */
    controller: /* @ngInject */ function ($scope, metaInfo, Language) {
        $scope.returnToStock = true
    },
}))
