import '../../factories/api'
import dock from '../../modules/dock'
import getMessagesFromObject from '../../shared/functions/getMessagesFromObject'

/* eslint-disable */
// The possible deficiency form states
export const ReportMpnFormState = Object.freeze({
    IDLE: Symbol('idle'), // The form is ready to be filled in
    PROCESSING: Symbol('processing'), // The deficiency is being processed
    PROCESS_ERROR: Symbol('process_error'), // An error occurred while processing the deficiency
})

const controllerName = 'ReportMpnCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    $q,
    $translate,
    api,
    $uibModalInstance,
    product,
    toastr,
) {
    $scope.product = product
    $scope.State = ReportMpnFormState
    $scope.state = ReportMpnFormState.IDLE
    $scope.requestCanceler = null

    $scope.reportMpn = {
        newMpn: '',
    }

    $scope.processMpn = function () {
        $scope.requestCanceler = $q.defer()
        $scope.state = ReportMpnFormState.PROCESSING

        const suggestedMpn = $scope.reportMpn.newMpn ? $scope.reportMpn.newMpn : undefined

        // Register order item as deficient
        api.products.reportIncorrectMpn(product.templateId, suggestedMpn, $scope.requestCanceler)
            .then((response) => {
                if (
                    response.data
                    && response.data.ok === 'OK'
                ) {
                    $scope.close()

                    const titleRequest = $translate(
                        'overlay.reportMpn.success.title',
                    )
                    const messageRequest = $translate(
                        'overlay.reportMpn.success.message',
                    )

                    $q.all([titleRequest, messageRequest]).then(
                        ([title, message]) => {
                            toastr.success(message, title)
                        },
                    )
                } else {
                    throw new Error('Response not OK')
                }
            })
            .catch(response => {
                $scope.state = ReportMpnFormState.PROCESS_ERROR

                const errorData = response.data
                console.error(errorData)
                $scope.errorMessages = getMessagesFromObject(errorData)
            })
    }

    $scope.close = function () {
        $uibModalInstance.close()
    }

    $scope.$on('$destroy', () => ($scope.requestCanceler ? $scope.requestCanceler.resolve() : false))
})
