export default class ProductsEditorController {
    constructor() {
        this.onSaveHandlers = []
    }

    /**
     * Executes the on save handlers for all registered handlers.
     *
     * @return {Promise[]} Returns a set of promises. Each promise is one on save handler returning its promise
     */
    emitOnSaveEvent() {
        return this.onSaveHandlers.map(handler => handler())
    }

    /**
     * Registers an on save handler that get called on the on save event.
     *
     * @param {Function} handler The function to call when the on save function is triggered
     */
    registerOnSaveHandler(handler) {
        this.onSaveHandlers.push(handler)
    }
}
