import dock from '../../modules/dock'

dock.directive('cannotContainLongWords', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            ngModel.$validators.cannotContainLongWords = function (modelValue) {
                return (
                    !modelValue ||
                    !modelValue.split(' ').some(({ length }) => length > 25)
                )
            }
        },
    }
})
