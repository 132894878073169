import dock from '../../modules/dock'
import CanceledTimer from '../../shared/classes/CanceledTimer'
import numberInputTemplate from './numberInput.html'
import './numberInput.scss'

/* eslint-disable */
dock.directive('numberInput', /* @ngInject */ function ($timeout, $interval) {
    return {
        restrict: 'E',
        require: 'ngModel',
        template: numberInputTemplate,
        scope: {
            ngModel: '=',
            disabled: '<'
        },
        link(scope, element, attributes, ngModel) {
            let holdTimeout = new CanceledTimer($timeout)

            let holdInterval = new CanceledTimer($interval)
            const timeout = 200
            const interval = 50

            const _plusInterval = () =>
                (holdInterval = $interval(scope.plusOne, interval))
            const _minusInterval = () =>
                (holdInterval = $interval(scope.minusOne, interval))

            scope.plusOne = () =>
                ngModel.$viewValue < 99
                    ? ngModel.$setViewValue(ngModel.$viewValue + 1)
                    : scope.holdCancel()
            scope.minusOne = () =>
                ngModel.$viewValue > 0
                    ? ngModel.$setViewValue(ngModel.$viewValue - 1)
                    : scope.holdCancel()
            scope.plusHold = () =>
                (holdTimeout = $timeout(_plusInterval, timeout))
            scope.minusHold = () =>
                (holdTimeout = $timeout(_minusInterval, timeout))
            scope.holdCancel = () => {
                $timeout.cancel(holdTimeout)
                $interval.cancel(holdInterval)
            }

            scope.$watch('ngModel', newValue => {
                if (!angular.isNumber(newValue)) {
                    scope.ngModel = 0
                }
            })
        },
    }
})
