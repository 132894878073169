import dock from '../../../modules/dock'
import template from './searchFields.html'

dock.directive('productSectionSearchFields', function () {
    return {
        restrict: 'A',
        scope: false,
        template,
    }
})
