import dock from '../../modules/dock'
import validatorMessageTemplate from './validatorMessage.html'

dock.directive('validatorMessage', function () {
    return {
        restrict: 'E',
        template: validatorMessageTemplate,
        scope: {
            name: '@fieldName',
            validator: '@',
            parameter: '=?',
            postfix: '@?',
        },
    }
})
