import dock from '../../modules/dock'
import filterBarItemTemplate from './dockFilterBarItem.html'
import '../uiSelectLink/uiSelectLink'

/* eslint-disable */
dock.directive('dockFilterBarItem', /* @ngInject */ function ($document) {
    return {
        restrict: 'E',
        require: '?checkReadOnly',
        scope: {
            filter: '=',
            allDisabled: '=',
            setAutofocus: '=?',
            model: '=?',
            property: '@?',
            required: '=?ngRequired',
            name: '@?',
        },
        template: filterBarItemTemplate,
        link(scope, element, attributes, checkReadOnly) {
            if (scope.model && scope.property) {
                scope.filter.registerExternalModel(scope.model, scope.property)
            }

            function setModelWatcher() {
                scope.$watch(
                    () =>
                        scope.filter.model.object[scope.filter.model.property],
                    newVal => {
                        if (scope.filter.config.searchOnly) {
                            return;
                        }

                        if (scope.filter.isSelected(scope.filter.getPreference())) {
                            return;
                        }

                        if (scope.filter.hasOption(newVal)) {
                            scope.filter.setSelected(newVal);
                        } else {
                            scope.filter.reset();
                        }
                    },
                )
            }

            if (checkReadOnly) {
                scope.simulateDblClick = checkReadOnly.simulateDblClick
            }

            $document.ready(() => {
                if (checkReadOnly) {
                    scope.readonly = checkReadOnly.isReadOnly()
                }
            })

            setModelWatcher()
        },
        controller: /* @ngInject */ function ($scope, $element) {
            let uiSelect = null

            function registerUiSelect(uiSelectController) {
                uiSelect = uiSelectController
                _applySettingsForUiSelectController()
            }

            $element.dblclick(() => {
                $scope.simulateDblClick()
                $scope.readonly = false
            })

            function _applySettingsForUiSelectController() {
                if (uiSelect === null) {
                    return
                }

                if ($scope.setAutofocus) {
                    uiSelect.setFocus()
                }
            }

            return { registerUiSelect }
        },
    }
})
