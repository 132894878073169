import dock from '../../modules/dock'

dock.directive('cannotContainWpn', function ($parse) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            const wpn = $parse(attributes.cannotContainWpn)

            ngModel.$validators.cannotContainWpn = function (modelValue) {
                return (
                    !modelValue ||
                    !wpn(scope) ||
                    !modelValue.toLowerCase().includes(wpn(scope).toLowerCase())
                )
            }
        },
    }
})
