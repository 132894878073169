import angular from 'angular'
import dock from '../../modules/dock'

let setReadOnlyController = null
const disableCallbacks = []

function setReadOnlyAttributes($element, readOnly) {
    $element.attr('readonly', readOnly)
    $element.attr('tabIndex', readOnly ? '-1' : false)
}

/* eslint-disable */
dock.directive('checkReadOnly', function() {
    return {
        restrict: 'A',
        require: '^^?setReadOnly',
        link(scope, element, attributes, setReadOnly) {
            setReadOnlyController = setReadOnly
            scope.readOnly = false

            if (setReadOnly && setReadOnly.isReadOnly()) {
                setReadOnlyAttributes(angular.element(element), true)
                scope.readOnly = true
            }

            scope.setReadOnly = function ($element, readOnly) {
                setReadOnlyAttributes($element, readOnly)
                scope.readOnly = readOnly

                if (!readOnly) {
                    disableCallbacks.forEach(callback => callback())
                }
            }
        },
        controller: /* @ngInject */ function ($scope, $element, $timeout) {
            function isReadOnly() {
                return $scope.readOnly && (setReadOnlyController && setReadOnlyController.isReadOnly())

            }

            $element.dblclick(() => {
                $timeout(() => $scope.setReadOnly($element, false))
            })

            function simulateDblClick() {
                $timeout(() => $scope.setReadOnly($element, false))
            }

            function onDisable(callback) {
                disableCallbacks.push(callback)
            }

            $scope.isReadOnly = isReadOnly

            return { isReadOnly, simulateDblClick, onDisable }
        },
    }
})
