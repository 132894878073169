import dock from '../modules/dock'
import { menuItem } from '../shared/constants/menuItems'
import LoginController from '../controllers/LoginCtrl'
import ImportController from '../controllers/ImportCtrl'
import LinkController from '../controllers/LinkCtrl'
import OrdersController from '../controllers/OrdersCtrl'
import ProductController, { ProductFormMode } from '../controllers/ProductCtrl'
import ProductsController from '../controllers/ProductsCtrl'
import DocumentationController from '../controllers/DocumentationCtrl'
import ReturnController from '../controllers/ReturnCtrl'
import ReturnsController from '../controllers/ReturnsCtrl'
import loginTemplate from '../templates/pages/login/login.html'
import ordersTemplate from '../templates/pages/orders/orders.html'
import returnsTemplate from '../templates/pages/returns/returns.html'
import productsTemplate from '../templates/pages/products/products.html'
import documentationTemplate from '../templates/pages/documentation/documentation.html'
import linkTemplate from '../templates/pages/link/link.html'
import productTemplate from '../templates/pages/product/product.html'
import returnTemplate from '../templates/pages/return/return.html'
import importTemplate from '../templates/pages/import/import.html'
import adminTemplate from '../templates/pages/admin/admin.html'

dock.config(/* @ngInject */ function ($routeProvider) {
    const resolveUpdate = {
        update(UpdateService) {
            return UpdateService.update()
        },
    }

    // Route pages to load their template
    // TODO: check where to declare requireLogin
    $routeProvider
        .when('/login/', {
            template: loginTemplate,
            controller: LoginController,
            requireLogin: false,
            resolve: resolveUpdate,
        })
        .when('/orders', {
            menuItem: menuItem.orders,
        })
        .when('/orders/:tab?/:filter?/:page?', {
            template: ordersTemplate,
            controller: OrdersController,
            requireLogin: true,
            reloadOnSearch: false,
            resolve: resolveUpdate,
            menuItem: menuItem.orders,
        })
        .when('/returns/', {
            menuItem: menuItem.returns,
        })
        .when('/returns/:tab?', {
            template: returnsTemplate,
            controller: ReturnsController,
            requireLogin: true,
            reloadOnSearch: false,
            resolve: resolveUpdate,
            menuItem: menuItem.returns,
        })
        .when('/products', {
            menuItem: menuItem.products,
        })
        .when('/products/:tab?/:filter?/:page?', {
            template: productsTemplate,
            controller: ProductsController,
            requireLogin: true,
            reloadOnSearch: false,
            resolve: resolveUpdate,
            menuItem: menuItem.products,
        })
        .when('/documentation/', {
            template: documentationTemplate,
            controller: DocumentationController,
            requireLogin: true,
            resolve: resolveUpdate,
            menuItem: menuItem.documentation,
        })
        .when('/link/', {
            template: linkTemplate,
            controller: LinkController,
            requireLogin: true,
            resolve: resolveUpdate,
            menuItem: menuItem.link,
        })
        .when('/link/prefilled/', {
            template: productTemplate,
            controller: ProductController,
            requireLogin: true,
            resolve: resolveUpdate,
            menuItem: menuItem.link,
            mode: ProductFormMode.PREFILLED,
        })
        .when('/link/product/:productId?', {
            template: productTemplate,
            controller: ProductController,
            requireLogin: true,
            resolve: resolveUpdate,
            menuItem: menuItem.linkProduct,
            mode: ProductFormMode.LINK,
        })
        .when('/return/:orderReferenceId/:orderIdSource', {
            template: returnTemplate,
            controller: ReturnController,
            requireLogin: true,
            resolve: resolveUpdate,
            menuItem: menuItem.reportReturnOrder,
        })
        .when('/return/:orderReferenceId/:orderIdSource/:rmaReferenceId?', {
            template: returnTemplate,
            controller: ReturnController,
            requireLogin: true,
            resolve: resolveUpdate,
            menuItem: menuItem.reportReturn,
        })
        .when('/product/:productId', {
            template: productTemplate,
            controller: ProductController,
            requireLogin: true,
            resolve: resolveUpdate,
            menuItem: menuItem.editProduct,
            mode: ProductFormMode.EDIT,
        })
        .when('/product/', {
            template: productTemplate,
            controller: ProductController,
            requireLogin: true,
            resolve: resolveUpdate,
            menuItem: menuItem.product,
            mode: ProductFormMode.ADD,
        })
        .when('/import/', {
            template: importTemplate,
            controller: ImportController,
            requireLogin: true,
            resolve: resolveUpdate,
            menuItem: menuItem.importProducts,
        })
        .when('/make-me-admin/', {
            template: adminTemplate,
            requireLogin: false,
            resolve: resolveUpdate,
        })
        .otherwise({
            requireLogin: false,
            noHit: true,
            resolve: resolveUpdate,
        })
})
