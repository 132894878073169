import dock from '../modules/dock'
import './Menu'
import './TabHistory'

dock.service('Tab', /* @ngInject */ function ($rootScope, $routeParams, $location, Menu, TabHistory) {
    this.active = null
    this.tabHistory = null
    this.tabs = []
    this.callbacks = []

    /**
     * Updates the active tab or redirects if necessary
     */
    this.update = function () {
        if (!Menu.activeItem || !Menu.activeItem.tabs || Menu.activeItem.tabs.length === 0) {
            this.tabs = []
            return
        }

        this.tabs = Menu.activeItem.tabs
        this.tabHistory = TabHistory.page(Menu.activeItem.name)

        if (!$routeParams.tab) {
            const rememberedTab = this.tabs.find(tab => tab.route === this.tabHistory.remember())
            this.switchTab(rememberedTab || this.tabs[0])
        } else {
            const routeTab = this.tabs.find(tab => tab.route === $routeParams.tab)
            this.setActiveTab(routeTab || this.tabs[0])
        }
    }

    /**
     * Sets the active tab to the given tab. Doesn't do any rerouting
     *
     * @param {Tab} tab The tab to set as active
     */
    this.setActiveTab = function (tab) {
        this.tabHistory.remember(tab.route)
        this.active = tab
        this.callbacks.forEach(callback => callback(tab))
    }

    /**
     * Routes to a new given tab
     *
     * @param {Tab} tab The tab to route to
     */
    this.switchTab = function (tab) {
        $location.path(`${Menu.activeItem.name}/${tab.route}`)
    }
})
