import angular from 'angular'

const SKU_SEPARATOR = '-'

export function splitSkuComponents(sku) {
    if (!angular.isString(sku)) {
        return []
    }

    return sku.split(SKU_SEPARATOR)
}

export function isContentApiSku(sku) {
    const [first, second] = splitSkuComponents(sku)

    if (angular.isUndefined(first) || angular.isUndefined(second)) {
        return false
    }

    return first === second
}

export function normalizeSku(sku) {
    const skuComponents = splitSkuComponents(sku)
    const normalizedComponents = isContentApiSku(sku) ? skuComponents.slice(1) : skuComponents

    return normalizedComponents.join(SKU_SEPARATOR)
}
