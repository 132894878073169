import dock from '../../modules/dock'

const _tripleSequenceRegex = /(.)\1{2,}/i

dock.directive('cannotContainTripleSequence', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            ngModel.$validators.cannotContainTripleSequence = function (modelValue) {
                return !modelValue || !_tripleSequenceRegex.test(modelValue)
            }
        },
    }
})
