import dock from '../modules/dock'

const tabHistoryStorageKey = 'tab_history'

/**
 * Service for remembering the tab that was last opened
 */
dock.service('TabHistory', function () {
    function _storageKey(pageName) {
        return `${tabHistoryStorageKey}_${pageName}`
    }

    this.page = pageName => ({
        remember: (tabName) => {
            if (!tabName) {
                return sessionStorage.getItem(_storageKey(pageName))
            }

            return sessionStorage.setItem(_storageKey(pageName), tabName)
        },
    })
})
