import dock from '../../modules/dock'

const controllerName = 'ContentApiConfirmCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    actionList,
) {
    const contentApiProducts = actionList
        .filter(product => product.fromContentApi())
    const totalCount = actionList.length
    const contentApiCount = contentApiProducts.length
    const onlyContentApiProducts = totalCount === contentApiCount
    $scope.onlyContentApiProducts = onlyContentApiProducts

    $scope.translationArguments = {
        totalCount,
        contentApiCount,
    }

    $scope.acknowledge = function acknowledge() {
        // If we only have content API products there's no point in continuing to the action
        if (onlyContentApiProducts) {
            $scope.$dismiss()
        } else {
            $scope.$close()
        }
    }
})
