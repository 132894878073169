import '../factories/api'
import '../factories/metaInfo'
import '../factories/commonFilters'
import '../services/Admin'
import '../services/Tab'
import '../directives/checkbox/checkbox'
import '../directives/dockFilterBar/dockFilterBar'
import '../directives/dockSelectionBar/dockSelectionBar'
import '../directives/productCategory/productCategory'
import '../directives/productPrice/productPrice'
import '../directives/searchable/searchable'
import '../directives/sortable/sortable'
import '../directives/dockLoadingMessage/dockLoadingMessage'
import '../directives/productImage/productImage'
import SaleModalTemplate from '../templates/modal/sale.html'
import SaleModalController from './modals/SaleCtrl'
import SaleStopModalTemplate from '../templates/modal/saleStop.html'
import SaleStopModalController from './modals/SaleStopCtrl'
import PrintLabelsModalTemplate from '../templates/modal/printLabels.html'
import PrintLabelsModalController from './modals/PrintLabelsCtrl'
import DeleteModalTemplate from '../templates/modal/delete.html'
import DeleteModalController from './modals/DeleteCtrl'
import StockModalTemplate from '../templates/modal/stock.html'
import StockModalController from './modals/StockCtrl'
import SalesBoosterTemplate from '../templates/modal/salesBooster/outline.html'
import SalesBoosterController from './modals/SalesBoosterCtrl'
import contentApiConfirmTemplate from '../templates/modal/contentApiConfirm.html'
import contentApiConfirmController from './modals/ContentApiConfirmCtrl'
import dock from '../modules/dock'
import { Product } from '../shared/classes/Product'
import { ProductVariant } from '../shared/classes/ProductVariant'
import { ChangeTracker } from '../shared/classes/ChangeTracker'
import Tab from '../shared/classes/Tab'
import CachedBatchLoader from '../shared/classes/CachedBatchLoader'
import stockAndPriceCache from '../shared/instances/stockAndPriceCache'
import { ProductStatuses, ProductStatusValues } from '../shared/constants/productStatus'

// The mass actions for the product
export const MassAction = Object.freeze({
    SALE: Symbol('sale'), // Set a sale
    SALE_STOP: Symbol('saleStop'), // Stop a sale
    PRINT_LABELS: Symbol('printLabels'), // Print shipping labels
    DELETE: Symbol('delete'), // Delete products
    STOCK: Symbol('stock'), // Stock for product
    SALES_BOOSTER: Symbol('sales-booster'), // All products in sale
})

export const ProductsTabs = Object.freeze({
    [ProductStatuses.statusOnline]: new Tab('products.status.online', 'online'),
    [ProductStatuses.statusProcessing]: new Tab('products.status.processing', 'processing'),
    [ProductStatuses.statusNew]: new Tab('products.status.new', 'new'),
    [ProductStatuses.statusAll]: new Tab('products.status.all', 'all'),
})

const translatePrefix = 'product.property.'

const controllerName = 'ProductsCtrl'
export default controllerName

/* eslint-disable */
dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    $location,
    $q,
    Data,
    api,
    ConstantsTranslator,
    $uibModal,
    commonFilters,
    GetPropertyList,
    Tab,
) {
    const C = {}
    const constants = ConstantsTranslator.setConstants(
        {
            PRODUCT: {
                SALE: {
                    NO_SALE: 'no-sale',
                    HAS_SALE: 'has-sale',
                    IN_SALE: 'in-sale',
                    PLANNED_SALE: 'planned-sale',
                    EXPIRED_SALE: 'expired-sale',
                },
                STOCK: {
                    IN_STOCK: 'in-stock',
                    OUT_OF_STOCK: 'out-of-stock',
                },
            },
        },
        C,
    )

    $scope.C = C
    $scope.constants = constants

    $scope.pageStatus = {
        loading: false,
        error: false,
    }

    // These are set by getSortItems();
    $scope.sortItems = {}

    // These are set by getProductFilters();
    $scope.searchFilter = null
    $scope.referenceSearchFilter = null

    const selectionControllerConfig = {
        itemTranslationKey: 'products.selectionItem',
        identifier: 'id',
    }

    $scope.refresh = refresh
    $scope.editProduct = editProduct
    $scope.editStock = editStock
    $scope.salesBooster = salesBooster
    $scope.productsOutOfSync = false
    $scope.Tab = Tab
    $scope.ProductsTabs = ProductsTabs

    let brandFilter
    const filters = getProductFilters()
    const massActions = getMassActions(Tab.active)
    const sortItems = getSortItems()
    const filterController = new Data.Filters.Controller(filters)
    const paginationController = new Data.Pagination.Controller()
    const selectionController = new Data.Selection.Controller(
        selectionControllerConfig,
        massActions,
    )
    const sortingController = new Data.Sorting.Controller(sortItems)
    const stockAndPriceLoader = new CachedBatchLoader(loadStockAndPriceForProducts, getSkuFromItem, stockAndPriceCache)
    $scope.stockAndPriceLoader = stockAndPriceLoader

    stockAndPriceLoader.subscribe(processStockAndPriceForProduct, processStockAndPriceErrorForProduct)

    $scope.products = new Data.DataController(
        filterController,
        paginationController,
        selectionController,
        sortingController,
    )

    $scope.requestCanceler = null
    $scope.products.pagination.onPageChange(loadStockAndPriceForCurrentPage)

    /**
     * Returns the filters for the product page.
     *
     * @returns {Filter[]} The filter objects specific for the products page
     */
    function getProductFilters() {
        brandFilter = commonFilters.getBrandFilter(false, false)
        const searchFilter = getSearchFilter()
        const referenceSearchFilter = getReferenceSearchFilter()
        const skuSearchFilter = getSkuSearchFilter()
        $scope.searchFilter = searchFilter
        $scope.referenceSearchFilter = referenceSearchFilter
        $scope.skuSearchFilter = skuSearchFilter

        return [
            brandFilter,
            ...commonFilters.getCategoryFilters(),
            getSaleFilter(translatePrefix),
            getStockFilter(translatePrefix),
            commonFilters.getSeasonFilter(),
            searchFilter,
            referenceSearchFilter,
            skuSearchFilter
        ]
    }

    function getSaleFilter(translatePrefix) {
        return new Data.Filters.Filter(
            'sale',
            translatePrefix + 'sale',
            'salePrijs',
            [
                C.PRODUCT.SALE.NO_SALE,
                C.PRODUCT.SALE.HAS_SALE,
                C.PRODUCT.SALE.IN_SALE,
                C.PRODUCT.SALE.PLANNED_SALE,
            ],
            {
                options: {
                    fixed: true,
                    translate: true,
                    mapFunction: function(filter) {
                        return constants.getValue(filter)
                    },
                },
                search: false,
                labelProperty: '.label',
                placeholderProperty: '.placeholder',
                filterFunction: _saleFilterFunction,
            },
        )
    }

    function getSearchFilter() {
        return new Data.Filters.Filter('search', '', '', [], {
            options: {
                empty: '',
                default: '',
            },
            visible: false,
            filterFunction: _searchFilterFunction,
            searchOnly: true,
        })
    }

    function getReferenceSearchFilter() {
        return new Data.Filters.Filter('referenceSearch', '', '', [], {
            options: {
                empty: '',
                default: '',
            },
            visible: false,
            filterFunction: _referenceSearchFilterFunction,
            searchOnly: true,
        })
    }

    function getSkuSearchFilter() {
        return new Data.Filters.Filter('skuSearch', '', '', [], {
            options: {
                empty: '',
                default: '',
            },
            visible: false,
            filterFunction: _skuSearchFilterFunction,
            searchOnly: true,
        })
    }

    function getStockFilter(translatePrefix) {
        return new Data.Filters.Filter(
            'stock',
            translatePrefix + 'stock',
            'amountInStock',
            [C.PRODUCT.STOCK.IN_STOCK, C.PRODUCT.STOCK.OUT_OF_STOCK],
            {
                options: {
                    fixed: true,
                    translate: true,
                    mapFunction: function(filter) {
                        return constants.getValue(filter)
                    },
                },
                search: false,
                labelProperty: '.label',
                filterFunction: _stockFilterFunction,
            },
        )
    }

    /**
     * Returns the mass actions for the products page
     *
     * @returns {MassAction[]} The generated mass actions
     */
    function getMassActions(tab) {
        const massActions = []

        massActions.push(
            new Data.Selection.MassAction({
                translateKey: 'product.action.startSale',
                executeFunction: getMassActionFunction(MassAction.SALE),
                iconClass: 'fa fa-calendar-plus-o',
                analytics: {
                    enabled: true,
                    event: 'open set sale modal',
                    category: 'product sales',
                },
            }),
        )

        massActions.push(
            new Data.Selection.MassAction({
                translateKey: 'product.action.stopSale',
                executeFunction: getMassActionFunction(MassAction.SALE_STOP),
                iconClass: 'fa fa-calendar-times-o',
                analytics: {
                    enabled: true,
                    event: 'open stop sale modal',
                    category: 'product sales',
                },
            }),
        )

        if (tab !== ProductsTabs.ONLINE) {
            massActions.push(
                new Data.Selection.MassAction({
                    translateKey: 'product.action.printLabels',
                    executeFunction: getMassActionFunction(MassAction.PRINT_LABELS),
                    iconClass: 'fa fa-print',
                    analytics: {
                        enabled: true,
                        event: 'open photography labels modal',
                        category: 'photography labels',
                    },
                }),
            )
        }

        massActions.push(
            new Data.Selection.MassAction({
                translateKey: 'product.action.delete',
                executeFunction: getMassActionFunction(MassAction.DELETE),
                iconClass: 'fa fa-trash color-danger',
                additionalClasses: 'color-danger',
                analytics: {
                    enabled: true,
                    event: 'open delete products modal',
                    category: 'product deleting',
                },
            }),
        )

        return massActions
    }

    function getSortItems() {
        const productInfo = new Data.Sorting.SortItem({
            property: 'productInfo',
            sortFunction: productInfoSort,
        })

        const stock = new Data.Sorting.SortItem({
            property: 'amountInStock',
            sortFunction: stockSort,
        })

        const cashRegisterReference = new Data.Sorting.SortItem({
            property: 'retailerProductReferentie',
            sortFunction: 'stringFast',
        })

        const sku = new Data.Sorting.SortItem({
            property: ProductVariant.getContentApiSkuProperty(),
            sortFunction: 'stringFast',
        })

        const category = new Data.Sorting.SortItem({
            name: 'category',
            sortFunction: categorySort,
        })

        const price = new Data.Sorting.SortItem({
            property: 'prijs',
            sortFunction: 'number',
        })

        const salePrice = new Data.Sorting.SortItem({
            property: 'salePrijs',
            sortFunction: 'number',
        })

        const salePercentage = new Data.Sorting.SortItem({
            property: 'saleKortingPercentage',
            sortFunction: 'number',
        })

        const saleFrom = new Data.Sorting.SortItem({
            property: 'saleKortingVan',
            sortFunction: 'date',
        })

        const saleTo = new Data.Sorting.SortItem({
            property: 'saleKortingTot',
            sortFunction: 'date',
        })

        $scope.sortItems = {
            productInfo,
            stock,
            cashRegisterReference,
            sku,
            category,
            price,
            salePrice,
            salePercentage,
            saleFrom,
            saleTo,
        }

        return Object.values($scope.sortItems)
    }

    function productInfoSort(data, reverse) {
        return data.sort((a, b) => {
            const brandA = a.merk.naam.toUpperCase()
            const brandB = b.merk.naam.toUpperCase()

            if (brandA < brandB) {
                return reverse ? 1 : -1
            } else if (brandA > brandB) {
                return reverse ? -1 : 1
            }

            const nameA = a.productNaam.toUpperCase()
            const nameB = b.productNaam.toUpperCase()

            if (nameA < nameB) {
                return reverse ? 1 : -1
            } else if (nameA > nameB) {
                return reverse ? -1 : 1
            }

            return 0
        })
    }

    function categorySort(data, reverse) {
        return data.sort((a, b) => {
            if (!a.hoofdCategorie) {
                return reverse ? 1 : -1
            }

            if (!b.hoofdCategorie) {
                return reverse ? -1 : 1
            }

            const mainCategoryA = a.hoofdCategorie.naam.toUpperCase()
            const mainCategoryB = b.hoofdCategorie.naam.toUpperCase()

            if (mainCategoryA < mainCategoryB) {
                return reverse ? 1 : -1
            } else if (mainCategoryA > mainCategoryB) {
                return reverse ? -1 : 1
            }

            const categoryA = a.categorie.naam.toUpperCase()
            const categoryB = b.categorie.naam.toUpperCase()

            if (categoryA < categoryB) {
                return reverse ? 1 : -1
            } else if (categoryA > categoryB) {
                return reverse ? -1 : 1
            }

            if (a.subCategorie.naam && b.subCategorie.naam) {
                const subCategoryA = a.subCategorie.naam.toUpperCase()
                const subCategoryB = b.subCategorie.naam.toUpperCase()

                if (subCategoryA < subCategoryB) {
                    return reverse ? 1 : -1
                } else if (subCategoryA > subCategoryB) {
                    return reverse ? -1 : 1
                }
            } else if (a.subCategorie.naam) {
                return reverse ? 1 : -1
            } else if (b.subCategorie.naam) {
                return reverse ? -1 : 1
            }

            return 0
        })
    }

    function stockSort(data, reverse) {
        return data.sort((a, b) => {
            if (a.stockNotLoaded()) {
                return 1
            }

            if (b.stockNotLoaded()) {
                return -1
            }

            const stockA = a.amountInStock
            const stockB = b.amountInStock

            if (stockA < stockB) {
                return reverse ? 1 : -1
            } else if (stockA > stockB) {
                return reverse ? -1 : 1
            } else {
                return 0
            }
        })
    }

    function setProducts(products) {
        $scope.products
            .setData(products)
            .filter()
            .sort()

        GetPropertyList.loadFixedFilterOptions([brandFilter], $scope.products)
        loadStockAndPriceForCurrentPage()
        stockAndPriceLoader.add(products).then(() => $scope.$apply())
    }

    function updateProductInList(product) {
        const products = $scope.products.getData()
        const productPosition = products.findIndex(({ id }) => id === product.id)

        products[productPosition] = product

        setProducts(products)
    }

    function deleteProductFromList(product) {
        const products = $scope.products.getData()
        const productPosition = products.findIndex(({ id }) => id === product.id)

        products.splice(productPosition, 1)

        setProducts(products)
    }

    function reloadProduct(product) {
        product.setMetaProperties({
            reloading: true
        })

        return api.products.getProduct(product)
            .then(({ data }) => {
                const product = new Product(data)
                updateProductInList(product)
                stockAndPriceLoader.removeCache([product])
                stockAndPriceLoader.addPrioritized([product]).then(() => $scope.$apply())
            })
            .catch(response => {
                // If we get a 404 Not Found HTTP status code we assume the product was deleted and we remove it from the list
                if (response && response.status === 404) {
                    stockAndPriceLoader.removeCache([product])
                    deleteProductFromList(product)
                    return
                }

                // Rethrow errors
                throw(response)
            })
    }

    function reloadProducts(products) {

        // If we're dealing with more than 10 changed products, let the user do a full refresh
        // TODO: Maybe use bulk mechanism in the future.
        if (products.length > 10) {
            $scope.productsOutOfSync = true;
            return
        }

        products.forEach(product => {

            // In case of an error, let the user do a full refresh
            reloadProduct(product)
                .catch(() => $scope.productsOutOfSync = true)
        })

        stockAndPriceLoader.addPrioritized(products).then(() => $scope.$apply())
    }

    function refresh() {
        loadProductsForTab(Tab.active)
            .then(() => loadStockAndPriceForCurrentPage())
        loadProductCountsForTabs()
    }

    function loadProductsForTab(tab) {
        $scope.pageStatus.loading = true
        $scope.pageStatus.error = false
        $scope.productsOutOfSync = false
        $scope.requestCanceler = $q.defer()

        return api.products
            .products(getProductStatus(tab), $scope.requestCanceler)
            .then(function(response) {
                const products = response.data.producten.map(product => new Product(product))
                setProducts(products)
                $scope.pageStatus.error = false
            })
            .catch(function(error) {
                console.error(error)
                $scope.pageStatus.error = true
                $scope.products
                    .setData([])
                    .filter()
                    .sort()
            })
            .finally(function() {
                $scope.pageStatus.loading = false
            })
    }

    function loadStockAndPriceForCurrentPage() {
        return loadStockForPage($scope.products.pagination.getPage())
    }

    function loadStockForPage(page) {
        const productsOnPage = $scope.products.pagination.getDataForPage(page)
        stockAndPriceLoader.addPrioritized(productsOnPage).then(() => $scope.$apply())
    }

    function processStockAndPriceForProduct({ id, SKU, amountInStock, amountOfVariants, ...priceProperties }) {
        const matchingProduct = $scope.products.getData().find(product => product[Product.getIdProperty()] === id)

        // If we can't find the product based on the id, something went wrong
        if (!matchingProduct) {
            return processStockAndPriceErrorForProduct({ SKU })
        }

        matchingProduct.setStock(amountInStock, amountOfVariants)
        matchingProduct.setStockStatusLoaded()
        matchingProduct.setPrice(priceProperties)
        matchingProduct.setPriceStatusLoaded()

        // This is the handler for an observable, which isn't handled by AngularJS, so we have to update the scope manually
        $scope.$apply()
    }

    function processStockAndPriceErrorForProduct({ SKU }) {
        const matchingProduct = $scope.products.getData().find(product => product.getOriginalSku() === SKU)

        if (!matchingProduct) {
            return
        }

        matchingProduct.setStockStatusError()
        matchingProduct.setPriceStatusError()

        // This is the handler for an observable, which isn't handled by AngularJS, so we have to update the scope manually
        $scope.$apply()
    }

    function getSkuFromItem(item) {
        if (item.getOriginalSku) {
            return item.getOriginalSku()
        }

        return item.SKU
    }

    async function loadStockAndPriceForProducts(products = []) {
        const skus = products.map(product => product.getOriginalSku())

        return api.products.getProductStockPriceBulk(skus)
            .then(response => {
                const { invalidSkus, productStockPriceInfo } = response.data
                const failed = invalidSkus.map(sku => ({ SKU: sku }))

                // Map to a set of failed and succeeded requests
                return { failed, succeeded: productStockPriceInfo }
            })
            .catch(() => {
                const failed = skus.map(sku => ({ SKU: sku}))

                // If the whole request failed, return all as failed requests
                return { failed, succeeded: [] }
            })
    }

    function getProductStatus(tab) {
        switch (tab) {
            case ProductsTabs[ProductStatuses.statusOnline]:
                return ProductStatusValues[ProductStatuses.statusOnline]
            case ProductsTabs[ProductStatuses.statusProcessing]:
                return ProductStatusValues[ProductStatuses.statusProcessing]
            case ProductsTabs[ProductStatuses.statusNew]:
                return ProductStatusValues[ProductStatuses.statusNew]
            case ProductsTabs[ProductStatuses.statusAll]:
                return ProductStatusValues[ProductStatuses.statusAll]
        }
    }

    function editProduct(product) {
        $location.path('product/' + product.id)
    }

    function editStock(product, $event) {
        $event.stopPropagation()
        getMassActionFunction(MassAction.STOCK)([product])
    }

    function getMassActionModalTemplate(action) {
        switch (action) {
            case MassAction.SALE: return SaleModalTemplate;
            case MassAction.SALE_STOP: return SaleStopModalTemplate;
            case MassAction.PRINT_LABELS: return PrintLabelsModalTemplate;
            case MassAction.DELETE: return DeleteModalTemplate;
            case MassAction.STOCK: return StockModalTemplate;
            case MassAction.SALES_BOOSTER: return SalesBoosterTemplate;
        }
    }

    function getMassActionModalController(action) {
        switch (action) {
            case MassAction.SALE: return SaleModalController;
            case MassAction.SALE_STOP: return SaleStopModalController;
            case MassAction.PRINT_LABELS: return PrintLabelsModalController;
            case MassAction.DELETE: return DeleteModalController;
            case MassAction.STOCK: return StockModalController;
            case MassAction.SALES_BOOSTER: return SalesBoosterController;
        }
    }

    function isContentApiMassAction(action) {
        const contentApiMassActions = [MassAction.PRINT_LABELS, MassAction.STOCK]
        return contentApiMassActions.includes(action)
    }

    function isContentApiProduct(product) {
        return product.fromContentApi()
    }

    function isContentApiModalNeeded(products, action) {
        return !isContentApiMassAction(action) &&
               products.some(isContentApiProduct)
    }

    async function openContentApiModal(products) {
        return $uibModal.open({
            template: contentApiConfirmTemplate,
            controller: contentApiConfirmController,
            size: 'md',
            resolve: {
                actionList: () => products
            }
        }).result
    }

    async function openMassActionModal(products, action, size) {
        const template = getMassActionModalTemplate(action)
        const controller = getMassActionModalController(action)
        const changeTracker = new ChangeTracker()

        let cleanedProducts = angular
                    .copy(products)
                    .map(product => {
                        delete product.saleKortingVanMilliseconds
                        delete product.saleKortingTotMilliseconds
                        return product;
                    })

        if (!isContentApiMassAction(action)) {
            cleanedProducts = cleanedProducts
                .filter(product => !product.fromContentApi())
        }

        try {
            await $uibModal.open({
                template,
                controller,
                size,
                resolve: {
                    changeTracker,
                    actionList: () => cleanedProducts,
                }
            }).result
        } catch (error) {
            throw changeTracker
        }

        return changeTracker
    }

    function getMassActionFunction(action, size = 'lg') {
        return async function (products) {
            const contentApiModalIsNeeded = isContentApiModalNeeded(products, action)

            if (contentApiModalIsNeeded) {
                try {
                    await openContentApiModal(products)
                } catch (error) {
                    // Content API modal was dismissed, so we return
                    return
                }
            }

            let changeTracker;
            try {
                changeTracker = await openMassActionModal(products, action, size)
            } catch (error) {
                changeTracker = error
            }

            if (changeTracker.wasChanged()) {
                reloadProducts(products)
            }
        }
    }

    function salesBooster(products) {
        const filteredProducts = products.filter(_salesBoosterFilterFunction)
        getMassActionFunction(MassAction.SALES_BOOSTER, 'md')(filteredProducts)
    }

    function _addSaleKortingTotMilliseconds(product) {
        if (product.saleKortingTotMilliseconds === undefined) {
            product.saleKortingTotMilliseconds = new Date(
                product.saleKortingTot,
            ).getTime()
        }
    }

    function _addSaleKortingVanMilliseconds(product) {
        if (product.saleKortingVanMilliseconds === undefined) {
            product.saleKortingVanMilliseconds = new Date(
                product.saleKortingVan,
            ).getTime()
        }
    }

    function _saleFilterFunction(product, filter) {
        const selected = filter.getSelected(true, true)

        const NO_SALE = constants.getValue(C.PRODUCT.SALE.NO_SALE)
        const HAS_SALE = constants.getValue(C.PRODUCT.SALE.HAS_SALE)
        const IN_SALE = constants.getValue(C.PRODUCT.SALE.IN_SALE)
        const PLANNED_SALE = constants.getValue(C.PRODUCT.SALE.PLANNED_SALE)
        const EXPIRED_SALE = constants.getValue(C.PRODUCT.SALE.EXPIRED_SALE)

        switch(selected) {
            case NO_SALE: return !product.saleItem();
            case HAS_SALE: return product.saleItem();
            case IN_SALE: return product.activeSale();
            case PLANNED_SALE: return product.plannedSale();
            case EXPIRED_SALE: return product.expiredSale();
        }
    }

    function _salesBoosterFilterFunction(product) {

        // If we don't have a sale, we can sale boost it
        if (product.salePrijs === null) {
            return true
        }

        _addSaleKortingTotMilliseconds(product)

        // If the sale has past, we can sale boost it
        if (Date.now() >= product.saleKortingTotMilliseconds) {
            return true;
        }

        // If there's an active sale, or a sale planned, don't saleboost it
        return false
    }

    function _searchFilterFunction(product, filter) {
        const productProperties = [
            (product.merk.naam || '').toUpperCase(),
            (product.productNaam || '').toUpperCase(),
            (product.artikelNummer || '').toUpperCase(),
        ]

        return _genericSearchFilterFunction(product, filter, productProperties)
    }

    function _referenceSearchFilterFunction(product, filter) {
        const productProperties = [
            (product.retailerProductReferentie || '').toUpperCase(),
        ]

        return _genericSearchFilterFunction(product, filter, productProperties)
    }

    function _skuSearchFilterFunction(product, filter) {
        const productProperties = [
            (product[Product.getContentApiSkuProperty()] || '').toUpperCase(),
        ]

        return _genericSearchFilterFunction(product, filter, productProperties);
    }

    function _genericSearchFilterFunction(product, filter, productProperties) {
        let searchMatched = false
        const searchString = filter.getSelected(false, false).toUpperCase()
        for (let index = 0; index < productProperties.length; index++) {
            if (productProperties[index].includes(searchString)) {
                searchMatched = true
                break
            }
        }

        return searchMatched
    }

    function _stockFilterFunction(product, filter) {
        if (product.stockNotLoaded()) {
            return true
        }

        const selected = filter.getSelected(true, true)

        return selected === constants.getValue(C.PRODUCT.STOCK.IN_STOCK)
            ? product.amountInStock > 0
            : !product.amountInStock
    }

    function loadProductCountsForTabs() {
        return api.products
            .productCount($scope.requestCanceler)
            .then(function (response) {
                Tab.tabs.map((tab) => {
                    switch (tab) {
                        case ProductsTabs[ProductStatuses.statusOnline]:
                            setTabProductCount(tab, response.onlineProducten)
                            break;
                        case ProductsTabs[ProductStatuses.statusProcessing]:
                            setTabProductCount(tab, response.fotografieProducten)
                            break;
                        case ProductsTabs[ProductStatuses.statusNew]:
                            setTabProductCount(tab, response.nieuwProducten)
                            break;
                        case ProductsTabs[ProductStatuses.statusAll]:
                            setTabProductCount(tab, response.alleProducten)
                            break;
                    }
                })
            })
    }

    function setTabProductCount(tab, productCount) {
        tab.badge = productCount
    }

    $scope.$on('$destroy', () => $scope.requestCanceler ? $scope.requestCanceler.resolve() : false)

    loadProductsForTab(Tab.active)
    loadProductCountsForTabs()
})
