export default class CombinedCategory {
    constructor(mainCategory, category, subCategory = { code: '', naam: '' }) {
        this.title = ''
        this.code = ''

        this.setMainCategory(mainCategory)
        this.setCategory(category)
        this.setSubCategory(subCategory)
    }

    setMainCategory(category) {
        this.mainCategory = category
        return this
    }

    setCategory(category) {
        this.category = category
        return this
    }

    setSubCategory(category) {
        if (!category || !category.code) {
            this.subCategory = { code: '', naam: '' }
        } else {
            this.subCategory = category
        }

        return this
    }

    generateTitleAndCode() {
        this.title = `${this.mainCategory.naam} - ${this.category.naam}${
            this.subCategory.naam ? ` - ${this.subCategory.naam}` : ''
        }`

        this.code = this.subCategory.code
            ? this.subCategory.code
            : this.category.code

        return this
    }
}
