import dock from '../modules/dock'
import { RetailerStatus } from '../services/Retailer'

dock.run(/* @ngInject */ function ($rootScope, $route, $location, UpdateService, Retailer) {
    $rootScope.$on('$routeChangeStart', ($event, newRoute) => {
        // Store the path that was requested
        const requestedPath = $location.path()
        let route = newRoute.$$route

        if (!route) {
            route = {
                noHit: true,
            }
        }

        // Finish the original route request and erase this history with .replace()
        function _executeRouting() {
            $location.path(requestedPath).replace()
            $route.reload()
        }

        // If updates are not done yet, wait for the updates and route when they are done
        if (!UpdateService.updated) {
            UpdateService.update().then(_executeRouting)
            $event.preventDefault()
            return
        }

        // Check if login is required or we have no direct route hit and if the retailer is not logged in
        if ((!!route.requireLogin || !!route.noHit) && !Retailer.isLoggedIn()) {
            // If the retailer should be logged in and he is not, check whether that is because it has not been
            // determined yet. If that is the case, check the retailer status with the back-end
            if (Retailer.status === RetailerStatus.UNDETERMINED) {
                Retailer.checkRetailerStatus()
                    .then(() => {
                        // If the back-end verifies that the retailer was logged in we can finish the original route
                        // request and erase this history with .replace()
                        _executeRouting()
                    })
                    .catch(() => {
                        // If it is determined that there is no session or the session was invalidated, add a warning
                        // to the console and try to reauthenticate
                        console.info('Login required for requested route and no valid session was found, ' +
                                'trying to reauthenticate...')

                        Retailer.reauthenticate()
                            .then(() => {
                                // If the reauthentication was successful we can finish the original route request and
                                // erase this history with .replace()
                                _executeRouting()
                            })
                            .catch((error) => {
                                // If we could not reauthenticate because there was no token or the token was
                                // invalidated, we log an error to the console and redirect to the login page
                                console.info(
                                    'Could not reauthenticate user, redirecting to login page',
                                    error,
                                )
                                $location.path('login')
                                $route.reload()
                            })
                    })
            }

            // If the retailer should be logged in and he is not, check whether that is because he is not logged in. If
            // that is the case, redirect to the login page
            if (Retailer.status === RetailerStatus.NOT_LOGGED_IN) {
                $location.path('login')
            }

            // in any case, prevent the page from being loaded. The retailer status could be 'loading', in that case
            // we assume that once loading finishes a redirect will happen
            $event.preventDefault()
        }

        // If there is no direct route hit and the user is logged in redirect him to the retailer specific base path
        if (!!route.noHit && Retailer.isLoggedIn()) {
            $location.path(Retailer.getBasePath())
        }
    })
})
