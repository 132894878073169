import dock from '../modules/dock'
import './Filters'
import './Pagination'
import './Selection'
import './Sorting'

dock.service('Data', /* @ngInject */ function (Filters, Pagination, Selection, Sorting) {
    function DataController(
        filtersController,
        paginationController,
        selectionController,
        sortingController,
    ) {
        this.filters = filtersController
        this.pagination = paginationController
        this.selection = selectionController
        this.sorting = sortingController
        this.data = null
        const self = this

        /**
         * Sets the data for the data controller
         *
         * @param {Object[]} data The data to set it to
         * @returns {DataController} Returns this to allow for chaining
         */
        this.setData = function (data) {
            this.data = data
            this.sorting.setData(data)
            this.selection.setSelectable(data)
            return this
        }

        /**
         * Sorts the data using the sorting controller
         *
         * @returns {DataController} Returns this to allow for chaining
         */
        this.sort = function () {
            this.sorting.setData(this.data).sort()
            return this
        }

        /**
         * Filters the data using the filter controller
         *
         * @returns {DataController} Returns this to allow for chaining
         */
        this.filter = function () {
            const filtered = this.filters.setData(this.getSorted()).filter()

            this.selection.setData(filtered)
            return this
        }

        /**
         * Paginates the data using the pagination controller
         *
         * @returns {DataController} Returns this to allow for chaining
         */
        this.paginate = function () {
            this.pagination
                .setPage(this.pagination.pageModel)
                .setData(this.getFiltered())
                .paginate()
            return this
        }

        /**
         * Gets the current data
         *
         * @return {Object[]} The current data
         */
        this.getData = function () {
            return this.data
        }

        /**
         * Gets the sorted data
         *
         * @return {Object[]} The sorted data
         */
        this.getSorted = function () {
            return this.sorting.data
        }

        /**
         * Gets the filtered data
         *
         * @return {Object[]} The filtered data
         */
        this.getFiltered = function () {
            return this.filters.dataFiltered
        }

        /**
         * Gets the paginated data
         *
         * @return {Object[]} The paginated data
         */
        this.getPaginated = function () {
            return this.pagination.dataPaginated
        }

        /**
         * Returns whether the data service has any items to display
         * @returns {Boolean} false when the data length is 0 or null
         */
        this.hasItems = function () {
            return (
                this.filters.dataFiltered !== null
                && this.filters.dataFiltered.length !== 0
            )
        }

        this.filters.onFilter(function (filteredData) {
            self.selection.setData(filteredData)
            self.paginate()
        })

        this.sorting.onSort(function () {
            self.filter()
        })

        this.pagination.onItemsPerPageChange(function () {
            self.paginate()
        })
    }

    this.DataController = DataController
    this.Filters = Filters
    this.Pagination = Pagination
    this.Selection = Selection
    this.Sorting = Sorting
})
