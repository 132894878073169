import dock from '../../../modules/dock'
import template from './debug.html'
import './debug.scss'

dock.directive('productSectionDebug', function () {
    return {
        restrict: 'A',
        scope: false,
        template,
    }
})
