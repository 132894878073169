(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("angular"), require("nouislider"));
	else if(typeof define === 'function' && define.amd)
		define("angularjs-nouislider", ["angular", "nouislider"], factory);
	else if(typeof exports === 'object')
		exports["angularjs-nouislider"] = factory(require("angular"), require("nouislider"));
	else
		root["angularjs-nouislider"] = factory(root["angular"], root["noUiSlider"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_angular__, __WEBPACK_EXTERNAL_MODULE_nouislider__) {
return 