import '../../factories/api'
import '../../directives/checkboxSwitch/checkboxSwitch'
import dock from '../../modules/dock'
import { Stock } from './StockCtrl'
import { Product } from '../../shared/classes/Product'
import { ProductVariant } from '../../shared/classes/ProductVariant'

/* eslint-disable */
/**
 * Class that extends the Stock class to be able to use it for multiple products
 */
export class MultipleProductsStock extends Stock {
    _getVariantIdentifier(variant) {
        const id = super._getVariantIdentifier(variant)
        return `${variant.productId}-${id}`
    }
}
const controllerName = 'ProcessCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    $q,
    $uibModalInstance,
    api,
    order,
) {
    // The states of the order in the modal
    const OrderState = Object.freeze({
        LOADING: Symbol('loading'), // The order information is loading
        READY: Symbol('ready'), // The order information was loaded
        ERROR: Symbol('error'), // Loading the order information resulted in an error
        PROCESSING: Symbol('processing'), // The order is being processed
        PROCESSED: Symbol('processed'), // The order was processed
        PROCESSING_ERROR: Symbol('processing_error'), // Processing the order resulted in an error
    })

    // The states of an order item's variants
    const VariantsState = Object.freeze({
        LOADING: Symbol('loading'), // The order item's variants are loading
        READY: Symbol('ready'), // The order item's variants were loaded
        ERROR: Symbol('error'), // Loading the order item's variants resulted in an error
    })

    // We use a stock queue to check if any stock values were updated
    let stock = new MultipleProductsStock()

    $scope.OrderState = OrderState
    $scope.VariantsState = VariantsState
    $scope.requestCanceler = null
    $scope.state = OrderState.READY
    $scope.order = order
    $scope.error = null
    $scope.loadOrderAndVariants = loadOrderAndVariants
    $scope.processOrder = processOrder
    $scope.stock = stock
    $scope.someContentApiProducts = someContentApiProducts

    loadOrderAndVariants()

    /**
     * Loads the order in the modal and triggers the load of its variants
     */
    function loadOrderAndVariants() {
        $scope.state = OrderState.LOADING
        $scope.requestCanceler = $q.defer()

        api.orders
            .getOrder(
                order.orderReferenceId,
                order.orderId.source,
                $scope.requestCanceler
            )
            .then(({ data }) => {
                $scope.state = OrderState.READY
                $scope.order.items = data.items

                // Each order item has a state to enable the use of spinners for each product
                $scope.order.items.forEach(orderItem => {
                    // <IWSNL-8881> Add fallback for reference ID's
                    orderItem.orderReferenceId = orderItem.orderReferenceId ?? $scope.order.orderReferenceId
                    // </IWSNL-8881>
                    orderItem.state = VariantsState.READY
                    orderItem.variant = null
                    orderItem.product = new Product(orderItem.product)
                    loadVariantForItem(orderItem)
                })
            })
            .catch(error => {
                $scope.state = OrderState.ERROR
                console.error('Error loading order', error)
            })
    }

    /**
     * Loads an order item's variant
     *
     * @param {Object} item The order item to load its variant from
     */
    function loadVariantForItem(item) {
        item.state = VariantsState.LOADING

        if (!item.product.id) {
            item.state = VariantsState.READY
            return
        }

        api.products
            .getVariant(item.product.id, item.product.variantId)
            .then(({ data }) => {
                item.state = VariantsState.READY
                item.variant = new ProductVariant(data)

                // Set the original value of stock variants
                stock.setOriginalStockVariant(data)
            })
            .catch(error => {
                item.state = VariantsState.ERROR
                console.error('Error loading order item variant', error)
            })
    }

    /**
     * A function that returns stock changes made by the user. If the stock of an
     * item failed to load this item is ignored, since the UI won't allow changes.
     *
     * @return {Array} Returns true if there are stock changes, false otherwise.
     */
    function getStockChanges() {
        const items = $scope.order.items
            .filter(({ state }) => state === VariantsState.READY)
            .filter(({ product }) => !product.fromContentApi())
            .map(({ variant }) => variant)
            .filter(variant => variant !== null)

        return stock.getStockQueue(items).getUpdated()
    }

    /**
     * Processes an order by setting its status to 'closed' and changing the stock of updated items
     */
    function processOrder() {
        $scope.state = OrderState.PROCESSING
        $scope.requestCanceler = $q.defer()

        const stockChanges = getStockChanges()
        const requests = stockChanges.map(_getUpdateVariantRequest)

        requests.push(api.orders.processOrder($scope.order))

        $q.all(requests)
            .then(() => {
                $scope.state = OrderState.PROCESSED
            })
            .catch(error => {
                $scope.state = OrderState.PROCESSING_ERROR
                $scope.error = error
                console.error(
                    'Error processing order and / or updating stock',
                    error,
                )
            })
    }

    function _getUpdateVariantRequest(variant) {
        return api.products.updateVariant(
            variant.productId,
            variant.id,
            variant.getRequestVersion(),
        )
    }

    function someContentApiProducts() {
        return $scope.state === OrderState.READY
            && $scope.order.items.some(({ product }) => product.fromContentApi())
    }

    $scope.close = function() {
        $uibModalInstance.close()
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss()
    }

    $scope.toggleStock = function(variant) {
        variant.voorraad = variant.voorraad ? 0 : 1
    }

    $scope.$on('$destroy', () => $scope.requestCanceler ? $scope.requestCanceler.resolve() : false)
})
