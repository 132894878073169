import MetaInfoField from './MetaInfoField'
import registry from '../registry'

const whitelistField = 'merkcode.whitelist'

/**
 * A meta info handler for the brand field. Adds useful functionality
 */
export class BrandHandler extends MetaInfoField {
    constructor(metaInfoField) {
        super(metaInfoField)
        this.whitelist = null
    }

    /**
     * This function runs after meta info has ran all the meta info field constructors.
     * @param metaInfo
     */
    initialize(metaInfo) {
        if (metaInfo.fieldExists(whitelistField)) {
            this.whitelist = metaInfo.field(whitelistField).getValues()
        }
    }

    /**
     * Only gets the whitelisted brands
     *
     * @return {Object[]} Returns the whitelisted brands. If there is no whitelist for this retailer, all brands are
     * returned
     */
    getWhitelisted() {
        return this.whitelist ? this.whitelist : this.getValues()
    }
}

registry.registerAlias('merkcode', 'brand')
export default registry.registerHandler('brand', BrandHandler)
