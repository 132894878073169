import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/src/css/main.scss'
import module from './module'

module.factory('PhotoSwipe', function () {
    let root = null

    function registerPhotoSwipeRoot($element) {
        [root] = $element.find('.pswp')
    }

    function getRoot() {
        return root
    }

    class Gallery extends PhotoSwipe {
        constructor(items, options) {
            super(root, PhotoSwipeUI, items, options)
        }
    }

    return { registerPhotoSwipeRoot, getRoot, Gallery }
})
