import debounce from 'lodash/debounce'
import '../factories/api'
import { FILTER_NAMES } from '../factories/commonFilters'
import '../services/Data'
import '../services/PartialProduct'
import '../directives/productImage/productImage'
import dock from '../modules/dock'
import seasonField from '../factories/metaInfo/handlers/seasonHandler'
import { Product } from '../shared/classes/Product'
import reportMpnModalController from './modals/ReportMpnCtrl'
import reportMpnModalTemplate from '../templates/modal/reportMpn.html'
import { ProductStatuses, ProductStatusValues, ProductStatusTranslation } from '../shared/constants/productStatus'

/* eslint-disable */
export const STATES = Object.freeze({
    INITIALIZED: Symbol('initialized'), // Initial state. User can enter search filters
    LOADING: Symbol('loading'), // User has entered search filters and we are loading products
    LOADING_ERROR: Symbol('loading_error'), // Something went wrong while loading
    SHOWING_PRODUCTS: Symbol('showing_products'), // We have loaded the products and showing them
    NO_PRODUCTS: Symbol('no_products'), // We have loaded the products but none were found
})

const controllerName = 'LinkCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $rootScope,
    $scope,
    $q,
    $location,
    $uibModal,
    Data,
    PartialProduct,
    api,
    metaInfo,
    commonFilters,
    Language
) {
    const productFiltersTranslate = 'product.property.'
    const filters = getLinkFilters()
    const sortItems = getSortItems()

    // Configuration for the Filter Controller
    const filterControllerConfig = {
        filterOnSelect: false,
        validationFunction: filterValidationFunction,
    }

    // Options for the filter bar
    const filterBarOptions = {
        searchButton: false,
    }

    const filterController = new Data.Filters.Controller(
        filters,
        filterControllerConfig,
    )

    const paginationController = new Data.Pagination.Controller()
    const selectionController = new Data.Selection.Controller(/* selectionControllerConfig, massActions */)
    const sortingController = new Data.Sorting.Controller(sortItems)

    $scope.filterBarOptions = filterBarOptions
    $scope.contained = false
    $scope.requestCanceler = null
    $scope.search = attemptSearch
    $scope.resetFilters = resetFilters
    $scope.restictiveBrand = false
    $scope.newProduct = newProduct
    $scope.linkProduct = linkProduct
    $scope.STATES = STATES
    $scope.state = STATES.INITIALIZED
    $scope.flagMpn = flagMpn
    $scope.searchResultFilters = {}
    $scope.console = console

    $scope.matches = new Data.DataController(
        filterController,
        paginationController,
        selectionController,
        sortingController,
    )

    if (filterController.hasActiveFilters()) {
        attemptSearch()
    }

    const debouncedSearch = debounce(() => attemptSearch(), 700)
    filterController.onChange(() => {
        if (filterController.isValid()) {
            $scope.requestCanceler = null
            $scope.state = STATES.LOADING
        }

        if (!filterController.hasActiveFilters()) {
            $scope.state = STATES.INITIALIZED
        }

        debouncedSearch()
    })

    filterController.filters.find(element => element.name === FILTER_NAMES.BRAND).onSelect(() => {
        checkRestrictiveBrand()
    })

    checkRestrictiveBrand()

    function checkRestrictiveBrand() {
        const brandCode = filterController.filters.find(element => element.name === FILTER_NAMES.BRAND).getSelected()?.code

        if (!brandCode) {
            $scope.restictiveBrand = false
            return;
        }

        metaInfo.load(Language.get())
            .then(metaInfo => {
                $scope.restictiveBrand = metaInfo.brandIsRestricted(brandCode)
        })
    }

    function loadProducts(filters) {
        $scope.state = STATES.LOADING
        $scope.requestCanceler = $q.defer()

        const statusFilterValue = popStatusFilterValue(filters)

        const filterBody = {
            ...filters,
            ...(
                filters.merkProductNummer ?
                { merkProductNummer: `%${filters.merkProductNummer}%` } :
                {}
            ),
            ...(
                filters.naam ?
                    { naam: `%${filters.naam}%` } :
                {}
            )
        }

        $q.all({
            // Load metaInfo and product matcher result simultaneously
            metaInfo: metaInfo.load(Language.get()),
            response: api.products.match(filterBody, statusFilterValue, $scope.requestCanceler),
        })
            .then(({ metaInfo, response }) => {
                // Add a 'season' property to the product
                response.data.producten.forEach(match => {
                    match.season = metaInfo
                        .field(seasonField)
                        .getName(match.seizoen)
                })

                // TODO: Implement SortingService
                return response.data.producten.reverse()
            })
            .then(matches => {
                const linkableProducts = matches
                    .map(product => new Product(product, true))
                    .filter(product => product.hasImage())

                $scope.matches
                    .setData(linkableProducts)
                    .filter()

                $scope.searchResultFilters = filters

                if ($scope.matches.getData().length > 0) {
                    $scope.state = STATES.SHOWING_PRODUCTS
                } else {
                    $scope.state = STATES.NO_PRODUCTS
                }
            })
            .catch(error => {
                console.error(error)

                if (error.xhrStatus === 'abort') {
                    // Aborted by user
                    $scope.state = STATES.INITIALIZED
                } else {
                    // Error while loading
                    $scope.state = STATES.LOADING_ERROR
                }
            })
    }

    /**
     * This function returns the value for the productStatus filter from the filters object
     * and then removes that key from the filters object.
     * If the filters object does not contain a productStatus, this function return null
     *
     * @param {Object} filters
     * @returns {null|string}
     */
    function popStatusFilterValue(filters) {
        if (!filters.hasOwnProperty('productStatus')) {
            return null;
        }

        const productStatus = filters.productStatus;
        delete filters.productStatus

        return ProductStatusValues[productStatus]
    }

    /**
     * The function that is used by the filter controller to verify whether the entered filter
     * values are a valid filter configuration
     *
     * @param {FilterController} filterController The filter controller instance
     * @return {Boolean} Returns true if the filter bar has either ean, mpn or brand and main
     * category as active filters
     */
    function filterValidationFunction(filterController) {
        const activeProperties = filterController
            .getActiveFilters()
            .map(filter => filter.property)
        return (
            activeProperties.includes('ean') ||
            activeProperties.includes('merkProductNummer') ||
            (activeProperties.includes('merk') &&
                activeProperties.includes('hoofdCategorie'))
        )
    }

    function getSortItems() {
        const price = new Data.Sorting.SortItem({
            property: 'prijs',
            sortFunction: 'number',
        })

        $scope.sortItems = {
            price,
        }

        return Object.values($scope.sortItems)
    }

    /**
     * Function that is called when the user changes any of the filter values
     */
    function attemptSearch() {
        if (!filterController.isValid()) {
            return
        }

        loadProducts(_getActiveFiltersAsObject())
    }

    /**
     * Resets all filters and moves to the initialized state
     */
    function resetFilters() {
        $scope.state = STATES.INITIALIZED
        $scope.matches.filters.resetFilters()
    }

    /**
     * Moves the user to the create new product page with the filters as prefilled values
     */
    function newProduct() {
        PartialProduct.set(_getActiveFiltersAsObject())
        $location.path('link/prefilled')
    }

    /**
     * Goes to the add product page prefilled with the given product
     *
     * @param {Object} product The product to link, has to have an 'id' property
     */
    function linkProduct(product) {
        $location.path(
            `link/product/${product[Product.getLinkedProductProperty()]}`,
        )
    }

    /**
     * Opens a modal which allows the user to report an incorrect brand product number (MPN).
     *
     * @param {Product} product The product with an incorrect brand product number
     */
    function flagMpn(product) {
        $uibModal.open({
            template: reportMpnModalTemplate,
            controller: reportMpnModalController,
            size: 'md',
            resolve: {
                product: () => product
            },
        })
    }

    /**
     * Get all filters for the link page
     */
    function getLinkFilters() {
        const linkFilters = [
            getBrandProductNumberFilter(),
            getEanFilter(),
            commonFilters.getBrandFilter(),
            getNameFilter(),
            ...commonFilters.getCategoryFilters(true),
            commonFilters.getColorFilter(),
            commonFilters.getSeasonFilter(false),
        ]

        if ($rootScope.admin.isAdmin()) {
            linkFilters.push(getStatusFilter())
        }

        return linkFilters
    }

    /**
     * Gets a filter for filtering on EAN code
     */
    function getEanFilter() {
        return new Data.Filters.Filter(
            'ean',
            `${productFiltersTranslate}ean`,
            'ean',
            [],
            {
                searchOnly: true,
                labelProperty: '.label',
                ngModelOptions: {
                    debounce: 100,
                },
                options: {
                    default: '',
                    empty: '',
                },
            },
        )
    }

    /**
     * Gets a filter for filtering on brand product number
     */
    function getBrandProductNumberFilter() {
        return new Data.Filters.Filter(
            'mpn',
            `${productFiltersTranslate}mpn`,
            'merkProductNummer',
            [],
            {
                searchOnly: true,
                labelProperty: '.label',
                ngModelOptions: {
                    debounce: 100,
                },
                options: {
                    default: '',
                    empty: '',
                },
            },
        )
    }

    /**
     * Gets a filter for filtering on product name
     */
    function getNameFilter() {
        return new Data.Filters.Filter(
            'name',
            `${productFiltersTranslate}name`,
            'naam',
            [],
            {
                searchOnly: true,
                labelProperty: '.label',
                ngModelOptions: {
                    debounce: 100,
                },
                options: {
                    default: '',
                    empty: '',
                },
            },
        )
    }

    /**
     * Gets a filter for filtering on status
     */
    function getStatusFilter() {
        return new Data.Filters.Filter(
            'productStatus',
            `products.status`,
            'productStatus',
            [
                ProductStatuses.statusAll,
                ProductStatuses.statusOnline,
                ProductStatuses.statusProcessing,
                ProductStatuses.statusNew,
            ],
            {
                search: false,
                labelProperty: '.label',
                placeholderProperty: '.default',
                options: {
                    fixed: true,
                    limit: 0,
                    translate: true,
                    default: ProductStatuses.statusAll,
                    titleMapFunction: status => ProductStatusTranslation[status]
                },
            },
        )
    }

    /**
     * Grabs the active filters from the filter controller and returns them as an object where the
     * keys are the filter properties and the value the parsed selected value
     *
     * @return {Object} The active filters as an object
     * @private
     */
    function _getActiveFiltersAsObject() {
        const filters = {}

        filterController.getActiveFilters().forEach(filter => {
            filters[filter.property] = filter.getSelected(true, true)
        })

        return filters
    }

    $scope.$on('$destroy', () => $scope.requestCanceler ? $scope.requestCanceler.resolve() : false)
})
