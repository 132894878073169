import Version from '../classes/Version'

// The current DOCK version
export const currentVersion = new Version('1.5.1')

// All DOCK versions
export const versions = [
    new Version('1.0.0'),
    new Version('1.0.1'),
    new Version('1.0.2'),
    new Version('1.1.0'),
    new Version('1.1.1'),
    new Version('1.1.2'),
    new Version('1.2.0'),
    new Version('1.3.0'),
    new Version('1.3.1'),
    new Version('1.3.2'),
    new Version('1.3.3'),
    new Version('1.4.0'),
    new Version('1.4.1'),
    new Version('1.4.2'),
    new Version('1.5.0'),
    currentVersion,
]
