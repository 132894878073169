/* eslint-disable */
import dock from '../modules/dock'
import '../factories/api'
import stockCache from '../shared/instances/stockAndPriceCache'
import Cookie from 'js-cookie'
import { DOCK_ADMIN_LOGIN_COOKIE_KEY } from '../shared/constants/dockAdminLoginCookieKey'
import jwtDecode from 'jwt-decode'
import environment from '../shared/instances/environment'

const storageKeys = {
    username: 'username',
    keepLoggedIn: 'keep_logged_in',
    token: 'token',
    sessionId: 'session_id',
    role: 'role',
    hasApiKey: 'has_api_key',
    brandOwner: 'is_brand_owner',
}

export const RetailerStatus = Object.freeze({
    INITIALIZING: Symbol('initializing'), // Angular hasn't loaded, so some functions are not available
    UNDETERMINED: Symbol('undetermined'), // A request verifying whether the retailer is logged in has not been issued
    LOADING: Symbol('loading'), // A request verifying whether the retailer is logged in has been issued
    LOGGED_IN: Symbol('logged_in'), // The retailer is logged in
    NOT_LOGGED_IN: Symbol('not_logged_in'), // The retailer is not logged in
})

const EVENT_BASE = 'retailer'
export const RetailerEvent = Object.freeze({
    LOGIN_SUCCESSFUL: `${EVENT_BASE}.login_successful`, // A successful login has occurred
    LOGOUT_SUCCESSFUL: `${EVENT_BASE}.logout_successful`, // A successful logout has occurred
    LOGGED_OUT: `${EVENT_BASE}.logged_out`, // The user was logged out
})

export const LoginError = Object.freeze({
    CREDENTIALS: Symbol('credentials'), // The user entered incorrect credentials
    OTHER: Symbol('other'), // Some unknown error happened
})

class RetailerService {
    constructor() {
        this.status = RetailerStatus.INITIALIZING
        this.reauthenticating = false
        this.resetInfo()
    }

    clearAll() {
        this.resetInfo()
        this.clearSessionId()
        this.clearRole()
        this.clearApiKey()
        this.clearBrandOwner()
        this.clearUsername()
        this.clearKeepLoggedIn()
        this.clearToken()
    }

    getBasePath() {
        return this.isBrandOwner() ? 'products/online' : 'orders/new'
    }

    isLoggedIn() {
        return this.status === RetailerStatus.LOGGED_IN
    }

    resetInfo() {
        this.info = {
            code: null,
            name: null,
            email: null,
        }
    }

    setUsername(username) {
        localStorage.setItem(storageKeys.username, username)
    }

    getUsername() {
        return localStorage.getItem(storageKeys.username)
    }

    clearUsername() {
        localStorage.removeItem(storageKeys.username)
    }

    setKeepLoggedIn(keepLoggedIn) {
        localStorage.setItem(
            storageKeys.keepLoggedIn,
            (!!keepLoggedIn).toString(),
        )
    }

    keepLoggedIn() {
        return localStorage.getItem(storageKeys.keepLoggedIn) === 'true'
    }

    clearKeepLoggedIn() {
        localStorage.removeItem(storageKeys.keepLoggedIn)
    }

    setToken(token) {
        localStorage.setItem(storageKeys.token, token)
    }

    getToken() {
        return localStorage.getItem(storageKeys.token)
    }

    clearToken() {
        return localStorage.removeItem(storageKeys.token)
    }

    loadDockAdminToken() {
        const cookie = Cookie.get(DOCK_ADMIN_LOGIN_COOKIE_KEY)

        if (!cookie) {
            return false
        }

        const { username } = jwtDecode(cookie)
        this.setUsername(username)
        this.setToken(cookie)
        Cookie.remove(DOCK_ADMIN_LOGIN_COOKIE_KEY)

        return true
    }

    /* SESSION STORAGE */
    setSessionId(sessionId) {
        sessionStorage.setItem(storageKeys.sessionId, sessionId)
    }

    getSessionId() {
        return sessionStorage.getItem(storageKeys.sessionId)
    }

    clearSessionId() {
        sessionStorage.removeItem(storageKeys.sessionId)
    }

    setRole(role) {
        sessionStorage.setItem(storageKeys.role, role)
    }

    getRole() {
        return sessionStorage.getItem(storageKeys.role)
    }

    clearRole() {
        sessionStorage.removeItem(storageKeys.role)
    }

    setHasApiKey(hasApiKey) {
        sessionStorage.setItem(storageKeys.hasApiKey, (!!hasApiKey).toString())
    }

    hasApiKey() {
        return sessionStorage.getItem(storageKeys.hasApiKey) === 'true'
    }

    clearApiKey() {
        sessionStorage.removeItem(storageKeys.hasApiKey)
    }

    setBrandOwner(brandOwner) {
        sessionStorage.setItem(
            storageKeys.brandOwner,
            (!!brandOwner).toString(),
        )
    }

    isBrandOwner() {
        return sessionStorage.getItem(storageKeys.brandOwner) === 'true'
    }

    clearBrandOwner() {
        sessionStorage.removeItem(storageKeys.brandOwner)
    }

    _throwIfUnsuccessfulResponse(response) {
        if (!response.data || response.data.result !== 'SUCCESS') {
            throw LoginError.CREDENTIALS
        }
    }

    _setGeneralAuthenticationFields(response) {
        this.setSessionId(response.data.sessionId)
        this.setRole(response.data.role)
        this.setHasApiKey(response.data.hasAPIKey)
        this.setBrandOwner(response.data.brandOwner)
    }
}

const retailerService = new RetailerService()
export default retailerService

dock.service('Retailer', /* @ngInject */ function ($rootScope, $q, api) {
    retailerService.status = RetailerStatus.UNDETERMINED

    // Watch the retailer status to emit corresponding events
    $rootScope.$watch(
        () => retailerService.status,
        (newStatus, oldStatus) => {
            switch (newStatus) {
                case RetailerStatus.LOGGED_IN:
                    $rootScope.$broadcast(
                        RetailerEvent.LOGIN_SUCCESSFUL,
                        retailerService.info,
                    )
                    break

                case RetailerStatus.NOT_LOGGED_IN:
                    if (oldStatus === RetailerStatus.LOGGED_IN) {
                        $rootScope.$broadcast(RetailerEvent.LOGGED_OUT)
                    }

                    break
            }
        },
    )

    /**
     * Checks the current state of the retailer
     *
     * @return {Promise} Returns a promise that resolves with the retailer name when the retailer is logged in and
     * rejects when the retailer is not logged in.
     */
    retailerService.checkRetailerStatus = function() {
        retailerService.status = RetailerStatus.LOADING

        return api.user
            .currentRetailerStatus()
            .then(response => {
                retailerService.status = RetailerStatus.LOGGED_IN

                retailerService.info = {
                    code: response.data.retailerCode,
                    name: response.data.retailerNaam,
                    email: response.data.retailerEmail,
                    intercomHash: response.data.retailerIntercomHash
                }

                return retailerService.info
            })
            .catch(error => {
                retailerService.status = RetailerStatus.NOT_LOGGED_IN

                throw error
            })
    }

    /**
     * Attempts to log in a retailer with the given parameters.
     *
     * @param {String} username The username of the retailer to log in
     * @param {String} password The password of the retailer to log in
     * @param {Boolean} keepLoggedIn Whether the retailer wants to receive a token so he can reauthenticate later and
     * keep logged in after his session expires
     * @return {Promise} Returns a promise that resolves with the retailer info when the retailer is logged in and which
     * rejects otherwise
     */
    retailerService.login = function(username, password, keepLoggedIn) {
        retailerService.status = RetailerStatus.LOADING

        const user = {
            username,
            password,
            keepLoggedIn,
        }

        return $q((resolve, reject) => {
            api.login
                .login(user)
                .then(response => {
                    retailerService._throwIfUnsuccessfulResponse(response)
                    retailerService._setGeneralAuthenticationFields(response)

                    retailerService
                        .checkRetailerStatus()
                        .then(info => {
                            retailerService.status = RetailerStatus.LOGGED_IN

                            retailerService.setUsername(user.username)
                            retailerService.setKeepLoggedIn(user.keepLoggedIn)

                            const token = response.data.token

                            if (token === null) {
                                retailerService.clearToken()
                            } else {
                                retailerService.setToken(token)
                            }

                            resolve(info)
                        })
                        .catch((error) => {
                            retailerService.status =
                                RetailerStatus.NOT_LOGGED_IN

                            if (error !== LoginError.CREDENTIALS) {
                                error = LoginError.OTHER
                            }

                            reject(error)
                        })
                })
                .catch((error) => {
                    retailerService.status = RetailerStatus.NOT_LOGGED_IN

                    if (error !== LoginError.CREDENTIALS) {
                        error = LoginError.OTHER
                    }

                    reject(error)
                })
        })
    }

    /**
     * Attempts to log out the current retailer
     *
     * @return {Promise} Returns a promise that resolves when the retailer is logged out and rejects otherwise
     */
    retailerService.logout = function() {
        retailerService.status = RetailerStatus.NOT_LOGGED_IN

        return api.login
            .logout()
            .then(() => {
                retailerService.clearAll()
                stockCache.clear()
                $rootScope.$broadcast(RetailerEvent.LOGOUT_SUCCESSFUL)
            })
            .catch(error => {
                retailerService.status = RetailerStatus.UNDETERMINED
                throw error
            })
    }

    /**
     * Attempts to reauthenticate the retailer with the token stored.
     *
     * @return {Promise} Returns a promise that resolves with the retailer name when the retailer was reauthenticated
     * successfully and which rejects when no token was available or the retailer was not reauthenticated successfully.
     */
    retailerService.reauthenticate = function() {
        retailerService.status = RetailerStatus.LOADING

        const dockAdminLogin = retailerService.loadDockAdminToken()

        const token = retailerService.getToken()
        const username = retailerService.getUsername()

        if (token === null || username === null) {
            retailerService.status = RetailerStatus.NOT_LOGGED_IN

            return $q.reject(
                new Error('No token was found to reauthenticate with'),
            )
        }

        const reauthenticateBody = {
            username,
            token,
        }

        // If we're using DOCK Admin to login, prevent using the token twice
        if (dockAdminLogin) {
            retailerService.clearToken()
        }

        return $q((resolve, reject) => {
            api.login
                .reauthenticate(reauthenticateBody)
                .then(response => {
                    retailerService._throwIfUnsuccessfulResponse(response)
                    retailerService._setGeneralAuthenticationFields(response)

                    retailerService
                        .checkRetailerStatus()
                        .then(info => {
                            retailerService.status = RetailerStatus.LOGGED_IN
                            resolve(info)
                        })
                        .catch(error => {
                            retailerService.status =
                                RetailerStatus.NOT_LOGGED_IN
                            reject(error)
                        })
                        .finally(() => {
                            retailerService.reauthenticating = false
                        })
                })
                .catch(error => {
                    retailerService.status = RetailerStatus.NOT_LOGGED_IN
                    reject(error)
                })
                .finally(() => {
                    retailerService.reauthenticating = false
                })
        })
    }

    return retailerService
})
