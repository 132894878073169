import 'babel-polyfill'

import '../vendor'
import './configs'
import './runs'
import './modules/dock'
import './controllers/AppCtrl'
import './sass/base.scss'

// This reloads the application when a hot module reload is triggering
if (module.hot) {
    module.hot.dispose(() => {
        window.location.reload()
        throw new Error('Reloading instead of HMR')
    })
}
