import dock from '../../modules/dock'
import productImageTemplate from './productImage.html'
import { Product } from '../../shared/classes/Product'
import '../../modules/photoswipe'

dock.directive('productImage', /* @ngInject */ function () {
    return {
        restrict: 'E',
        template: productImageTemplate,
        scope: {
            product: '=',
            imageWidth: '@',
        },
        link: {

            // We need the gallery property before the linking process because otherwise the settings won't resolve
            pre: (scope, element) => {
                element.addClass('dock-image-make-transparent-white')
                scope.galleryProperty = Product.getGalleryProperty()
            },
        },
    }
})
