import '../factories/api'
import '../services/Retailer'
import '../directives/dockFilterBar/dockFilterBar'
import '../directives/sortable/sortable'
import '../directives/includeReplace/includeReplace'
import '../directives/productImage/productImage'
import '../directives/rowLoading/rowLoading'
import '../services/Data'
import '../services/GetPropertyList'
import '../services/ExpandedOrders'
import '../services/Tab'
import processModalController from './modals/ProcessCtrl'
import processModalTemplate from '../templates/modal/process.html'
import deficiencyModalController from './modals/DeficiencyCtrl'
import deficiencyModalTemplate from '../templates/modal/deficient.html'
import dock from '../modules/dock'
import { Product } from '../shared/classes/Product'
import Tab from '../shared/classes/Tab'

export const OrdersTabs = Object.freeze({
    NEW: new Tab('orders.tabs.new', 'new'),
    CLOSED: new Tab('orders.tabs.closed', 'closed'),
    SEARCH: new Tab('orders.tabs.search', 'search', true),
})

const controllerName = 'OrdersCtrl'
export default controllerName

/* eslint-disable */
dock.controller(
    controllerName,
    /* @ngInject */ function (
        $scope,
        $q,
        $location,
        $routeParams,
        $uibModal,
        $translate,
        api,
        toastr,
        ExpandedOrders,
        ConstantsTranslator,
        GetPropertyList,
        Data,
        Retailer,
        Tab
    ) {
        const STATE = ($scope.STATE = {
            LOADING: "loading",
            IDLE: "idle",
            ERROR: "error",
        });

        let C = ($scope.C = {});
        const today = new Date();
        const constants = ($scope.constants = ConstantsTranslator.setConstants(
            {
                ORDER: {
                    CARRIER: {
                        UPS: "ups",
                        TRUNKRS: "trunkrs",
                        POSTNL: "postnl",
                        FADELLO: "fadello"
                    },
                    STATUS: {
                        NEW: "NIEUW",
                        CLOSED: "AFGEROND",
                    },
                    DATE: {
                        LAST_7_DAYS: new Date(
                            today.getFullYear(),
                            today.getMonth(),
                            today.getDate() - 7
                        ),
                        LAST_14_DAYS: new Date(
                            today.getFullYear(),
                            today.getMonth(),
                            today.getDate() - 14
                        ),
                        LAST_30_DAYS: new Date(
                            today.getFullYear(),
                            today.getMonth(),
                            today.getDate() - 30
                        ),
                    },
                    SOURCE: {
                        MAGENTO_V1: "MAGENTO_V1",
                        MAGENTO_V2: "MAGENTO_V2"
                    }
                },
            },
            C
        ));
        let downloadLabelQueueToastr = null;

        $scope.constantMaps = {
            resolutionType: constants.generateConstantMap(),
        };

        const loadOrdersForTab = ($scope.loadOrdersForTab = function (tab) {
            if (!tab) {
                return;
            }

            $scope.state = STATE.LOADING;
            $scope.requestCanceler = $q.defer();

            let request = null;

            if (tab === OrdersTabs.SEARCH) {
                $scope.searchString = $location.search().query || "";
                request = api.orders.search(
                    $scope.searchString,
                    "orderIdPart",
                    $scope.requestCanceler
                );
            } else {
                let status = getOrderStatus(tab);
                request = api.orders.getOrders(
                    status,
                    $scope.requestCanceler,
                    filters,
                    $scope.activeSort,
                    $scope.pagination
                );
            }

            request
                .then((response) => {
                    $scope.state = STATE.IDLE;
                    const responseOrders = response.data.orders;
                    $scope.pagination.totalCount =
                        response.data.totalCount || response.data.orders.length;
                    if (
                        response.data.currentPage &&
                        orders.pagination.pageModel !== response.data.currentPage
                    ) {
                        // override the local page with what we get from the API
                        orders.pagination.pageModel = response.data.currentPage;
                        orders.pagination.setPreference(
                            "current_page",
                            orders.pagination.pageModel
                        );
                    }
                    responseOrders.forEach(setOrderMetadata);
                    responseOrders.forEach(checkShippingStatus);
                    // <IWSNL-8881> Add fallback for reference ID's
                    responseOrders.map((responseOrder) => {
                        responseOrder.orderReferenceId =
                            responseOrder.orderReferenceId ?? responseOrder.orderId.orderId;
                        return responseOrder;
                    });
                    // </IWSNL-8881>
                    orders.setData(responseOrders)
                    loadFilterOptions(filters, orders);

                    // Expand the orders that were expanded
                    orders.getData().forEach(function (order) {
                        if (ExpandedOrders.isExpanded(order.orderReferenceId)) {
                            $scope.getDetailsForOrder(order);
                        }
                    });
                })
                .catch((error) => {
                    orders.setData([])

                    $scope.state = STATE.ERROR;
                    console.error(error);
                });
        });

        const loadData = function () {
            loadOrdersForTab(Tab.active);
        };

        // Configuration for the Filter Controller
        const filterControllerConfig = {
            filterOnSelect: false,
            executeCallback: () => {
                $scope.pagination.page = 1
                loadData()
            },
        };
        // Options for the filter bar
        const filterBarOptions = {
            searchButton: false,
            executeButton: true,
        };
        // These are set by getSortItems();
        $scope.sortItems = {};
        $scope.state = STATE.LOADING;
        $scope.requestCanceler = null;
        $scope.Tab = Tab;
        $scope.OrdersTabs = OrdersTabs;
        $scope.escapedSessionId = window.encodeURIComponent(
            Retailer.getSessionId()
        );
        $scope.downloadLabelQueueOrderReferenceIdSourceObjects = [];
        $scope.filterBarOptions = filterBarOptions;
        $scope.$watch(
            "downloadLabelQueueOrderReferenceIdSourceObjects",
            (orderReferenceIdSourceObject) => {
                if (orderReferenceIdSourceObject.length === 0) {
                    toastr.clear(downloadLabelQueueToastr);
                    downloadLabelQueueToastr = null;
                } else if (downloadLabelQueueToastr === null) {
                    $translate("orders.toast.downloadingLabels").then((message) => {
                        downloadLabelQueueToastr = toastr.info(message, null, {
                            timeOut: 0,
                            extendedTimeOut: 0,
                        });
                    });
                }
            }
        );

        $scope.search = {
            model: null,
        };

        const selectionControllerConfig = {
            itemTranslationKey: "orders.selectionItem",
            identifier: "orderReferenceId",
        };
        const filterTranslationPrefix = "orders.headers";
        const filters = getOrdersFilters();
        const sortItems = getOrdersSortItems();
        const filterController = new Data.Filters.Controller(
            filters,
            filterControllerConfig
        );
        const paginationController = new Data.Pagination.Controller();
        const selectionController = new Data.Selection.Controller(
            selectionControllerConfig,
            getMassActions()
        );
        const sortingController = new Data.Sorting.Controller(sortItems);
        const orders = ($scope.orders = new Data.DataController(
            filterController,
            paginationController,
            selectionController,
            sortingController
        ));

        // start with the natural sorting by the backend
        const sortingPreference = orders.sorting.getPreference();
        let sortField = null;
        let sortOrder = null;
        if (sortingPreference) {
            sortField = sortingPreference.identifier;
            sortOrder = sortingPreference.reverse ? "desc" : "asc";
        }
        $scope.activeSort = {
            sortField,
            sortOrder,
        };
        $scope.pagination = {
            page: orders.pagination.pageModel || 1,
            itemsPerPage: orders.pagination.getPreference("items_per_page") || 20,
        };

        sortingController.onSort((data, active) => {
            const sortField = active.getIdentifier();
            const sortOrder = active.reverse ? "desc" : "asc";

            let shouldReload = false;

            if (
                $scope.activeSort.sortField !== sortField ||
                $scope.activeSort.sortOrder !== sortOrder
            ) {
                shouldReload = true;
            }
            $scope.activeSort = {
                sortField,
                sortOrder,
            };
            if (shouldReload) {
                loadData();
            }
        });

        paginationController.onItemsPerPageChange((itemsPerPage) => {
            const oldItemsPerPage = $scope.pagination.itemsPerPage;
            const shouldReload = oldItemsPerPage !== itemsPerPage;
            $scope.pagination.itemsPerPage = itemsPerPage;
            if (shouldReload) {
                $scope.pagination.page = 1
                loadData();
            }
        });

        $scope.changePage = function () {
            const oldPage = $scope.pagination.page;
            const shouldReload = oldPage !== orders.pagination.pageModel;
            $scope.pagination.page = orders.pagination.pageModel;
            if (shouldReload) {
                loadData();
            }
        };

        $scope.getDetailsForOrder = function (order) {
            order.error = false;
            order.items = [];
            order.isCollapsed = !order.isCollapsed;

            if (order.isCollapsed) {
                ExpandedOrders.remove(order.orderReferenceId);
            } else {
                ExpandedOrders.add(order.orderReferenceId);
            }

            if (!order.isCollapsed) {
                order.loading = true;
                order.error = false;

                api.orders
                    .getOrder(order.orderReferenceId, order.orderId.source)
                    .then((response) => {
                        order.items = response.data.items.map((item) => {
                            // <IWSNL-8881> Add fallback for reference ID's
                            item.orderReferenceId =
                                item.orderReferenceId ?? order.orderReferenceId;
                            // </IWSNL-8881>
                            item.product = new Product(item.product);

                            return item;
                        });
                    })
                    .catch(() => {
                        order.error = true;
                    })
                    .finally(() => {
                        order.loading = false;
                    });
            }
        };

        $scope.searchOrders = function (searchString) {
            $location.search("query", searchString);

            if (Tab.active === OrdersTabs.SEARCH) {
                loadOrdersForTab(Tab.active);
            } else {
                Tab.switchTab(OrdersTabs.SEARCH);
            }
        };

        $scope.printLabel = function (orderReferenceId, orderIdSource, $event) {
            $event.stopPropagation();
            printLabelsByOrderReferenceIdSourceObjects([{
                orderId: orderReferenceId,
                source: orderIdSource
            }]);
        };

        $scope.processOrder = function (order, $event) {
            $event.stopPropagation();
            order.error = null;

            // If all order items are marked as deficient, there's no need for us to show a modal
            if (order.isOrderDeficient) {
                order.loading = true;

                return api.orders
                    .processOrder(order)
                    .then(() => {
                        loadOrdersForTab(Tab.active);
                    })
                    .catch((error) => {
                        console.error("Error while processing order", error);
                        order.error = true;
                    })
                    .finally(() => {
                        order.loading = false;
                    });
            }

            // show modal
            let modalInstance = $uibModal.open({
                template: processModalTemplate,
                controller: processModalController,
                size: "lg",
                resolve: {
                    order: function () {
                        return order;
                    },
                },
            });

            modalInstance.result.then(
                function () {
                    loadOrdersForTab(Tab.active);
                },
                function () {
                    // dismissed wihtout save
                }
            );
        };

        $scope.deficient = function (item, $event) {
            $event.stopPropagation();

            // show modal
            let modalInstance = $uibModal.open({
                template: deficiencyModalTemplate,
                controller: deficiencyModalController,
                size: "md",
                resolve: {
                    item: function () {
                        return item;
                    },
                },
            });

            modalInstance.result.then(
                function () {
                    loadOrdersForTab(Tab.active);
                },
                function () {
                    // dismissed wihtout save
                }
            );
        };

        $scope.isMagento1Order = function (order) {
            return order.orderId.source === constants.getValue(C.ORDER.SOURCE.MAGENTO_V1);
        }

        $scope.clickCheckboxToggle = function (order, $event) {
            $event.stopPropagation();
            if (!$scope.isCheckboxToggleAllowed(order)) {
                return;
            }

            $scope.orders.selection.toggleSelect(order);
        };

        $scope.clickCheckboxToggleAll = function ($event, source) {
            $event.stopPropagation();
            if (!$scope.isCheckboxToggleAllAllowed(source)) {
                return;
            }

            const orders = $scope.orders.selection.selectable.filter(order => order.orderId.source === constants.getValue(source));
            if ($scope.allSelected(source) || $scope.orders.selection.selected.length === 0) {
                orders.forEach(order => $scope.orders.selection.toggleSelect(order));
            } else {
                orders.forEach(order => $scope.orders.selection.select(order));
            }
        }

        $scope.allSelected = function (source) {
            const orders = $scope.orders.selection.selectable?.filter(order => order.orderId.source === constants.getValue(source)) || [];
            return orders.length ? orders.every(order => $scope.orders.selection.isSelected(order)) : false
        }

        $scope.isCheckboxToggleAllowed = function (order) {
            return $scope.selectionIsCompatibleWithSource($scope.orders.selection.selected, order.orderId.source)
        }

        $scope.isCheckboxToggleAllAllowed = function (source) {
            return $scope.selectionIsCompatibleWithSource($scope.orders.selection.selected, constants.getValue(source))
        }

        $scope.selectionIsCompatibleWithSource = function (selection, source) {
            return selection.length ? selection[0].orderId.source === source : true
        }

        function setOrderMetadata(order) {
            order.isCollapsed = true;

            // IWSNL-3522 | Difference between RMA and order
            order.customerName =
                order.customer.firstName + " " + order.customer.lastName;
        }

        function checkShippingStatus(order) {
            order.shippingStatus = order.shippingStatus || "unknown";
        }

        function getOrderStatus(tab) {
            switch (tab.route) {
                case "new":
                    return constants.getValue(C.ORDER.STATUS.NEW);
                case "closed":
                    return constants.getValue(C.ORDER.STATUS.CLOSED);
            }
        }

        /**
         * Returns the filters for the orders page.
         *
         * @orders {Filter[]} The filter objects specific for the orders page
         */
        function getOrdersFilters() {
            return [
                getCarrierFilter(),
                getDateFilter(),
                getCustomerFilter(),
                getPhoneFilter(),
                getOrderNumberFilter(),
                getInvoiceNumberFilter(),
            ];
        }

        function getOrdersSortItems() {
            const date = new Data.Sorting.SortItem({
                property: "dateTime",
                sortFunction: "date",
            });

            const status = new Data.Sorting.SortItem({
                property: "shippingStatus",
                sortFunction: "stringFast",
            });

            const customer = new Data.Sorting.SortItem({
                property: "customerName",
            });

            const phone = new Data.Sorting.SortItem({
                property: "billingPhoneNumber",
            });

            const orderNumber = new Data.Sorting.SortItem({
                property: "orderReferenceId",
                sortFunction: "stringFast",
            });

            const invoiceNumber = new Data.Sorting.SortItem({
                property: "magento2OrderIncrementId",
                sortFunction: "stringFast",
            });

            const price = new Data.Sorting.SortItem({
                property: "totaalPrijs",
                sortFunction: "number",
            });

            $scope.sortItems = {
                date,
                status,
                customer,
                phone,
                orderNumber,
                invoiceNumber,
                price,
            };

            return Object.values($scope.sortItems);
        }

        function loadFilterOptions(filters, orders) {
            const unfixedFilters = filters.filter((filter) => {
                return !filter.config.options.fixed && !filter.config.searchOnly
            });

            GetPropertyList.loadFixedFilterOptions(unfixedFilters, orders);
        }

        function getCarrierFilter() {
            const name = "carrier";
            const translationLabel = `${filterTranslationPrefix}.carrier`;
            const property = "carrierCode";
            const options = [
                C.ORDER.CARRIER.UPS,
                C.ORDER.CARRIER.POSTNL,
                C.ORDER.CARRIER.TRUNKRS,
                C.ORDER.CARRIER.FADELLO,
            ];

            const config = {
                options: {
                    fixed: true,
                    translate: true,
                    mapFunction: (filter) => {
                        return constants.getValue(filter);
                    },
                },
            };

            return new Data.Filters.Filter(
                name,
                translationLabel,
                property,
                options,
                config
            );
        }

        function getDateFilter() {
            const name = "date";
            const translationLabel = `${filterTranslationPrefix}.date`;
            const property = "dateTime";
            const options = [
                C.ORDER.DATE.LAST_7_DAYS,
                C.ORDER.DATE.LAST_14_DAYS,
                C.ORDER.DATE.LAST_30_DAYS,
            ];

            const config = {
                options: {
                    fixed: true,
                    translate: true,
                    mapFunction: (filter) => {
                        return constants.getValue(filter);
                    },
                },
                filterFunction: function (order, filter) {
                    const selected = filter.getSelected(true, true);

                    // Add the dateMilliseconds property if it doesn't exist
                    if (order.dateMilliseconds === undefined) {
                        order.dateMilliseconds = new Date(order.dateTime).getTime();
                    }

                    return order.dateMilliseconds > selected.getTime();
                },
                search: false,
            };

            return new Data.Filters.Filter(
                name,
                translationLabel,
                property,
                options,
                config
            );
        }

        function getCustomerFilter() {
            const name = "customer";
            const translationLabel = `${filterTranslationPrefix}.customer`;
            const property = "customerName";
            const options = [];
            const config = { searchOnly: true };

            return new Data.Filters.Filter(
                name,
                translationLabel,
                property,
                options,
                config
            );
        }

        function getPhoneFilter() {
            const name = "phone";
            const translationLabel = `${filterTranslationPrefix}.phone`;
            const property = "billingPhoneNumber";
            const options = [];
            const config = { searchOnly: true };

            return new Data.Filters.Filter(
                name,
                translationLabel,
                property,
                options,
                config
            );
        }

        function getOrderNumberFilter() {
            const name = "orderNumber";
            const translationLabel = `${filterTranslationPrefix}.number`;
            const property = "orderReferenceId";
            const options = [];
            const config = { searchOnly: true };

            return new Data.Filters.Filter(
                name,
                translationLabel,
                property,
                options,
                config
            );
        }

        function getInvoiceNumberFilter() {
            const name = "invoiceNumber";
            const translationLabel = `${filterTranslationPrefix}.invoiceNumber`;
            const property = "magento2OrderIncrementId";
            const options = [];
            const config = { searchOnly: true };

            return new Data.Filters.Filter(
                name,
                translationLabel,
                property,
                options,
                config
            );
        }

        /**
         * Returns the mass actions for the orders page
         *
         * @returns {MassAction[]} The generated mass actions
         */
        function getMassActions() {
            const massActions = [];

            massActions.push(
                new Data.Selection.MassAction({
                    translateKey: "orders.actions.printTickets",
                    executeFunction: printLabels,
                    iconClass: "fa fa-print",
                })
            );

            return massActions;
        }

        function printLabels(orders) {
            const orderReferenceIdSourceObjects = orders.map((order) => {
                return {
                    orderId: order.orderReferenceId,
                    source: order.orderId.source
                }
            });
            printLabelsByOrderReferenceIdSourceObjects(orderReferenceIdSourceObjects);
        }

        function printLabelsByOrderReferenceIdSourceObjects(orderReferenceIdSourceObjects) {
            $scope.downloadLabelQueueOrderReferenceIdSourceObjects = [
                ...$scope.downloadLabelQueueOrderReferenceIdSourceObjects,
                ...orderReferenceIdSourceObjects,
            ];

            api.orders
                .pakbon(orderReferenceIdSourceObjects)
                .then((response) => {
                    const filename =
                        orderReferenceIdSourceObjects.length === 1
                            ? `shipping-label-order-${orderReferenceIdSourceObjects.find(
                                (item) => true
                            ).orderReferenceId}.pdf`
                            : `shipping-label-multiple-orders-${Date.now()}.pdf`;

                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(response.data);
                    link.download = filename;
                    link.click();
                })
                .catch((response) => {
                    $translate("orders.toast.downloadingLabelsFailed").then((message) => {
                        toastr.warning(message, null, {
                            timeOut: 5000,
                            extendedTimeOut: 1000,
                        });
                    });
                    response.data.text().then(jsonText => {
                        console.error("Error while downloading shipping label(s)", JSON.parse(jsonText));
                    })
                })
                .finally(() => {
                    $scope.downloadLabelQueueOrderReferenceIdSourceObjects =
                        $scope.downloadLabelQueueOrderReferenceIdSourceObjects.filter(
                            (orderReferenceIdSourceObject) =>
                                !orderReferenceIdSourceObjects.includes(orderReferenceIdSourceObject)
                        );
                });
        }

        $scope.$on("$destroy", () =>
            $scope.requestCanceler ? $scope.requestCanceler.resolve() : false
        );

        loadOrdersForTab(Tab.active);
    }
);
