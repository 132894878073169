import dock from '../../modules/dock'
import { dutch } from '../../shared/constants/language'
import '../../factories/api'
import { Product } from '../../shared/classes/Product'

const controllerName = 'SalesBoosterCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    $uibModalInstance,
    actionList,
    changeTracker,
    moment,
    api,
    $q,
) {
    const products = actionList

    $scope.STATE = {
        IDLE: 'idle',
        PROCESSING: 'processing',
        PROCESSED: 'processed',
        ERROR: 'error',
    }

    $scope.sale = {
        start: moment(new Date()).add(1, 'days'),
        end: moment(new Date()).add(2, 'days'),
        percentage: 30,
    }

    $scope.progress = {
        processed: 0,
        total: products.length,
    }

    $scope.state = $scope.STATE.IDLE
    $scope.dutch = dutch
    $scope.products = products
    $scope.failedProducts = {
        length: 0,
        list: [],
    }

    const setSale = (productToModify, sale) => api.products
        .updateProduct({
            ...productToModify.getRequestProduct(),
            ...sale,
        })

    const setSales = async (productsToModify, sale) => {
        const hadError = []

        for (const product of productsToModify) {
            try {
                await setSale(product, sale)
            } catch (error) {
                hadError.push(product)
            }

            $scope.progress.processed += 1
        }

        if (hadError.length > 0) {
            throw hadError
        }
    }

    $scope.activate = (productsToModify, sale) => {
        $scope.state = $scope.STATE.PROCESSING
        $scope.progress.processed = 0

        const saleProperties = {
            saleKortingVan: sale.start.format('YYYY-MM-DD'),
            saleKortingTot: sale.end.format('YYYY-MM-DD'),
            saleKortingPercentage: sale.percentage,
        }

        $q.all([setSales(productsToModify, saleProperties)])
            .then(() => {
                $scope.state = $scope.STATE.PROCESSED
            })
            .catch((error) => {
                $scope.state = $scope.STATE.ERROR
                $scope.failedProducts = {
                    length: error.length,
                    list: error.map(product => new Product(product)),
                }
            })
            .finally(() => {
                changeTracker.markChanged()
            })
    }
})
