import dock from '../../../modules/dock'
import template from './error.html'

dock.directive('productSectionError', function () {
    return {
        restrict: 'A',
        scope: false,
        template,
    }
})
