import dock from '../../modules/dock'

dock.directive('uniqueEansPerProduct', function ($parse) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            const productAttribute = $parse(attributes.uniqueEansPerProduct)

            /**
             * @param {string} ean
             * @param {Product} product
             * @returns {boolean}
             */
            function uniqueEansPerProduct(ean, product) {
                const productEans = []

                if (product.ean) {
                    productEans.push(product.ean)
                }

                if (Array.isArray(product.variaties)) {
                    /** @param {ProductVariant} size */
                    product.variaties.forEach((variant) => {
                        if (variant.ean) {
                            productEans.push(variant.ean)
                        }
                    })
                }

                return productEans.filter((productEan) => productEan === ean).length <= 1
            }

            ngModel.$validators.uniqueEansPerProduct = function (modelValue) {
                return !ngModel.$dirty
                    || (!modelValue
                        || !productAttribute(scope)
                        || uniqueEansPerProduct(modelValue, productAttribute(scope))
                    )
            }
        },
    }
})
