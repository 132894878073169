import { Observable } from 'rxjs'
import partition from 'lodash/partition'
import BatchLoader from './BatchLoader'

export default class CachedBatchLoader {
    constructor(batchRequest, getKeyMethod, cache, { batchSize = 250 } = {}) {
        const observable = new Observable((subscriber) => { this.subscriber = subscriber })
        this.subscribe = (subscriber) => observable.subscribe(subscriber)
        this.getKeyMethod = getKeyMethod
        this.cache = cache
        this.batchSize = batchSize
        this.items = new Map()
        this.batchLoader = new BatchLoader(batchRequest, { batchSize })
        this.batchLoader.subscribe((item) => this._itemLoaded(item), (item) => this._itemFailed(item))
        this.activeRequest = null
    }

    async add(items) {
        const nonLoaded = await this._filterForQueue(items)
        this.batchLoader.add(nonLoaded)
    }

    async addPrioritized(items) {
        const nonLoaded = await this._filterForQueue(items)
        this.batchLoader.addPrioritized(nonLoaded)
    }

    async removeCache(items) {
        this.batchLoader.remove(items)
        items.forEach((item) => this.items.delete(this.getKeyMethod(item)))

        return Promise.all(
            items
                .map((item) => this.getKeyMethod(item))
                .map((key) => this.cache.removeItem(key)),
        )
    }

    isLoading() {
        return this.activeRequest !== null || this.batchLoader.isLoading()
    }

    getProgress() {
        const loadedItems = Array
            .from(this.items)
            .map(([, loaded]) => loaded)
            .filter((loaded) => loaded === true)

        return loadedItems.length / this.items.size
    }

    itemRegistered(item) {
        return this.items.has(this.getKeyMethod(item))
    }

    async _filterForQueue(items) {
        const newItems = items.filter((item) => !this.itemRegistered(item))
        const batchLoaderItems = items.filter((item) => this.batchLoader.itemRegistered(item))
        this._setLoaded(newItems, false)
        const { cached, nonCached } = await this._loadFromCache(newItems)
        const existingOrNonCached = nonCached.concat(batchLoaderItems)
        this._setLoaded(cached)
        this._setLoaded(nonCached, false)
        return existingOrNonCached
    }

    async _loadFromCache(items) {
        const cacheRequests = items
            .map((item) => [this.getKeyMethod(item), item])
            .map(([key, item]) => this.cache.getItem(key).then((cache) => [item, cache]))

        this.activeRequest = Promise.all(cacheRequests)
        const itemCacheMapping = await this.activeRequest
        this.activeRequest = null

        const [nonCachedMap, cachedMap] = partition(itemCacheMapping, ([, cache]) => cache === null)
        const nonCached = nonCachedMap.map(([item]) => item)
        const cached = cachedMap.map(([item]) => item)

        cachedMap.forEach(([, cache]) => this.subscriber.next(cache))
        return { cached, nonCached }
    }

    _setLoaded(items, loaded = true) {
        items.forEach((item) => this.items.set(this.getKeyMethod(item), loaded))
    }

    _itemLoaded(item) {
        this._setLoaded([item])
        this.subscriber.next(item)
        this.cache.setItem(this.getKeyMethod(item), item)
    }

    _itemFailed(item) {
        this._setLoaded([item])
        this.subscriber.error(item)
    }
}
