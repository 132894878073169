import dock from '../modules/dock'

const adminStorageKey = '_admin'

dock.service('Admin', function () {
    /**
     * Checks if the user is an admin
     *
     * @return {Boolean} Returns true if the user is an admin, false otherwise
     */
    this.isAdmin = () => !!JSON.parse(localStorage.getItem(adminStorageKey))

    this.toggleAdmin = () => {
        localStorage.setItem(adminStorageKey, (!this.isAdmin()).toString())
    }
})
