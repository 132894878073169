import dock from '../modules/dock'
import intercomAppId from '../shared/constants/intercomAppId'

dock.service('Intercom', /* @ngInject */ function ($rootScope, Retailer, Language) {
    return {
        boot() {
            if (!Language.get()) {
                throw new Error('User language is not defined')
            }

            const intercomSettings = {
                app_id: intercomAppId,
                email: Retailer.info.email,
                user_id: Retailer.info.code,
                user_hash: Retailer.info.intercomHash,
                name: Retailer.info.name,
                language_override: Language.get().split('-')[0],
            }

            window.Intercom?.('boot', intercomSettings)
        },
        shutdown() {
            window.Intercom?.('shutdown')
        },
        update() {
            window.Intercom?.('update')
        },
    }
})
