import angular from 'angular'
import dock from '../../modules/dock'
import selectGridTemplate from './selectGrid.html'
import './selectGrid.scss'

/* eslint-disable */
dock.directive('selectGrid', function() {
    return {
        restrict: 'E',
        scope: {
            items: '<',
            selected: '=',
            disabled: '<?',
            otherSizesPerDimension: '<?',
        },
        template: selectGridTemplate,
        controller: /* @ngInject */ function ($scope, $element, $timeout, Selection) {
            $scope.disabled = $scope.disabled ?? []
            $scope.otherSizesPerDimension = $scope.otherSizesPerDimension ?? []
            const visibleClass = 'visible'

            const selectionControllerConfig = {
                identifier: 'maat',
            }

            const selectionController = ($scope.controller = new Selection.Controller(
                selectionControllerConfig,
            ))

            updateItems($scope.items, $scope.otherSizesPerDimension)
            selectionController.select($scope.selected)

            $scope.$watch(
                'items',
                items => updateItems(items, $scope.otherSizesPerDimension)
            )

            $scope.$watch(
                'otherSizesPerDimension',
                otherSizesPerDimension => updateItems($scope.items, otherSizesPerDimension)
            )

            $scope.$watch(
                () => selectionController.selected,
                () => { $scope.selected = selectionController.selected },
                true,
            )

            function updateItems(items, otherSizesPerDimension) {
                const allSizes = [...items]
                otherSizesPerDimension.map(sizesPerDimension => {
                    allSizes.push(...sizesPerDimension.sizes)
                })

                selectionController.setSelectable(allSizes)
                _removeWidth(_getElements())
                $timeout(addEventListeners)
                $timeout(makeItemsEqualWidth)

                if (allSizes.length === 1) {
                    selectionController.select(allSizes)
                }
            }

            function addEventListeners() {
                _getElements().forEach(_registerKeyUpEventListener)
            }

            function makeItemsEqualWidth() {
                const elements = _getElements()

                if (elements.length === 0) {
                    return
                }
                const largestWidthElement = _getLargestWidthElement(elements)
                const largestWidth = largestWidthElement.offsetWidth
                _setWidth(elements, largestWidth)
            }

            function _getElements() {
                return $element.find('.select-grid-item').toArray()
            }

            function _removeWidth(elements) {
                $element.removeClass(visibleClass)
                elements.forEach((element) => { element.style.width = null })
            }

            function _setWidth(elements, width) {
                elements.forEach((element) => { element.style.width = `${width}px` })
                $timeout(() => $element.addClass(visibleClass))
            }

            function _getLargestWidthElement(elements) {
                return elements.reduce((largestElement, currentElement) => {
                    if (
                        !angular.isElement(largestElement) ||
                        currentElement.offsetWidth > largestElement.offsetWidth
                    ) {
                        return currentElement
                    }

                    return largestElement
                })
            }

            function _registerKeyUpEventListener(element) {
                element.addEventListener('keydown', event => {
                    const enterKeyCode = 13
                    const spaceKeyCode = 32
                    const leftArrowKeyCode = 37
                    const rightArrowKeyCode = 39

                    switch (event.keyCode) {
                        case enterKeyCode:
                        case spaceKeyCode:
                            _simulateClick(element)
                            break
                        case leftArrowKeyCode:
                            _selectPrevious(element)
                            break
                        case rightArrowKeyCode:
                            _selectNext(element)
                            break
                    }
                })
            }

            function _simulateClick(element) {
                angular.element(element).click()
            }

            function _selectPrevious(element) {
                angular
                    .element(element)
                    .prev()
                    .focus()
            }

            function _selectNext(element) {
                angular
                    .element(element)
                    .next()
                    .focus()
            }
        },
    }
})
