import dock from '../modules/dock'
import { RetailerStatus } from '../services/Retailer'

const httpInterceptorName = 'httpInterceptor'
export default httpInterceptorName

dock.factory(httpInterceptorName, /* @ngInject */ function ($q, $rootScope) {
    return {
        request(config) {
            if (
                config.url.indexOf('wsnl-rest') !== -1 &&
                config.method === 'GET'
            ) {
                // add a cachebuster for REST calls based on a GET
                if (config.url.indexOf('?') === -1) {
                    config.url += '?'
                } else {
                    config.url += '&'
                }
                config.url = `${config.url}ts=${new Date().getTime()}`
            }
            return config || $q.when(config)
        },
        responseError(rejection) {
            // do something on error
            const { status } = rejection
            switch (status) {
            case 401: // Unauthorized
                if ($rootScope.retailer) {
                    $rootScope.retailer.status =
                            RetailerStatus.NOT_LOGGED_IN
                }

                break
            case 403: // forbidden
                if ($rootScope.retailer) {
                    $rootScope.retailer.logout()
                }

                break
            case 503: // Maintenance
                document.location.href = '/error/503/'
                break
            default:
                $q.reject(rejection)
            }
            return $q.reject(rejection)
        },
    }
})
