import dock from '../../modules/dock'
import './uploadStep.scss'

dock.directive('uploadStep', function () {
    return {
        restrict: 'A',
        scope: false,
        link(scope, element) {
            const stepClasses = [
                'upload-drop-step',
                'dock-layout',
                'dock-layout-columns',
                'dock-layout-center-horizontal',
                'dock-layout-center-vertical',
            ]

            element.addClass(stepClasses.join(' '))
        },
    }
})
