import MetaInfoField from './MetaInfoField'
import CombinedCategory from '../../../shared/classes/CombinedCategory'
import registry from '../registry'

const BLACKLISTED_FIELD = 'category.blacklist'

/* eslint-disable */
/**
 * A meta info handler for the category field. Adds useful functionality
 */
export class CategoryHandler extends MetaInfoField {
    constructor(metaInfoField) {
        super(metaInfoField)
        this.blacklisted = []
    }

    /**
     * Gets the blacklisted values from a different meta info field
     */
    initialize(metaInfo) {
        if (metaInfo.fieldExists(BLACKLISTED_FIELD)) {
            this.blacklisted = metaInfo
                .field(BLACKLISTED_FIELD)
                .getValues()
                .map(({ code }) => code)
        }
    }

    /**
     * Only returns non-blacklisted values
     */
    getValues() {
        return this.values
            .filter(({ code }) => !this.blacklisted.includes(code))
            .slice()
    }

    /**
     * Gets the categories for a specific main category
     *
     * @param {String} [mainCategory] The main category or category code to select categories from
     * @return {String[]} The categories for the specific main category. If no main category was given it returns all
     * categories
     */
    getCategories(mainCategory) {
        if (!mainCategory) {
            return []
        }

        return this.getValues().filter(category =>
            category.code.startsWith(`${mainCategory}_`),
        )
    }
}

export class MainCategoryHandler extends MetaInfoField {
    initialize(metaInfo) {
        this._categoryHandler = metaInfo.field(categoryField)
        this._subCategoryHandler = metaInfo.field(subCategoryField)
    }

    getCombined() {
        if (!this.combinedCategories) {
            this._generateCombinedCategories()
        }

        return this.combinedCategories
    }

    _generateCombinedCategories() {
        this.combinedCategories = []

        this.getValues().forEach(mainCategory => {
            this._categoryHandler
                .getCategories(mainCategory.code)
                .forEach(category => {
                    const subCategories = this._subCategoryHandler.getCategories(
                        category.code,
                    )

                    if (subCategories.length === 0) {
                        subCategories.push({ code: "", naam: "" })
                    }

                    subCategories.forEach(subCategory => {
                        const combined = new CombinedCategory(
                            mainCategory,
                            category,
                            subCategory,
                        ).generateTitleAndCode()
                        this.combinedCategories.push(combined)
                    })
                })
        })
    }
}
export const mainCategoryField = registry.registerHandler(
    'mainCategory',
    MainCategoryHandler,
)
export const categoryField = registry.registerHandler(
    'category',
    CategoryHandler,
)
export const subCategoryField = registry.registerHandler(
    'subCategory',
    CategoryHandler,
)

registry.registerAlias('hoofdcategoriecode', mainCategoryField)
registry.registerAlias('categoriecode', categoryField)
registry.registerAlias('subcategoriecode', subCategoryField)


registry.registerTranslatable(mainCategoryField)
registry.registerTranslatable(categoryField)
registry.registerTranslatable(subCategoryField)
