import dock from '../../modules/dock'

dock.directive('notInSet', /* @ngInject */ function ($parse) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            const set = $parse(attributes.notInSet)

            ngModel.$validators.notInSet = function (modelValue) {
                return !ngModel.$dirty
                    || (!modelValue
                        || !set(scope)
                        || !set(scope).includes(modelValue)
                    )
            }
        },
    }
})
