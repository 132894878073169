import module from '../../module'
import './photoswipe-trigger.scss'

module.directive('photoswipeTrigger', /* @ngInject */ function (PhotoSwipe) {
    return {
        restrict: 'A',
        scope: {
            options: '=photoswipeTrigger',
            items: '<photoswipeGalleryItems',
            imageSelector: '@photoswipeImageSelector',
        },
        link(scope, element) {
            if (!Number.isInteger(scope.options.index)) {
                scope.options.index = 0
            }

            /**
             * Creates a PhotoSwipe gallery
             *
             * @param {Object[]} items The gallery's photos
             * @param {Object} options The PhotoSwipe configuration
             * @return {Gallery} Returns a PhotoSwipe gallery
             * @private
             */
            function _createGallery(items, options) {
                return new PhotoSwipe.Gallery(items, options)
            }

            /**
             * Registers a function to a PhotoSwipe gallery that tells
             * PhotoSwipe the dimensions of the thumbnail and
             * thus where animations should start from and go towards
             *
             * @param {Gallery} gallery The PhotoSwipe gallery
             * @param {HTMLElement} image The thumbnail image element
             * @private
             */
            function _registerThumbBoundsFunction(gallery, image) {
                gallery.options.getThumbBoundsFn = function () {
                    const imageOffset = image.getBoundingClientRect()

                    return {
                        y: imageOffset.top,
                        x: imageOffset.left,
                        w: imageOffset.width,
                    }
                }
            }

            /**
             * Registers a zoom listener that removes the class that indicates an active gallery
             * @param {Gallery} gallery The PhotoSwipe gallery
             * @private
             */
            function _registerZoomListeners(gallery) {
                gallery.listen('initialZoomOutEnd', () =>
                    element.removeClass('showing-gallery'))
            }

            /**
             * Registers a listener to a change in the selected item and reflects that to the scope
             *
             * @param {Gallery} gallery The PhotoSwipe gallery
             * @private
             */
            function _registerIndexChangeListener(gallery) {
                gallery.listen('afterChange', () => {
                    scope.options.index = gallery.getCurrentIndex()
                    scope.$apply()
                })
            }

            element.on('click', () => {
                // If we don't have items don't open the gallery
                if (!scope.items || scope.items.length === 0) {
                    return
                }

                const imageElement = element.find(scope.imageSelector)[0]

                // Add a class indicating the gallery is open
                element.addClass('showing-gallery')

                const gallery = _createGallery(scope.items, scope.options)
                _registerThumbBoundsFunction(gallery, imageElement)
                _registerZoomListeners(gallery)
                _registerIndexChangeListener(gallery)
                gallery.init()
            })
        },
    }
})
