import dock from '../modules/dock'
import { dutch, supportedLanguages } from '../shared/constants/language'

dock.run(function () {
    // Get language from local storage
    const currentLanguage = localStorage.getItem('language')

    // If the language in the local storage is valid use that one, otherwise, set it to the default language
    if (supportedLanguages.indexOf(currentLanguage) === -1) {
        localStorage.setItem('language', dutch)
    }
})
