import dock from '../../../modules/dock'
import template from './descriptors.html'

dock.directive('productSectionDescriptors', function () {
    return {
        restrict: 'A',
        scope: false,
        template,
    }
})
