/**
 * Trims a path string returned by $location.path(). It replaces all slashes with underscores and
 * removes any trailing slashes.
 *
 * @param {String} path The path returned by $location.path()
 * @return {String} The trimmed path
 */
export default function trimPath(path) {
    // Remove the slash at the start and replace all slashes with underscores
    path = path.slice(1).replace('/', '_');

    // Remove any trailing underscores if they are present
    return path.slice(0, path.length - path.endsWith('_'));
}