import angular from 'angular'
import '../../factories/api'
import dock from '../../modules/dock'

/* eslint-disable */
const controllerName = 'SaleCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    $q,
    $uibModalInstance,
    api,
    actionList,
    changeTracker,
    moment,
) {
    let constants = {
        STATE: {
            LOADING: 0,
            READY: 1,
            LOADING_ERROR: 2,
            NO_OVERLAP_ERROR: 3,
            PROCESSING: 4,
            PROCESSED: 5,
            PROCESS_ERROR: 6,
        },
        ERROR: {
            GENERIC: {
                NO_ERROR: -1,
                RESTRICTED: 1000,
            },
            SALE_START: {
                BEFORE_TODAY: 2000,
            },
            SALE_STOP: {
                BEFORE_START: 3000,
                INVALID_WITH_START: 3001,
            },
        },
    }

    $scope.$watch(
        'sale',
        function() {
            updateValidation()
        },
        true,
    )

    $scope.actionList = actionList
    $scope.startDateOpen = false
    $scope.endDateOpen = false
    $scope.restrictedBrands = []
    $scope.constants = constants
    $scope.state = null
    $scope.requestCanceler = null
    $scope.settings = { setEndDate: false }

    $scope.validation = {
        saleStart: constants.ERROR.GENERIC.NO_ERROR,
        saleStop: constants.ERROR.GENERIC.NO_ERROR,
    }

    $scope.length = {
        value: $scope.actionList.length,
    }

    $scope.percentageSliderOptions = {
        step: 1,
        range: {
            min: 10,
            max: 70,
        },
        pips: {
            mode: 'steps',
            density: 7,
            filter: percentage => {
                if (percentage % 25 === 0 || percentage === 10 || percentage === 70) {
                    return 1;
                }
                if (percentage % 5 === 0) {
                    return 2;
                }
                return 0;
            },
            format: {
                to: input => `${input}%`,
                from: input => parseInt(input.replace('%', ''), 10),
            },
        },
        format: {
            to: input => parseInt(input, 10),
            from: input => input.toString(),
        },
    }

    $scope.sale = {
        percentage: 25,
        start: null,
        stop: null,
    }

    $scope.errorInfo = {
        errors: [],
        products: [],
    }

    $scope.saleStartDatepickerOptions = {
        dateDisabled: function(selection) {
            switch (selection.mode) {
                case 'year':
                    return !isValidSaleStartYear(selection.date)
                case 'month':
                    return !isValidSaleStartMonth(selection.date)
                case 'day':
                    return (
                        getSaleStartError(selection.date) !==
                        constants.ERROR.GENERIC.NO_ERROR
                    )
            }
        },
    }

    $scope.saleStopDatepickerOptions = {
        dateDisabled: function(selection) {
            switch (selection.mode) {
                case 'year':
                    return !isValidSaleStopYear(selection.date)
                case 'month':
                    return !isValidSaleStopMonth(selection.date)
                case 'day':
                    return (
                        getSaleStopError(selection.date) !==
                        constants.ERROR.GENERIC.NO_ERROR
                    )
            }
        },
    }

    loadRestrictedBrands()

    $scope.loadRestrictedBrands = loadRestrictedBrands

    $scope.getRestrictedBrandNames = function() {
        return $scope.restrictedBrands.map(function(brand) {
            return brand.name
        })
    }

    $scope.updateEndDate = function(endDateModel) {
        if (endDateModel.$untouched) {
            $scope.sale.stop = getProposedEndDate(
                moment($scope.sale.start),
                $scope.restrictedBrands,
            ).toDate()
        }
    }

    $scope.close = function() {
        $uibModalInstance.close()
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss()
    }

    $scope.setSale = function(clearSale) {
        $scope.state = constants.STATE.PROCESSING
        $scope.progressPerc = 0

        // synchronise the behaviour so we can progress through the different products.
        progressProduct(0, clearSale, function() {
            if ($scope.errorInfo.errors.length > 0) {
                $scope.state = constants.STATE.PROCESS_ERROR
            } else {
                $scope.state = constants.STATE.PROCESSED
            }
        })
    }

    $scope.correctSale = function() {
        const percentage = $scope.sale.percentage

        return (
            percentage >= 10 &&
            percentage <= 70 &&
            getSaleStartError() === constants.ERROR.GENERIC.NO_ERROR &&
            getSaleStopError() === constants.ERROR.GENERIC.NO_ERROR
        )
    }

    /**
     * Generates a proposal for the start date of the sale that lies within the given restrictions
     *
     * @param {Array} restrictedBrands An array of brand objects with its sale restrictions to test compliance with
     * @returns {Moment} A Moment.js object with the date set to the proposed start date.
     */
    function getProposedStartDate(restrictedBrands) {
        let tomorrow = moment().add(1, 'day')
        let proposedStartDate = moment(tomorrow)

        // Since restrictions are year independent, we only have to check for
        let checkLimit = moment(tomorrow).add(1, 'year')

        // Since restrictions mostly contain monthly periods, this is the closest exponential value of 2 that is less
        // then a month. Which leads to the fastest search with the first possible date from now as most likely result
        let dateStepStart = 16
        let dateStep = dateStepStart
        let multiplier

        while (dateStep >= 1) {
            while (proposedStartDate.isSameOrBefore(checkLimit)) {
                if (
                    isWithinRestrictions(
                        proposedStartDate,
                        proposedStartDate,
                        restrictedBrands,
                    )
                ) {
                    if (!proposedStartDate.isSame(tomorrow)) {
                        // We found an unrestricted date that isn't today. Since it isn't today we know there are
                        // restrictions. We'll now find the first day before the proposed date that isn't within
                        // restrictions
                        let firstRestrictedBeforeProposed = moment(
                            proposedStartDate,
                        ).subtract(1, 'days')

                        while (
                            isWithinRestrictions(
                                firstRestrictedBeforeProposed,
                                firstRestrictedBeforeProposed,
                                restrictedBrands,
                            )
                        ) {
                            firstRestrictedBeforeProposed.subtract(1, 'days')
                        }

                        // The proposed start date is the first restricted date before the original proposed date + 1 day.
                        proposedStartDate = firstRestrictedBeforeProposed.add(
                            1,
                            'days',
                        )
                    }

                    return proposedStartDate
                }

                /* If the dateStep hasn't been reduced, multiplier is 1, otherwise 2 (we step with step * 2 with offset
                 * step).
                 * Example: We start with step 32
                 * First run: 0, 32, 64...      // Multiplier is 1
                 * Second run: 16, 48, 80...    // Multiplier is 2
                 * Third run: 8, 24, 40...      // Multiplier is 2
                 * Fourth run: 4, 12, 20...     // ...
                 * Fifth run: 2, 6, 10...       // ...
                 * Sixth run: 1, 3, 5...        // ...
                 *
                 * This ensures we try every offset within the check limit without duplicates.
                 * */
                multiplier = dateStep === dateStepStart ? 1 : 2
                proposedStartDate.add(dateStep * multiplier, 'days')
            }

            dateStep /= 2
            proposedStartDate = moment(tomorrow).add(dateStep, 'days')
        }

        // If no proposed date was found within a year, then the restrictions leave no valid options, therefore we
        // return an invalid date.
        return moment.invalid()
    }

    /**
     * Generates a valid end date based on a given start date for a sale period. Tries a period of ten years, then one year, then a month,
     * then a week, then a day and otherwise falls back to the same day as the start date.
     *
     * @param {Moment} startDate A Moment.js object with the date set to the start of the sale period
     * @param {Array} restrictedBrands An array of brand objects with its sale restrictions to test compliance with
     * @returns {Moment} A Moment.js object with the date set to the proposed end date.
     */
    function getProposedEndDate(startDate, restrictedBrands) {
        let proposedEndDates = [
            moment(startDate)
                .add(10, 'years')
                .subtract(1, 'day'),
            moment(startDate)
                .add(1, 'year')
                .subtract(1, 'day'),
            moment(startDate)
                .add(1, 'month')
                .subtract(1, 'day'),
            moment(startDate)
                .add(1, 'week')
                .subtract(1, 'day'),
            moment(startDate).add(1, 'day'),
        ]

        for (let index = 0; index < proposedEndDates.length; index++) {
            let proposedEndDate = proposedEndDates[index]

            if (
                isWithinRestrictions(
                    startDate,
                    proposedEndDate,
                    restrictedBrands,
                )
            ) {
                return proposedEndDate
            }
        }

        return startDate
    }

    /**
     * Returns whether a given start date and end date are within all brand restrictions
     *
     * @param {Moment} saleStart A Moment.js object with the date set to the start of the sale period
     * @param {Moment} saleEnd A Moment.js object with the date set to the end of the sale period
     * @param {Array} restrictedBrands An array of brand objects with its sale restrictions to test compliance with
     * @returns {Boolean} Returns true if the saleStart and saleEnd are within the brand restrictions and false
     *                    otherwise. If a restriction start or end is an invalid date, false will be returned
     */
    function isWithinRestrictions(saleStart, saleEnd, restrictedBrands) {
        // We start with saying it is within all brand restrictions that and make a false value persistent through the
        // && operator, so if it fails to comply with one brand, false is returned
        return restrictedBrands.reduce(function(withinRestrictions, brand) {
            // We start with saying it's not within this brand's restrictions since we can safely assume if a brand is
            // added to the restrictedBrand it has restrictions. We make it non-persistent through the || operator, so
            // if it complies with one restriction from this brand, true is returned
            return (
                withinRestrictions &&
                brand.saleRestrictions.reduce(function(
                    validForBrand,
                    restriction,
                ) {
                    return (
                        validForBrand ||
                        isValidWithinRestriction(
                            saleStart,
                            saleEnd,
                            restriction,
                        )
                    )
                },
                false)
            )
        }, true)
    }

    /**
     * @typedef saleRestrictionDate
     * @type {Object}
     * @property {Number} month The month of the date from 0 - 11, 0 being january
     * @property {Number} day The date's day of the month from 1 - 31 (depending on the month it can go up to 31, 30,
     *                        29). Note that for february a date of 29 means the whole of february, and 28 means it will
     *                        exclude the 29th of february in a leap year
     */

    /**
     * @typedef saleRestriction
     * @type {Object}
     * @property {saleRestrictionDate} dateStart The start of the sale restriction period
     * @property {saleRestrictionDate} dateEnd The end of the sale restriction period
     */

    /**
     * Returns whether a given start date and end date are within a given restriction. Discussion about this function
     * can be found in IWSNL-3263 and IWSNL-3613
     *
     * @param {Moment} saleStart A Moment.js object with the date set to the start of the sale period
     * @param {Moment} saleEnd A Moment.js object with the date set to the end of the sale period
     * @param {saleRestriction} restriction The restriction we want to test for
     * @returns {Boolean} Returns true if the saleStart and saleEnd are within the restriction and false otherwise. If
     *                    the restriction start or end is an invalid date, false will be returned
     */
    function isValidWithinRestriction(saleStart, saleEnd, restriction) {
        // 2016 was a leap year, therefore this date won't be invalid if the restriction is a leap day
        let restrictionStart = moment()
            .year(2016)
            .month(restriction.dateStart.month)
            .date(restriction.dateStart.day)
        let restrictionEnd = moment()
            .year(2016)
            .month(restriction.dateEnd.month)
            .date(restriction.dateEnd.day)

        let restrictionStartIsLeapDay = isLeapDay(restrictionStart)
        let restrictionEndIsLeapDay = isLeapDay(restrictionEnd)
        let saleStartIsLeapDay = isLeapDay(saleStart)
        let saleEndIsLeapDay = isLeapDay(saleEnd)

        if (
            saleStartIsLeapDay &&
            saleEndIsLeapDay &&
            restrictionStartIsLeapDay &&
            restrictionEndIsLeapDay
        ) {
            return true
        }

        let restrictionEndYear = saleStart.year()

        // If the restriction end is before the restrictionStart, then we're dealing with a restriction that leaps a
        // year and we need to account for this.
        if (restrictionEnd.isBefore(restrictionStart)) {
            // The end year of the restriction can be at max 1 year after the sale start, because of year-leaping
            // restrictions. Look at IWSNL-3613 for more details
            restrictionEndYear = saleStart.year() + 1
        }

        // These have to be objects, since using month() or date() will always result in valid dates
        let restrictionStartDate = moment({
            year: saleStart.year(),
            month: restrictionStart.month(),
            date: restrictionStart.date(),
        }).startOf('day')

        let restrictionEndDate = moment({
            year: restrictionEndYear,
            month: restrictionEnd.month(),
            date: restrictionEnd.date(),
        }).endOf('day')

        // If the date is invalid because it’s a leap day, set it to the first of march
        if (!restrictionStartDate.isValid()) {
            if (!restrictionStartIsLeapDay) {
                // If it’s not a leap day, the restriction date is an invalid date
                return false
            }

            restrictionStartDate = moment()
                .year(saleStart.year())
                .month(1)
                .date(28)
        }

        // If the date is invalid because it’s a leap day, set it to the end of february
        if (!restrictionEndDate.isValid()) {
            if (!restrictionEndIsLeapDay) {
                // If it’s not a leap day, the restriction date is an invalid date
                return false
            }

            restrictionEndDate = moment()
                .year(restrictionEndYear)
                .month(1)
                .date(28)
        }

        return (
            saleStart.isBetween(
                restrictionStartDate,
                restrictionEndDate,
                null,
                '[]',
            ) &&
            saleEnd.isBetween(
                restrictionStartDate,
                restrictionEndDate,
                null,
                '[]',
            )
        )
    }

    /**
     * Checks whether a given date is a leap day (i.e. the 29th of february)
     *
     * @param {Moment} date A Moment.js object that needs to be checked for being a leap day
     * @returns {Boolean} Returns true if the date is a leap day and false otherwise
     */
    function isLeapDay(date) {
        return date.month() === 1 && date.date() === 29
    }

    /**
     * Returns the brand objects for the brands of the products that have sale restrictions
     *
     * @param {Array} products The products that a sale wants to be set for
     * @param {Array} brands An array of all brands retrieved from the back-end. Can include brands that have no
     *                       sale restrictions
     * @returns {Array} Returns an array of brands that have sale restrictions and were the brand of one of the given
     *                  {@link products}
     */
    function getRestrictedBrands(products, brands) {
        let restrictedBrands = []

        products.forEach(function(product) {
            let brandInfo = brands.filter(function(brand) {
                return brand.code === product.merk.code
            })[0]

            if (
                brandInfo.saleRestrictions.length > 0 &&
                restrictedBrands.indexOf(brandInfo) === -1
            ) {
                restrictedBrands = restrictedBrands.concat(brandInfo)
            }
        })

        restrictedBrands.forEach(function(brand) {
            brand.saleRestrictions.forEach(function(restriction) {
                let dates = [restriction.dateStart, restriction.dateEnd].map(
                    function(date) {
                        let split = date.split('-')
                        let day = parseInt(split[0])
                        let month = parseInt(split[1]) - 1

                        return {
                            day: day,
                            month: month,
                        }
                    },
                )

                restriction.dateStart = dates[0]
                restriction.dateEnd = dates[1]
            })

            // We're going to look for a restriction that ends on the 31st of december and another restriction that
            // starts on the 1st of january, since we have to combine them to a new restriction, to make sale periods
            // that leap over a year valid. Look at IWSNL-3613 for more details
            let decemberEndIndex = brand.saleRestrictions.reduce(function(
                decemberEndIndex,
                restriction,
                index,
            ) {
                if (
                    restriction.dateEnd.month === 11 &&
                    restriction.dateEnd.day === 31
                ) {
                    return index
                }

                return decemberEndIndex === -1 ? -1 : decemberEndIndex
            },
            -1)

            let januaryStartIndex = brand.saleRestrictions.reduce(function(
                januaryStartIndex,
                restriction,
                index,
            ) {
                if (
                    restriction.dateStart.month === 0 &&
                    restriction.dateStart.day === 1
                ) {
                    return index
                }

                return januaryStartIndex === -1 ? -1 : januaryStartIndex
            },
            -1)

            if (decemberEndIndex > -1 && januaryStartIndex > -1) {
                brand.saleRestrictions.push({
                    dateStart: angular.copy(
                        brand.saleRestrictions[decemberEndIndex].dateStart,
                    ),
                    dateEnd: angular.copy(
                        brand.saleRestrictions[januaryStartIndex].dateEnd,
                    ),
                })
            }
        })

        return restrictedBrands
    }

    /**
     * Queries the api for all brand information and filters restricted and listed brands
     */
    function loadRestrictedBrands() {
        $scope.requestCanceler = $q.defer()
        $scope.state = constants.STATE.LOADING

        api.brands
            .get($scope.requestCanceler)
            .then(function(response) {
                $scope.state = constants.STATE.READY
                $scope.brands = response.data.brands
                $scope.restrictedBrands = getRestrictedBrands(
                    $scope.actionList,
                    $scope.brands,
                )

                let proposedStartDate = getProposedStartDate(
                    $scope.restrictedBrands,
                )


                if (proposedStartDate.isValid()) {

                    let proposedEndDate = getProposedEndDate(
                        proposedStartDate,
                        $scope.restrictedBrands,
                    )

                    let nineYears = moment().add(9, 'years')

                    $scope.sale.start = proposedStartDate.toDate()
                    $scope.sale.stop = proposedEndDate.toDate()

                    // If the proposed end date is at least 9 years away, collapse the end date field
                    // since we're probably dealing with sales restricted brands
                    $scope.setEndDate = proposedEndDate.isBetween(proposedStartDate, nineYears)
                } else {
                    // An invalid proposed start date means the brand restrictions have no overlap
                    $scope.state = constants.STATE.NO_OVERLAP_ERROR
                }
            })
            .catch(function(response) {
                $scope.state = constants.STATE.LOADING_ERROR
                $scope.error = response.data
            })
    }

    /**
     * Updates the scope variables that control validation
     */
    function updateValidation() {
        $scope.validation.saleStart = getSaleStartError()
        $scope.validation.saleStop = getSaleStopError()
    }

    /**
     * Returns the error code for the sale start date
     *
     * @param {Date} [saleStart=$scope.sale.start] The start date to check for errors
     * @returns {Number} Error code constant corresponding to the error
     */
    function getSaleStartError(saleStart) {
        let startDate = moment(saleStart || $scope.sale.start).startOf('day')
        let today = moment().startOf('day')

        if (startDate.isSameOrBefore(today)) {
            return constants.ERROR.SALE_START.BEFORE_TODAY
        }

        if (
            !isWithinRestrictions(startDate, startDate, $scope.restrictedBrands)
        ) {
            return constants.ERROR.GENERIC.RESTRICTED
        }

        return constants.ERROR.GENERIC.NO_ERROR
    }

    /**
     * Returns the error code for the sale stop date
     *
     * @returns {Number} Error code constant corresponding to the error
     */
    function getSaleStopError(saleStop) {
        let startDate = moment($scope.sale.start)
        let stopDate = moment(saleStop || $scope.sale.stop)

        if (stopDate.isBefore(startDate)) {
            return constants.ERROR.SALE_STOP.BEFORE_START
        }

        if (
            !isWithinRestrictions(stopDate, stopDate, $scope.restrictedBrands)
        ) {
            return constants.ERROR.GENERIC.RESTRICTED
        }

        if (
            !isWithinRestrictions(startDate, stopDate, $scope.restrictedBrands)
        ) {
            return constants.ERROR.SALE_STOP.INVALID_WITH_START
        }

        return constants.ERROR.GENERIC.NO_ERROR
    }

    /**
     * Checks whether the given sale start date is in a valid year
     *
     * @param {Date} saleStart A date with the year set to the year to check
     * @returns {Boolean} Returns true if the year is valid, false otherwise
     */
    function isValidSaleStartYear(saleStart) {
        let saleStartYear = moment(saleStart).startOf('year')
        let thisYear = moment().startOf('year')
        let restrictedBrands = $scope.restrictedBrands

        if (saleStartYear.isAfter(thisYear)) {
            return true
        }

        if (saleStartYear.isBefore(thisYear)) {
            return false
        }

        if (restrictedBrands.length === 0) {
            return true
        } else {
            return thisYearHasRestrictionsLeft(restrictedBrands)
        }
    }

    /**
     * Checks whether the current year has unrestricted dates left
     *
     * @param {Array} restrictedBrands The brands that have restrictions
     * @returns {Boolean} Returns true if this year has unrestricted dates left, false otherwise
     */
    function thisYearHasRestrictionsLeft(restrictedBrands) {
        return restrictedBrands.reduce(function(
            hasBrandValidAfterToday,
            brand,
        ) {
            return (
                hasBrandValidAfterToday ||
                brand.saleRestrictions.reduce(function(
                    hasRestrictionValidAfterToday,
                    restriction,
                ) {
                    let today = moment().startOf('day')
                    let end = restriction.dateEnd

                    return (
                        hasRestrictionValidAfterToday ||
                        today.month() < end.month ||
                        (today.month() === end.month && today.date() <= end.day)
                    )
                },
                false)
            )
        },
        false)
    }

    /**
     * Check whether the given sale stop date is in a valid year
     * @param {Date} saleStop A date with the year set to the year to check
     * @returns {Boolean} Returns true if the sale stop date is in a valid year, false otherwise
     */
    function isValidSaleStopYear(saleStop) {
        let saleStart = moment($scope.sale.start)
        let startYear = moment(saleStart).year()
        let stopYear = moment(saleStop).year()
        let firstOfStopYear = moment(saleStop).startOf('year')

        return (
            stopYear === startYear ||
            (stopYear > startYear && $scope.restrictedBrands.length === 0) ||
            (stopYear === startYear + 1 &&
                isWithinRestrictions(
                    saleStart,
                    firstOfStopYear,
                    $scope.restrictedBrands,
                ))
        )
    }

    /**
     * Checks whether the given sale start date is in a valid month. Does this in a very efficient way by checking first
     * the start of the month, then the end and then all values exactly in between the values that already have been
     * checked.
     *
     * @example
     * First checks: 0, 31
     * Then: 16
     * Then: 8, 23
     * Then: 4, 12, 19, 27
     * Then: 2, 6, 10, 14, 17, 21, 25, 29
     * Then: 3, 5, 7, 9, 11, 13, 15, 18, 20, 22, 24, 26, 28, 30
     *
     * @param {Date} saleStart A date with the month set to the month to check
     * @returns {Boolean} Returns true if the sale start date is in a valid month, false otherwise
     */
    function isValidSaleStartMonth(saleStart) {
        // If the year doesn't have any valid months, this month is also invalid
        if (!isValidSaleStartYear(saleStart)) {
            return false
        }

        let saleStartMonth = moment(saleStart).startOf('month')
        let thisMonth = moment().startOf('month')

        // If the sale start month is before the current month, it's invalid
        if (saleStartMonth.isBefore(thisMonth)) {
            return false
        }

        // If we don't have any restrictions, it's valid
        if ($scope.restrictedBrands.length === 0) {
            return true
        }

        let checkDate = moment(saleStart)
        let extremes = [1, moment(saleStart).daysInMonth()] // We start by checking the begin and end of the month
        let toCheck = extremes.slice()

        while (true) {
            // We loop through all values we need to check
            for (let index = 0; index < toCheck.length; index++) {
                // Set the date of the month to the number we need to check
                checkDate.date(toCheck[index])

                // If we don't get an error we found a valid day, otherwise we check another one
                if (
                    getSaleStartError(checkDate) ===
                    constants.ERROR.GENERIC.NO_ERROR
                ) {
                    return true
                }
            }

            // Okay, we've checked all values that we needed to check, let's see if there are others within the month
            // we need to check
            toCheck = findNewCheckValues(extremes)

            // If there are no more values to check and we haven't returned true by now, there's no possible date within
            // this month.
            if (toCheck.length === 0) {
                return false
            }
        }
    }

    /**
     * Checks whether the given sale stop date is in a valid month
     *
     * @param {Date} saleStop A date with the month set to the month to check
     * @returns {Boolean} Returns true if the sale stop date is in a valid month, false otherwise
     */
    function isValidSaleStopMonth(saleStop) {
        // If we're not even in a valid year, forget it.
        if (!isValidSaleStopYear(saleStop)) {
            return false
        }

        let saleStart = moment($scope.sale.start)
        let saleStopMoment = moment(saleStop)
        let saleStopMonthEnd = moment(saleStopMoment).endOf('month')
        let saleStopMonthStart = moment(saleStopMoment).startOf('month')

        // If the end of the stop month is before the sale start, we're before the sale start, which makes the month
        // invalid.
        if (saleStopMonthEnd.isBefore(saleStart)) {
            return false
        }

        // Because we don't want unnecessary computation, first, check if the year and the month are the same, if that's the case
        // we can return true, because we can always choose the same day.
        if (
            saleStopMoment.year() === saleStart.year() &&
            saleStopMoment.month() === saleStart.month()
        ) {
            return true
        }

        // If we don't have any restrictions, it's valid
        if ($scope.restrictedBrands.length === 0) {
            return true
        }

        // The sale stop month is after the sale start. Check if the range from the sale start to the start of the sale
        // stop month is valid.
        return isWithinRestrictions(
            saleStart,
            saleStopMonthStart,
            $scope.restrictedBrands,
        )
    }

    /**
     * Finds values that lie within the list of extremes. Does this by first returning the values that lie exactly
     * between the extremes, and otherwise finds all extremes + 1 values if they are not an extreme. It then adds the
     * new values that can be checked to the extremes, since they can be used for the next round.
     *
     * @example
     * Extremes   | Returns   | Modified extremes |
     * [0, 31]      [16]        [0, 16, 31]
     * [1, 3]       [2]         [1, 2, 3]
     * [1, 2]       []          [1, 2]
     * [1, 2, 4]    [3]         [1, 2, 3, 4]
     *
     * @param {Number[]} extremes A list of extremes to find values between
     * @modifies extremes
     * @returns {Number[]} The new values that can be checked
     */
    function findNewCheckValues(extremes) {
        let newValues = []
        let newExtremes = extremes.slice()
        let newExtremesOffset = 0
        let newValue

        // We need at least 2 extremes
        if (extremes.length < 2) {
            return newValues
        }

        for (let index = 0; index < extremes.length - 1; index++) {
            // If there is a value or there are values between the extremes, we find one of those values by taking the
            // mean of the all sequential extremes, flooring them and adding them as new values to the extremes and new
            // values
            if (extremes[index] + 1 !== extremes[index + 1]) {
                newValue =
                    extremes[index] +
                    Math.floor((extremes[index + 1] - extremes[index]) / 2)
                newValues.push(newValue)
                newExtremes.splice(index + newExtremesOffset + 1, 0, newValue)

                // Since we're always going forward we can keep track of an offset if we insert an item. This way we
                // can find where a value in extremes is in the newExtremes if we're not trying to find an item that has
                // an index lower than index
                newExtremesOffset++
            }
        }

        extremes.splice(0, extremes.length)
        newExtremes.forEach(function(newExtreme) {
            extremes.push(newExtreme)
        })

        return newValues
    }

    let progressProduct = function(i, clearSale, callback) {
        if ($scope.actionList[i]) {
            let product = angular.copy($scope.actionList[i]).getRequestProduct()

            delete product.isCollapsed
            delete product.checked

            if (clearSale) {
                product.saleKortingVan = ''
                product.saleKortingTot = ''
                product.saleKortingPercentage = ''
            } else {
                product.saleKortingVan = moment($scope.sale.start).format(
                    'YYYY-MM-DD',
                )
                product.saleKortingTot = moment($scope.sale.stop).format(
                    'YYYY-MM-DD',
                )
                product.saleKortingPercentage = $scope.sale.percentage
            }
            $scope.progress = {
                product: product,
                index: i,
            }
            $scope.progressPerc = Math.floor(
                (i / $scope.actionList.length) * 100,
            )

            api.products
                .updateProduct(product)
                .catch(function(error) {
                    $scope.errorInfo.errors.push(error)
                    $scope.errorInfo.products.push(product)
                })
                .finally(function() {
                    let j = Number(i) + 1
                    changeTracker.markChanged()
                    progressProduct(j, clearSale, callback)
                })
        } else {
            callback()
        }
    }

    $scope.$on('$destroy', () => $scope.requestCanceler ? $scope.requestCanceler.resolve() : false)
})
