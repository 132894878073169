import dock from '../../modules/dock'

const _digitEanLengthRegex = /^(\d{8}|\d{12,14})$/

dock.directive('validateEan', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            function _eanValid(ean) {
                if (!ean) {
                    return true
                }

                if (!_digitEanLengthRegex.test(ean)) {
                    return false
                }

                const controlDigit = parseInt(ean.charAt(ean.length - 1), 10)
                const eanRemainder = ean.slice(0, -1)

                let total = 0

                for (let i = 0; i < eanRemainder.length; i += 1) {
                    const digit = parseInt(
                        eanRemainder.charAt(eanRemainder.length - i - 1),
                        10,
                    )

                    if (i % 2 === 1) {
                        total += digit
                    } else {
                        total += 3 * digit
                    }
                }

                const calculatedControlDigit = (10 - (total % 10)) % 10

                return controlDigit === calculatedControlDigit
            }

            ngModel.$validators.ean = function (modelValue) {
                return !ngModel.$dirty || _eanValid(modelValue)
            }
        },
    }
})
