import dock from '../../modules/dock'
import sortableTemplate from './sortable.html'
import './sortable.scss'

dock.directive('sortable', function () {
    return {
        restrict: 'A',
        transclude: true,
        scope: {
            sortItem: '=',
            sortController: '=',
        },
        template: sortableTemplate,
    }
})
