import angular from 'angular'
import dock from '../modules/dock'
import trimPath from '../shared/functions/trimPath'

dock.service('Filters', /* @ngInject */ function ($filter, $location) {
    function trueFunction() {
        return true
    }

    function returnFunction(value) {
        return value
    }

    /**
     * A controller that can be used to keep track of filter properties and to filter data through a
     * set of filters.
     *
     * @class
     * @param {Filter[]} [filters=[]] A set of filters to use for filtering
     * @param {ControllerConfig} [config] The filter controller configuration. Options described in
     * the typedef below
     * @returns {FilterController} Returns this to allow for chaining
     * @constructor
     */
    function FilterController(filters, config) {
        this.filters = []
        this.data = null
        this.dataFiltered = null

        const self = this
        const onSelectCallbacks = []
        const onFilterCallbacks = []
        const onChangeCallbacks = []
        const onNotifyOnChangeCallbacks = []

        /**
         * @typedef {Object} ControllerConfig
         * @type {Object}
         * @property {Boolean} [filterOnSelect=true] Whether the filter controller should filter
         * when a filter value changes
         * @property {Function} [validationFunction=trueFunction] A function that returns a boolean
         * which either allows or disallows filtering. Defaults to the trueFunction which always
         * allows filtering. The filterController instance is passed as a parameter to the
         * validationFunction.
         * @property {Function} [executeCallback=trueFunction] A function that is executed when
         * the execute filters actions is called.
         */
        const configDefaults = {
            filterOnSelect: true,
            validationFunction: trueFunction,
            executeCallback: trueFunction,
        }

        function registerOnSelectCallbacks(filtersToRegister) {
            onSelectCallbacks.concat(
                filtersToRegister.map(
                    (filter) => filter.onSelect(() => self.filter(), false),
                ),
            )
        }

        function unRegisterOnSelectCallbacks() {
            onSelectCallbacks.forEach((callback) => callback.offSelect())
        }

        function registerNotifyOnChangeCallbacks(filtersToRegister) {
            onNotifyOnChangeCallbacks.concat(
                filtersToRegister.map(
                    (filter) => filter.onSelect(() => self.notifyOnChange(), false),
                ),
            )
        }

        function unRegisterNotifyOnChangeCallbacks() {
            onNotifyOnChangeCallbacks.forEach((callback) => callback.offSelect())
        }
        this.config = angular.merge({}, configDefaults, config)

        /**
         * Sets the filters of the filter controller. These filters will be used to filter the data
         *
         * @param {Filter[]} [filtersToSet=[]] A set of filters to use for filtering. If no array is
         * given the filters will be reset
         * @returns {FilterController} Returns this to allow for chaining
         */
        this.setFilters = function (filtersToSet) {
            unRegisterOnSelectCallbacks()
            unRegisterNotifyOnChangeCallbacks()

            if (!Array.isArray(filtersToSet)) {
                this.filters = []
                return this
            }

            if (this.config.filterOnSelect) {
                registerOnSelectCallbacks(filtersToSet)
            }

            registerNotifyOnChangeCallbacks(filtersToSet)

            this.filters = filtersToSet
            return this
        }

        /**
         * Sets the data of the filter controller. This is the data that will be filtered.
         *
         * @param {Object[]} [data=[]] The data to filter through. If no array is given the data
         * will be reset
         * @returns {FilterController} Returns this to allow for chaining
         */
        this.setData = function (data) {
            if (!Array.isArray(data)) {
                this.data = null
                return this
            }

            this.data = data
            return this
        }

        /**
         * Sets the disabled status for all filters
         *
         * @param {Boolean} disabled The disabled status to set all filters to. True is disabled,
         * false is enabled.
         * @returns {FilterController} Returns this to allow for chaining
         */
        this.setDisabled = function (disabled) {
            this.filters.forEach(function (filter) {
                filter.setDisabled(disabled, false)
            })

            this.filter()
            return this
        }

        /**
         * Resets all filters and updates the data accordingly
         *
         * @return {FilterController} Returns this to allow for chaining
         */
        this.resetFilters = function () {
            this.filters.forEach(function (filter) {
                filter.reset(false)
            })

            this.filter()
            this.notifyOnChange()

            return this
        }

        /**
         * Executes the current filters
         */
        this.executeFilters = function () {
            if (this.config.executeCallback) this.config.executeCallback()
            return this
        }

        /**
         * Returns whether the controller has filters that have a selection.
         *
         * @return {Boolean} Returns true if the controller has a filter that is active and false
         * otherwise
         */
        this.hasActiveFilters = function () {
            return this.filters.reduce(function (hasActive, filter) {
                return hasActive || filter.hasSelection()
            }, false)
        }

        /**
         * Returns the filters in the controller that have a selection.
         *
         * @return {Filter[]} Returns the filters that return true to filter.hasSelection()
         */
        this.getActiveFilters = function () {
            return this.filters.filter(function (filter) {
                return filter.hasSelection()
            })
        }

        /**
         * Validates all filters using the config's validationFunction
         *
         * @return {Boolean} The result of the
         */
        this.isValid = function () {
            return this.config.validationFunction(this)
        }

        /**
         * Filters the data using the registered filters
         *
         * @return {Object[]} The filtered data
         */
        this.filter = function () {
            if (this.data === null) {
                return []
            }
            if (this.isValid && !this.isValid()) {
                return []
            }

            const activeFilters = this.getActiveFilters()

            if (activeFilters.length === 0 || !this.config.filterOnSelect) {
                this.dataFiltered = this.data
            } else {
                this.dataFiltered = this.data.filter(function (object) {
                    let isValid = true

                    for (let i = 0; i < activeFilters.length; i += 1) {
                        const filter = activeFilters[i]

                        if (!filter.config.filterFunction(object, filter)) {
                            isValid = false
                            break
                        }
                    }

                    return isValid
                })
            }

            onFilterCallbacks.forEach(function (callback) {
                callback(self.dataFiltered)
            })

            return this.dataFiltered
        }

        /**
         * Registers a callback that gets called when the data was filtered
         * @param {Function} callback The funcion to call
         * @return {FilterController} Return this for chaining
         */
        this.onFilter = function (callback) {
            onFilterCallbacks.push(callback)
            return this
        }

        /**
         * Registers a callback that gets called when any of the filter values changed
         * @param {Function} callback The funcion to call
         * @return {FilterController} Return this for chaining
         */
        this.onChange = function (callback) {
            onChangeCallbacks.push(callback)
            return this
        }

        /**
         * Notifies the on change listeners of a change in filter values
         */
        this.notifyOnChange = function () {
            onChangeCallbacks.forEach((callback) => callback())
        }

        this.setFilters(filters)
        return this
    }

    /**
     * A filterable property that can be passed to a dockFilterBarItem directive which controls the
     * filter's state.
     *
     * @class
     * @param {String} name The identifying name of the filter
     * @param {String} translateLabel The label to use for translations
     * @param {String} property The property in the array item it resembles
     * @param {(String[]|Object[])} options An array of options the user can select from
     * @param {Config} config The filter configuration. Options described in the typedef below
     * @returns {Filter} Returns this to allow for chaining
     * @constructor
     */
    function Filter(name, translateLabel, property, options, config) {
        this.name = name
        this.translateLabel = translateLabel
        this.property = property
        this.disabled = false

        const self = this
        const valueCallbacks = []
        let registeredRequiredCallbacks = []

        function defaultFilterFunction(object, filter) {
            return angular.equals(
                object[filter.property],
                filter.getSelected(true, true),
            )
        }

        /**
         * @typedef {Object} Config
         * @type {Object}
         * @property {*} [options.default=null] The default selected option
         * @property {*} [options.empty=null] The value the model has when no option was selected
         * @property {Boolean} [options.fixed=false] Whether the options are loaded dynamically or
         * are fixed
         * @property {Boolean} [options.translate=false] Whether to translate the given options
         * @property {Number} [options.limit=50] The maximum amount of options to show. If the limit
         * is 0 there is no limit.
         * @property {String} [options.displayProperty=null] The property to display if the options
         *  are objects
         * @property {String} [options.filterProperty=null] The property to filter on if the options
         * are objects
         * @property {Function} [options.filterFunction=trueFunction] The function that is used to
         * create the title for an option
         * @property {Function} [options.mapFunction=null] A function that maps the selected option
         * to a different value
         * @property {Function} [options.titleMapFunction=returnFunction] The function that is used
         * to filter the options
         * @property {Boolean} [search=true] Whether the options can be searched
         * @property {Boolean} [searchOnly=false] Whether only a search input is visible
         * @property {Object} [ngModelOptions=null] The Angular model options to use
         * @property {Boolean} [allowClear=true] Whether the filter has a clear option (cross)
         * @property {Filter|Filter[]} [requires=null] The filter that is required to have a
         * selected value before this filter gets enabled. This is used and checked by the
         * dockFilterBar directive
         * @property {Boolean} [visible=true] Whether the filter is visible (in the filter bar)
         * @property {Boolean} [label=true] Whether the filter should include a label in the
         * template
         * @property {String} [labelProperty=''] If you have both placeholders and labels this
         * string gets added to the translateLabel to select the label
         *   translation
         * @property {Boolean} [placeholder=false] Whether the label inside the filter should be
         * translation placeholder instead of the label
         * @property {String} [placeholderProperty=''] If you have both placeholders and labels
         * this string gets added to the translateLabel to select the placeholder translation
         * @property {Boolean} [savePreference=true] Whether to save the filter's preference in the
         * session storage
         * @property {Function} [filterFunction=null] The function that can be used to actually
         * filter the object. Must return a boolean
         */
        const configDefaults = {
            options: {
                default: null,
                empty: null,
                fixed: false,
                translate: false,
                limit: 50,
                displayProperty: null,
                filterProperty: null,
                filterFunction: trueFunction,
                mapFunction: null,
                titleMapFunction: returnFunction,
            },
            search: true,
            searchOnly: false,
            ngModelOptions: null,
            allowClear: true,
            requires: null,
            visible: true,
            label: true,
            labelProperty: '',
            placeholder: false,
            placeholderProperty: '',
            savePreference: true,
            filterFunction: defaultFilterFunction,
        }

        this.config = angular.merge({}, configDefaults, config)

        this.options = {
            all: [],
            filtered: [],
            filteredCount: 0,
            loading: false,
            selected: this.config.options.default,
            selectedMapped: null,
        }

        this.model = {
            object: this.options,
            property: 'selected',
        }

        function unRegisterRequiredCallbacks() {
            registeredRequiredCallbacks.forEach(function (callback) {
                callback.offSelect()
            })

            registeredRequiredCallbacks = []
        }

        function checkRequiredFilters() {
            if (!Array.isArray(self.config.requires)) {
                return
            }

            self.setDisabled(
                !self.config.requires.reduce(
                    function (
                        allSelected,
                        requiredFilter,
                    ) {
                        return allSelected && requiredFilter.hasSelection()
                    },
                    true,
                ),
                false,
            )
        }

        function getStorageKey() {
            // Start with the filter name and make it snake_case
            const filterName = self.name
                .replace(/([A-Z])/g, '_$1')
                .toLowerCase()

            // Add the current path
            const path = trimPath($location.path())

            // Return the combined key
            return `filter_${path}_${filterName}`
        }

        function registerRequiredCallbacks(newFilters) {
            let newFltrs = newFilters

            if (!newFilters) {
                newFltrs = self.config.requires
            }

            if (!Array.isArray(newFilters)) {
                newFltrs = [newFilters]
            }

            // Filter out empty filters
            newFltrs = newFltrs.filter(function (filter) {
                return filter !== null && filter !== undefined
            })

            registeredRequiredCallbacks = registeredRequiredCallbacks.concat(newFltrs.map(function (filter) {
                /* When one of the required filters updates, check if all filters are selected,
                     * if so enable the filter, otherwise disable it */
                return filter.onSelect(checkRequiredFilters)
            }))
        }

        /**
         * Filters the options with the given input. Helpful for searchable selects. Uses the
         * standard 'filter' filter from Angular.
         *
         * @param {String} [input=''] The string to search for.
         * @returns {Filter} Returns this to allow for chaining
         */
        this.refreshFilteredOptions = function (input) {
            let correctedInput = input
            if (correctedInput === undefined) {
                correctedInput = ''
            }

            const filteredOptions = this.options.all.filter(this.config.options.filterFunction)

            if (correctedInput.length === 0) {
                if (
                    filteredOptions.length <= this.config.options.limit
                    || this.config.options.limit === 0
                ) {
                    this.options.filtered = filteredOptions
                } else {
                    this.options.filtered = []
                }

                // Set the option count to the total amount of options
                this.options.filteredCount = filteredOptions.length

                return this
            }

            const inputObject = {}
            inputObject[
                this.config.options.displayProperty || '$'
            ] = correctedInput

            const filteredOptionsTemporary = $filter('filter')(
                filteredOptions,
                inputObject,
            )

            if (
                filteredOptionsTemporary.length <= this.config.options.limit
                || this.config.options.limit === 0
            ) {
                this.options.filtered = filteredOptionsTemporary
            } else {
                this.options.filtered = []
            }

            // Temporary filtered options because we want to show how many options were found
            this.options.filteredCount = filteredOptionsTemporary.length

            return this
        }

        /**
         * Sets the selectable options for the filter
         *
         * @param {String[]|Object[]} opts An array of options the user can select from
         * @returns {Filter} Returns this to allow for chaining
         */
        this.setOptions = function (opts) {
            this.options.all = opts
            this.refreshFilteredOptions()

            if (
                opts.length === 0
                || this.config.searchOnly
                || this.hasOption(this.getSelected(false, false))
            ) {
                return this
            }

            if (this.hasOption(this.config.options.default)) {
                this.reset()
            } else {
                this.empty()
            }

            return this
        }

        /**
         * Checks if the filter has a certain option
         *
         * @param {String|Object} option The option to check for
         * @return {Boolean} Returns true if the option was found, false otherwise
         */
        this.hasOption = function (option) {
            return this.options.all.some((compareOption) => angular.equals(compareOption, option))
        }

        /**
         * Sets the loading state of the filter
         *
         * @param {Boolean} loading Whether the filter is loading
         * @returns {Filter} Returns this to allow for chaining
         */
        this.setLoading = function (loading) {
            this.options.loading = loading
            return this
        }

        /**
         * Returns if the filter is in the loading state
         *
         * @return {Boolean} Returns true if the filter is in the loading state, false otherwise
         */
        this.isLoading = function () {
            return this.options.loading
        }

        /**
         * Sets or unsets the disabled state of the filter
         *
         * @param {Boolean} disabled The disabled state of the filter
         * @param {Boolean} [notifyListeners=true] Whether to call the onSelect callbacks for this
         * change
         * @return {Filter} Returns this to allow for chaining
         */
        this.setDisabled = function (disabled, notifyListeners) {
            this.disabled = disabled

            if (disabled) {
                this.default(notifyListeners)
            }

            return this
        }

        /**
         * Sets the options.filterFunction config property that filters the options before the
         * input.
         *
         * @param {Function} filterFunction The function to use, its only argument is the option
         * that needs to be filtered
         * @return {Filter} Returns this to allow for chaining
         */
        this.setOptionsFilterFunction = function (filterFunction) {
            this.config.options.filterFunction = filterFunction
            return this
        }

        /**
         * Sets the required config property for the filters that are required to have a value
         * before this filter can be assigned.
         *
         * @param {Filter|Filter[]} filters A filter, or an array of filters that you want to set
         * required to
         * @return {Filter} Returns this to allow for chaining
         */
        this.setRequired = function (filters) {
            this.config.requires = []
            unRegisterRequiredCallbacks()
            this.addRequired(filters)
            return this
        }

        /**
         * Adds to the required config property for the filters that are required to have a value
         * before this filter can be assigned.
         *
         * @param {Filter|Filter[]} filters A filter, or an array of filters that you want to add
         * to the required filters
         * @return {Filter} Returns this to allow for chaining
         */
        this.addRequired = function (filters) {
            let fltrs = filters

            if (!Array.isArray(filters)) {
                fltrs = [filters]
            }

            this.config.requires = this.config.requires.concat(fltrs)
            registerRequiredCallbacks(fltrs)

            return this
        }

        /**
         * Sets the selection to a certain value
         *
         * @param {String|Object} selection The selected value to set the filter to
         * @returns {Filter} Returns this to allow for chaining
         */
        this.setSelected = function (selection) {
            this.setModelValue(selection)
            this.notifyOnSelect(this.getModelValue())
            this.updatePreference()
            return this
        }

        /**
         * Gets the currently selected value
         *
         * @param {Boolean} [executeOptionMapFunction=true] Whether to execute the
         * options.mapFunction function before sending back
         * @param {Boolean} [filterProperty=false] Whether to get the filterProperty instead of the
         * whole option object if the options are objects
         * @return {String|Object} The currently selected value
         */
        this.getSelected = function (executeOptionMapFunction, filterProperty) {
            let execOptionMapFunction = executeOptionMapFunction
            let filterProp = filterProperty

            if (executeOptionMapFunction !== false) {
                execOptionMapFunction = true
            }

            if (filterProperty !== true) {
                filterProp = false
            }

            let selected
            if (execOptionMapFunction && this.config.options.mapFunction) {
                if (this.options.selectedMapped === null) {
                    const selectedMapped = this.config.options.mapFunction(this.getModelValue())
                    this.options.selectedMapped = selectedMapped
                }

                selected = this.options.selectedMapped
            } else {
                selected = this.getModelValue()
            }

            if (selected === null || selected === undefined) {
                return selected
            }

            if (filterProp && this.config.options.filterProperty) {
                selected = selected[this.config.options.filterProperty]
            }

            return selected
        }

        this.isSelected = function (option) {
            return angular.equals(this.getSelected(false, false), option)
        }

        this.getSelectedTitle = function () {
            return this.getOptionTitle(this.getSelected())
        }

        this.getOptionTitle = function (option) {
            if (!option) {
                return option
            }

            return this.config.options.titleMapFunction(this.config.options.displayProperty
                ? option[this.config.options.displayProperty]
                : option)
        }

        /**
         * Checks if the filter has an option selected
         *
         * @return {Boolean} Returns true if the filter has a selection and false if it's empty
         */
        this.hasSelection = function () {
            return this.getModelValue() !== this.config.options.empty
        }

        /**
         * Checks if the filter has no option selected
         *
         * @return {Boolean} Returns true if the filter is empty and false if it has a selection
         */
        this.hasNoSelection = function () {
            return !this.hasSelection()
        }

        /**
         * Register a callback that gets fired when the selected option is changed and call it once
         * to instantiate
         *
         * @param {Function} callback The function that gets called
         * @param {Boolean} [executeCallback=true] Whether to execute the callback once on
         * registration.
         * @return {Object} An object with the offSelect function to unregister the callback
         */
        this.onSelect = function (callback, executeCallback = true) {
            valueCallbacks.push(callback)

            if (executeCallback) {
                callback(self.getModelValue())
            }

            return {
                offSelect() {
                    const callbackIndex = valueCallbacks.indexOf(callback)

                    if (callbackIndex === -1) {
                        return
                    }

                    valueCallbacks.splice(callbackIndex, 1)
                },
            }
        }

        /**
         * Notifies all callback listeners of a value change.
         */
        this.notifyOnSelect = function ($item, $model) {
            if ($item === undefined) {
                this.empty(false)
            } else {
                this.options.selectedMapped = null
                this.updatePreference()
            }

            valueCallbacks.forEach(function (callback) {
                callback(self.getModelValue(), $model)
            })
        }

        /**
         * Resets the filter to the default value
         *
         * @param {Boolean} [notifyListeners=true] Whether to call the onSelect callbacks for
         * this change
         * @return {Filter} Returns this to allow for chaining
         */
        this.reset = function (notifyListeners) {
            this.setModelValue(this.config.options.default)
            this.options.selectedMapped = null
            this.refreshFilteredOptions()
            this.updatePreference()

            if (notifyListeners !== false) {
                this.notifyOnSelect(this.getModelValue())
            }

            checkRequiredFilters()

            return this
        }

        /**
         * Sets the filter to its empty value
         *
         * @param {Boolean} [notifyListeners=true] Whether to call the onSelect callbacks for
         * this change
         * @return {Filter} Returns this to allow for chaining
         */
        this.empty = function (notifyListeners) {
            this.setModelValue(this.config.options.empty)
            this.options.selectedMapped = null
            this.updatePreference()

            if (notifyListeners !== false) {
                this.notifyOnSelect(this.getModelValue())
            }

            return this
        }

        /**
         * Sets the filter to its default value
         *
         * @param {Boolean} [notifyListeners=true] Whether to call the onSelect callbacks for
         * this change
         * @return {Filter} Returns this to allow for chaining
         */
        this.default = function (notifyListeners) {
            this.setModelValue(this.config.options.default)
            this.options.selectedMapped = null
            this.updatePreference()

            if (notifyListeners !== false) {
                this.notifyOnSelect(this.getModelValue())
            }

            return this
        }

        /**
         * Checks if the selected option is the default
         *
         * @return {Boolean} Returns true if the selected option is the default, false otherwise
         */
        this.defaultSelected = function () {
            return this.getModelValue() === this.config.options.default
        }

        /**
         * Gets the preference from the session storage
         *
         * @return {Object} The value that is preferred. Null if the item doesn't exist or the
         * config doesn't say to save the preference.
         */
        this.getPreference = function () {
            if (!this.config.savePreference) {
                return null
            }

            return angular.fromJson(sessionStorage.getItem(getStorageKey()))
        }

        /**
         * Updates the preference in the session storage to the current selection
         */
        this.updatePreference = function () {
            if (!this.config.savePreference) {
                return
            }

            sessionStorage.setItem(
                getStorageKey(),
                angular.toJson(this.getSelected(false, false)),
            )
        }

        /**
         * Registers an external model as used in the dock filter bar item directive to the filter
         * controller
         *
         * @param {Object} object The model / object to alter
         * @param {String} prop The model property to alter
         */
        this.registerExternalModel = function (object, prop) {
            this.model.object = object
            this.model.property = prop
        }

        this.getModelValue = function () {
            return this.model.object[this.model.property]
        }

        this.setModelValue = function (value) {
            this.model.object[this.model.property] = value
        }

        if (this.config.savePreference) {
            const preference = this.getPreference()

            if (preference !== null) {
                this.setModelValue(preference)
            }
        }

        if (this.config.options.fixed) {
            this.setOptions(options)
        }

        registerRequiredCallbacks()

        return this
    }

    this.Filter = Filter
    this.Controller = FilterController
})
