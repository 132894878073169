import dock from '../modules/dock'
import brandField from './metaInfo/handlers/brandHandler'
import {
    mainCategoryField,
    categoryField,
    subCategoryField,
} from './metaInfo/handlers/categoryHandler'
import seasonField from './metaInfo/handlers/seasonHandler'
import fitField from './metaInfo/handlers/fitHandler'
import dimensioningField from './metaInfo/handlers/dimensioningHandler'
import sizeField from './metaInfo/handlers/sizeHandler'

export const FILTER_NAMES = Object.freeze({
    CATEGORY: 'category',
    MAIN_CATEGORY: 'mainCategory',
    SUB_CATEGORY: 'subCategory',
    BRAND: 'brand',
    COLOR: 'color',
    SEASON: 'season',
    FIT: 'fit',
    DIMENSIONING: 'dimensioning',
    SIZE_ON_PHOTO: 'sizeOnPhoto',
})

/* eslint-disable */
dock.factory('commonFilters', /* @ngInject */ function ($interval, $timeout, metaInfo, Filters, Language) {
    const commonFilters = {}

    commonFilters.createProductFilter = function(filterConfig, codeOnly) {
        return new Filters.Filter(
            filterConfig.name,
            filterConfig.translateLabel,
            filterConfig.property,
            [],
            {
                options: {
                    fixed: true,
                    displayProperty: codeOnly === true ? null : 'naam',
                },
                labelProperty: '.label',
                placeholderProperty: '.placeholder',
                search: filterConfig.search,
            },
        ).setLoading(true)
    }

    commonFilters.loadMetaInfoOptions = function(filter, filterConfig) {
        return metaInfo
            .load(Language.get())
            .then(metaInfo => {
                let valuesFunction = 'getValues'
                let field = null

                if (filterConfig) {
                    field = filterConfig.metaInfoField
                    valuesFunction =
                        filterConfig.metaInfoFunction || valuesFunction
                } else {
                    field = filter.property.toLowerCase()
                }

                let newOptions = metaInfo.field(field)[valuesFunction]()
                filter.setOptions(newOptions).setLoading(false)

                $timeout(() => {
                    if (filterConfig.autoLoad && newOptions.length === 1) {
                        filter.setSelected(newOptions[0])
                    }
                })

                if (filterConfig.titleMapFunction) {
                    filter.config.options.titleMapFunction = (...args) =>
                        metaInfo
                            .field(field)
                            [filterConfig.titleMapFunction].call(
                                metaInfo.field(field),
                                ...args,
                            )
                }

                return newOptions
            })
            .catch(error => {
                console.error(error)
            })
    }

    const productsTranslatePrefix = 'product.property.'
    commonFilters.getCategoryFilters = function(includeSubCategory = true) {
        const filterConfigs = [
            {
                name: FILTER_NAMES.MAIN_CATEGORY,
                translateLabel: `${productsTranslatePrefix}mainCategory`,
                property: 'hoofdCategorie',
                metaInfoField: mainCategoryField,
                search: false,
            },
            {
                name: FILTER_NAMES.CATEGORY,
                translateLabel: `${productsTranslatePrefix}category`,
                property: 'categorie',
                metaInfoField: categoryField,
                search: true,
            },
        ]

        if (includeSubCategory) {
            filterConfigs.push({
                name: FILTER_NAMES.SUB_CATEGORY,
                translateLabel: `${productsTranslatePrefix}subCategory`,
                property: 'subCategorie',
                metaInfoField: subCategoryField,
                search: true,
            })
        }

        const filters = filterConfigs.map(commonFilters.createProductFilter)
        filters.forEach((filter, index) => {
            commonFilters.loadMetaInfoOptions(filter, filterConfigs[index])
        })

        const mainCategoryFilter = filters[0]

        const categoryFilter = filters[1]

        categoryFilter.setRequired(mainCategoryFilter)

        mainCategoryFilter.onSelect(function () {
            categoryFilter.reset(false)
        }, false);

        categoryFilter.setOptionsFilterFunction(function(option) {
            if (!mainCategoryFilter.getSelected(false, false)) {
                return true
            }

            return (
                option.code.indexOf(
                    mainCategoryFilter.getSelected(true, true).code,
                ) === 0
            )
        })

        if (includeSubCategory) {
            const subCategoryFilter = filters[2]
            subCategoryFilter.setRequired([mainCategoryFilter, categoryFilter])

            categoryFilter.onSelect(function() {
                subCategoryFilter.reset(false)

                if (subCategoryFilter.options.filteredCount === 0) {
                    subCategoryFilter.setDisabled(true, false)
                }
            })

            subCategoryFilter.setOptionsFilterFunction(function(option) {
                if (!categoryFilter.getSelected(false, false)) {
                    return true
                }

                return (
                    option.code.indexOf(
                        categoryFilter.getSelected(true, true).code,
                    ) === 0
                )
            })
        }

        return filters
    }

    commonFilters.getCombinedCategoryFilter = function() {
        const filter = new Filters.Filter(
            FILTER_NAMES.CATEGORY,
            `${productsTranslatePrefix}category`,
            null,
            [],
            {
                options: {
                    fixed: true,
                    displayProperty: 'title',
                    limit: 0,
                },
                labelProperty: '.label',
                placeholderProperty: '.placeholder',
            },
        ).setLoading(true)

        commonFilters.loadMetaInfoOptions(filter, {
            metaInfoField: mainCategoryField,
            metaInfoFunction: 'getCombined',
        })

        return filter
    }

    commonFilters.getBrandFilter = function (autoLoad = false, fromMetaInfo = true) {
        const filter = commonFilters.createProductFilter({
            name: FILTER_NAMES.BRAND,
            translateLabel: `${productsTranslatePrefix}brand`,
            property: 'merk',
        })

        if (fromMetaInfo) {
            commonFilters.loadMetaInfoOptions(filter, {
                metaInfoField: brandField,
                metaInfoFunction: 'getWhitelisted',
                autoLoad
            })
        }

        return filter
    }

    /**
     * Gets a filter for filtering on color
     * @returns {Filter}
     */
    commonFilters.getColorFilter = function() {
        const filter = commonFilters.createProductFilter({
            name: FILTER_NAMES.COLOR,
            translateLabel: `${productsTranslatePrefix}color`,
            property: 'kleur',
        })

        commonFilters.loadMetaInfoOptions(filter, {
            metaInfoField: 'kleurcode',
        })

        return filter
    }

    /**
     * Gets a filter for filtering on season
     * @returns {Filter}
     */
    commonFilters.getSeasonFilter = function (deprecated = true) {
        const filter = commonFilters.createProductFilter(
            {
                name: FILTER_NAMES.SEASON,
                translateLabel: `${productsTranslatePrefix}season`,
                property: 'seizoen',
            },
            true,
        )

        commonFilters.loadMetaInfoOptions(filter, {
            metaInfoField: seasonField,
            metaInfoFunction: deprecated ? 'getCodes' : 'getNonDeprecatedCodes',
            titleMapFunction: 'getName',
        })

        return filter
    }

    /**
     * Gets a filter for filtering on fit
     * @returns {Filter}
     */
    commonFilters.getFitFilter = function() {
        const filter = commonFilters.createProductFilter(
            {
                name: FILTER_NAMES.FIT,
                translateLabel: `${productsTranslatePrefix}fit`,
            },
            true,
        )

        commonFilters.loadMetaInfoOptions(filter, {
            metaInfoField: fitField,
            metaInfoFunction: 'getNames',
        })

        return filter
    }

    /**
     * Gets a filter for filtering on dimensioning
     * @returns {Filter}
     */
    commonFilters.getDimensioningFilter = function() {
        const filter = commonFilters.createProductFilter({
            name: FILTER_NAMES.DIMENSIONING,
            translateLabel: `${productsTranslatePrefix}dimensioning`,
        })

        commonFilters.loadMetaInfoOptions(filter, {
            metaInfoField: dimensioningField,
        })

        return filter
    }

    commonFilters.getDimensioningAndPhotoSizeFilter = function() {
        const dimensioningFilter = commonFilters.getDimensioningFilter()

        const photoSizeFilter = commonFilters.createProductFilter(
            {
                name: FILTER_NAMES.SIZE_ON_PHOTO,
                translateLabel: `${productsTranslatePrefix}photoSize`,
            },
            true,
        )

        commonFilters.loadMetaInfoOptions(photoSizeFilter, {
            metaInfoField: sizeField,
            metaInfoFunction: 'getNames',
        })

        photoSizeFilter.setRequired(dimensioningFilter)

        metaInfo.load(Language.get()).then(metaInfo => {
            dimensioningFilter.onSelect(() => {
                const dimensioning = dimensioningFilter.getSelected(true, true)
                const newOptions = dimensioning
                    ? metaInfo.field(sizeField).getNames(dimensioning.code)
                    : []
                photoSizeFilter.setOptions(newOptions).setLoading(false)

                if (photoSizeFilter.options.filteredCount === 1) {
                    photoSizeFilter.setSelected(
                        photoSizeFilter.options.filtered[0],
                    )
                }
            })
        })

        return { dimensioningFilter, photoSizeFilter }
    }

    return commonFilters
})
