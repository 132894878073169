import dock from '../../../modules/dock'
import template from './linking.html'

dock.directive('productSectionLinking', function () {
    return {
        restrict: 'A',
        scope: false,
        template,
    }
})
