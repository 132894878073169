import angular from 'angular'
import './httpInterceptor'
import { RetailerStatus } from '../services/Retailer'
import { ApiBaseUrl } from '../shared/constants/apiBaseUrl'
import { english, dutch } from '../shared/constants/language'
import dock from '../modules/dock'

/* eslint-disable */
dock.factory('api', /* @ngInject */ function ($rootScope, $http, $log, $q, Upload) {
    const REST_BASE_URL = ApiBaseUrl.EXTENSION

    /**
     * Executes an Angular $http request with some default config such as the base URL and possibly the session
     * id.
     *
     * @param {Object} requestConfig An $http configuration object
     *  (https://docs.angularjs.org/api/ng/service/$http#usage). that will be altered to add defaults for API
     *  requests as mentioned in the function description.
     * @param {Boolean} [includeSessionId=true] Whether to include the session ID header in the request.
     *  If getting the session ID fails a warning is logged, Dock will try to reauthenticate and a rejected
     *  promise with the error is returned.
     * @returns {Promise} If includeSessionId is true and getting the session ID fails, a rejected
     *  promise with the error is returned, otherwise the $http promise is returned
     *  (https://docs.angularjs.org/api/ng/service/$http#general-usage).
     */
    function $wsnlHttp(requestConfig, includeSessionId) {
        let request

        // Make sure the header config exists if we later want to extend it
        if (!angular.isObject(requestConfig.headers)) {
            requestConfig.headers = {}
        }

        // Add the session ID to the headers if one is needed
        if (includeSessionId !== false) {
            let sessionId

            try {
                sessionId = getSessionId()

                // Extend the request headers with the session ID header
                const sessionIdHeader = getSessionIdHeader(sessionId)
                angular.extend(requestConfig.headers, sessionIdHeader)
            } catch (error) {
                request = $q.reject(error)
            }
        }

        // Prefix the url with the base URL
        requestConfig.url = REST_BASE_URL + requestConfig.url

        // Start executing the http request if it wasn't already set due to an error
        if (angular.isUndefined(request)) {
            request = $http(requestConfig)
        }

        // Return original promise to allow for promise chaining
        return request
    }

    function getSessionIdHeader(sessionId) {
        return {
            'header-sessionId': sessionId,
        }
    }

    function getSessionId() {
        const sessionId = $rootScope.retailer.getSessionId()

        // getItem() returns null if the key is not found
        if (sessionId === null) {
            if ($rootScope.retailer) {
                $rootScope.retailer.status = RetailerStatus.NOT_LOGGED_IN
            }

            throw new Error('Session ID not found in session storage')
        }

        return sessionId
    }

    return {
        metaInfo(language) {
            if (![english, dutch].includes(language)) {
                throw new Error(`Language ${ language } not supported for metaInfoVelden, specify API endpoint for this language`)
            }

            let url;
            switch (language) {
                case dutch: url = 'metainfovelden?fromBoutique=false'; break;
                case english: url = 'metainfovelden?fromBoutique=true'; break;
            }

            return $wsnlHttp({
                method: 'GET',
                url
            })
        },
        login: {
            login(user) {
                return $wsnlHttp(
                    {
                        method: 'POST',
                        url: 'user/login',
                        data: user,
                    },
                    false,
                )
            },
            reauthenticate(data) {
                return $wsnlHttp(
                    {
                        method: 'POST',
                        url: 'user/reauthenticate',
                        data,
                    },
                    false,
                )
            },
            logout() {
                try {
                    getSessionId()
                } catch (error) {
                    console.warn(
                        'Trying to logout without a session ID. Error:',
                        error,
                    )
                    _toRoot()
                    return $q.reject(error)
                }

                const logoutRequest = $wsnlHttp({
                    method: 'POST',
                    url: 'user/logout',
                })

                logoutRequest.catch(function() {
                    _toRoot()
                })

                return logoutRequest
            },
        },
        user: {
            currentRetailerStatus() {
                return $wsnlHttp({
                    method: 'GET',
                    url: 'user/currentRetailerStatus',
                })
            },
        },
        import: {
            upload(file, delimiter) {
                return Upload.upload({
                    url: `${REST_BASE_URL}import`,
                    data: {
                        File: file,
                        Delimiter: delimiter,
                    },
                    headers: getSessionIdHeader(getSessionId()),
                })
            },
        },
        products: {
            getProduct(product, canceler) {
                const requestConfig = {
                    method: 'GET',
                    url: `producten/${product.id}?withStock=false`,
                }

                if (canceler) {
                    requestConfig.timeout = canceler.promise
                }

                return $wsnlHttp(requestConfig)
            },
            getProductStock(product, canceler, onlyMagentoStock = false) {
                const requestConfig = {
                    method: 'GET',
                    url: `producten/${
                        product.id
                    }?withStock=true&onlyMagentoStock=${onlyMagentoStock}`,
                }

                if (canceler) {
                    requestConfig.timeout = canceler.promise
                }

                return $wsnlHttp(requestConfig)
            },
            getProductStockPriceBulk(skus, canceler) {
                const requestConfig = {
                    method: 'POST',
                    url: 'producten/stock-price',
                    data: { skus }
                }

                if (canceler) {
                    requestConfig.timeout = canceler.promise
                }

                return $wsnlHttp(requestConfig)
            },
            getWsnlProduct(product, canceler) {
                const requestConfig = {
                    method: 'GET',
                    url: `producten/wsnlproduct/${product.id}`,
                }

                return $wsnlHttp(requestConfig)
            },
            printLabels(ids) {
                return $wsnlHttp({
                    method: 'POST',
                    url: 'producten/action/registerForPhotography',
                    data: ids,
                })
            },
            addProduct(product, canceler) {
                return $wsnlHttp({
                    method: 'POST',
                    url: 'producten',
                    data: product,
                    timeout: canceler.promise,
                })
            },
            updateProduct(product, canceler) {
                const requestConfig = {
                    method: 'PUT',
                    url: `producten/${product.id}`,
                    data: product,
                }

                if (canceler) {
                    requestConfig.timeout = canceler.promise
                }

                return $wsnlHttp(requestConfig)
            },
            deleteProduct(product) {
                return $wsnlHttp({
                    method: 'DELETE',
                    url: `producten?productIds=${product.id}`,
                })
            },
            getVariant(
                pimProductId,
                pimProductVariatieId,
                canceler = $q.defer(),
            ) {
                return $wsnlHttp({
                    method: 'GET',
                    url: `producten/${pimProductId}/variaties/${pimProductVariatieId}`,
                    timeout: canceler.promise,
                })
            },
            addVariant(pimProductId, variant) {
                return $wsnlHttp({
                    method: 'POST',
                    url: `producten/${pimProductId}/variaties/`,
                    data: variant,
                })
            },
            addVariantBulk(pimProductId, variants) {
                return $wsnlHttp({
                    method: 'POST',
                    url: `producten/${pimProductId}/variaties/bulk`,
                    data: variants,
                })
            },
            updateVariant(
                pimProductId,
                pimProductVariatieId,
                variant,
                canceler = $q.defer(),
            ) {
                return $wsnlHttp({
                    method: 'PUT',
                    url: `producten/${pimProductId}/variaties/${pimProductVariatieId}`,
                    data: variant,
                    timeout: canceler.promise,
                })
            },
            updateVariantBulk(pimProductId, variants) {
                return $wsnlHttp({
                    method: 'PUT',
                    url: `producten/${pimProductId}/variaties/bulk`,
                    data: variants,
                })
            },
            updateVariantBulkStock(variantStock) {
                return $wsnlHttp({
                    method: 'PUT',
                    url: `producten/variaties/bulk`,
                    data: variantStock
                })
            },
            products(status, canceler) {
                return $wsnlHttp({
                    method: 'GET',
                    url: `producten${status ? `?productStatus=${status}` : ''}`,
                    timeout: canceler.promise,
                }).then(response => {
                    response.data.producten.forEach(product => {
                        delete product.amountInStock
                        delete product.amountOfVariants
                    })

                    return response
                })
            },
            productCount(canceler) {
                return $wsnlHttp({
                    method: 'GET',
                    url: `producten/counter`,
                    timeout: canceler.promise,
                }).then(response => {
                    return response.data
                })
            },
            match(product, status, canceler = $q.defer()) {
                return $wsnlHttp({
                    method: 'POST',
                    url: `producten/matcher${status ? `?productStatus=${status}` : ''}`,
                    data: product,
                    timeout: canceler.promise,
                })
            },
            reportIncorrectMpn(productId, suggestedMpn, canceler = $q.defer()) {
                return $wsnlHttp({
                    method: 'POST',
                    url: `producten/${productId}/reportIncorrectMpn`,
                    data: {
                        productSuggestedMpn: suggestedMpn
                    },
                    timeout: canceler.promise
                })
            }
        },
        returns: {
            getReturns(status, canceler, filters, sort, pagination) {
                const params = new URLSearchParams();
                if (status) {
                    params.set('status', status || 'NIEUW');
                }
                filters.forEach((filter) => {
                    let value = filter.getSelected();
                    if (value) {
                        if (filter.property === 'rmadate') {
                            value = new Date(filter.getSelected()).toISOString()
                        }
                        params.set(filter.property, value);
                    }
                });
                if (sort.sortField) {
                    params.set('sortField', sort.sortField)
                    params.set('sortOrder', sort.sortOrder)
                }
                params.set('itemsPerPage', pagination.itemsPerPage)
                params.set('currentPage', pagination.page)
                return $wsnlHttp({
                    method: 'GET',
                    url: `returns?${params.toString()}`,
                    timeout: canceler.promise,
                })
            },
            getReturn(rmaReferenceId) {
                return $wsnlHttp({
                    method: 'GET',
                    url: `returns/${rmaReferenceId}`,
                })
            },
            getReturnWithOrder(rmaReferenceId, canceler = $q.defer()) {
                return $wsnlHttp({
                    method: 'GET',
                    url: `returns/${rmaReferenceId}?orderData=true`,
                    timeout: canceler.promise,
                })
            },
            returnAction(orderReferenceId, orderIdSource, post, canceler = $q.defer()) {
                return $wsnlHttp({
                    method: 'POST',
                    url: `returns/${orderIdSource}/${orderReferenceId}/action/return`,
                    data: post,
                    timeout: canceler.promise,
                })
            },
        },
        orders: {
            getOrders(status, canceler, filters, sort, pagination) {
                const params = new URLSearchParams();
                params.set('status', status || 'NIEUW');
                if (status == 'AFGEROND') {
                    const dateFrom = new Date()
                    dateFrom.setMonth(dateFrom.getMonth() - 1)
                    const filterFrom = dateFrom.toISOString().slice(0, 10)
                    params.set('dateTime', filterFrom);
                }
                filters.forEach((filter) => {
                    let value = filter.getSelected();
                    if (value) {
                        if (filter.property === 'dateTime') {
                            value = new Date(filter.getSelected()).toISOString()
                        }
                        params.set(filter.property, value);
                    }
                });
                if (sort.sortField) {
                    params.set('sortField', sort.sortField)
                    params.set('sortOrder', sort.sortOrder)
                }
                params.set('itemsPerPage', pagination.itemsPerPage)
                params.set('currentPage', pagination.page)
                return $wsnlHttp({
                    method: 'GET',
                    url: `orders?${params.toString()}`,
                    timeout: canceler.promise,
                })
            },
            getOrder(orderReferenceId, orderIdSource, canceler) {
                const requestConfig = {
                    method: 'GET',
                    url: `orders/${orderIdSource}/${orderReferenceId}`,
                }

                if (canceler) {
                    requestConfig.timeout = canceler.promise
                }

                return $wsnlHttp(requestConfig)
            },
            search(string, type, canceler) {
                return $wsnlHttp({
                    method: 'GET',
                    url: `orders?${type}=${string}`,
                    timeout: canceler.promise,
                })
            },
            newOrderCount() {
                return $wsnlHttp({
                    method: 'GET',
                    url: 'orders/counter',
                })
            },
            processOrder(order) {
                return $wsnlHttp({
                    method: 'PUT',
                    url: `orders/${order.orderId.source}/${order.orderReferenceId}/action/updateStatusToIngepakt`,
                })
            },
            deficient(order, deficiency, canceler) {
                return $wsnlHttp({
                    method: 'POST',
                    url: `orders/${order.orderReferenceId.source}/${order.orderReferenceId.orderId}/orderItems/${
                        order.orderItemId
                    }/action/reportOrderItemDeficiency`,
                    data: deficiency,
                    timeout: canceler.promise,
                })
            },
            pakbon(orderReferenceIdSourceObjects) {
                return $wsnlHttp({
                    method: 'POST',
                    url: `orders/pakbons`,
                    data: { orderIds: orderReferenceIdSourceObjects },
                    responseType: 'blob',
                })
            },
        },
        brands: {
            get(canceler) {
                return $wsnlHttp({
                    method: 'GET',
                    url: 'brands',
                    timeout: canceler.promise,
                })
            },
        },
    }

    // Redirects to dock root and preserves query string.
    function _toRoot() {
        document.location.href = `/${window.location.search}`
    }
})
