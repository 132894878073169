import Update from './Update'

/**
 * A registry that keeps track of all updates
 */
export default class UpdateRegistry {
    constructor() {
        this.registry = new Map()
    }

    /**
     * Registers an update to the update registry
     *
     * @param {Version} version The version for which the update should be executed
     * @param {Update} update The {@link Update} instance that should be executed when this update is triggered
     */
    register(version, update) {
        this.registry.set(version.toString(), update)
    }

    /**
     * Returns the {@link Update} instance for the given version
     *
     * @param {Version} version The version for which you want to find an update
     * @return {Update} Returns an {@link Update} instance for the given version. Returns a specific version update as
     * found in ./updates or else returns the normal Update instance which contains no update.
     */
    getUpdate(version) {
        if (this.registry.has(version.toString())) {
            return this.registry.get(version.toString())
        }

        return new Update(version)
    }
}
