import TranslationsLoader from './TranslationsLoader'
import registry from '../metaInfo/registry'
import { dutch } from '../../shared/constants/language'

export default class MetaInfoTranslationsLoader extends TranslationsLoader {
    constructor(metaInfo, Retailer) {
        super()
        this.metaInfo = metaInfo
        this.Retailer = Retailer
    }

    load(language) {
        if (!this.Retailer.isLoggedIn()) {
            return Promise.reject(new Error('Not loading meta info yet since the user is not logged in yet'))
        }

        const cachedOrPending = this.getCachedOrPending(language)

        if (cachedOrPending !== null) {
            return cachedOrPending
        }

        const promise = this.metaInfo
            .load(language)
            .then((languageMetaInfo) => {
                const translations = {}

                registry.getTranslatableFields().forEach((field) => {
                    const metaInfoField = languageMetaInfo.field(field)

                    metaInfoField.getValues().forEach((value) => {
                        translations[value.code] = value.naam
                    })
                })

                return translations
            })

        this.handleCaching(language, promise)
        return promise
    }
}
