import TranslationsLoader from './TranslationsLoader'
// import { dutch, english } from '../../shared/constants/language'

import { dutch, english } from '../../shared/constants/language'


/**
 * Returns a promise that returns the language object for a specific language
 *
 * ! Always make sure these return cached results once loaded, since it gets
 * re-called after login !
 *
 * @param {String} language
 * @return {Promise<Object>} A promise that resolves with the translations object
 */
export function getTranslations(language) {
    switch (language) {
        case dutch:
            return import('../../translations/nl.json')
        case english:
            return import('../../translations/en-gb.json')
        default:
            return Promise.resolve()
    }
}

export default class JsonTranslationsLoader extends TranslationsLoader {
    load(language) {
        const cachedOrPending = this.getCachedOrPending(language)

        if (cachedOrPending !== null) {
            return cachedOrPending
        }

        const promise = getTranslations(language)

        this.handleCaching(language, promise)
        return promise
    }
}
