import angular from 'angular'
import dock from '../modules/dock'

dock.service('Sorting', /* @ngInject */ function ($location) {
    /**
     * A controller that can be used to sort a set of data
     *
     * @class
     * @param {SortItem[]} [sortItems=[]] A set of sort items that the controller can sort on
     * @param {SortControllerConfig} [config={}] The sort controller configuration as described in the typedef below. If
     * no configuration is given it falls back to the defaults
     * @returns {SortController} Returns this to allow for chaining
     * @constructor
     */
    function SortController(sortItems, config) {
        this.active = null
        this.data = null
        this.sortItems = sortItems || []

        const onSortCallbacks = []
        const self = this

        /**
         * @typedef {Object} SortControllerConfig
         * @type {Object}
         */
        const configDefaults = {}

        this.config = angular.extend({}, configDefaults, config)

        function getStorageKey() {
            const path = $location.path()
            return `sorting_${path.slice(1).replace('/', '_')}`
        }

        /**
         * Sorts the data using the sortItem's sort algorithm
         *
         * @returns {SortController} Returns this to allow for chaining
         */
        this.sort = function () {
            if (this.data !== null && this.active !== null) {
                this.data = this.active.sort(self.data)

                onSortCallbacks.forEach(function (callback) {
                    callback(self.data, self.active)
                })
            }

            return this
        }

        /**
         * Sets the data of the sort controller. This is the data that will be sorted.
         *
         * @param {Object[]} [data=[]] The data to sort. If no array is given the data will be reset
         * @returns {SortController} Returns this to allow for chaining
         */
        this.setData = function (data) {
            if (!Array.isArray(data)) {
                this.data = null
                return this
            }

            this.data = data
            return this
        }

        /**
         * Sets the active sort item
         *
         * @param {SortItem} active The active sort item
         * @returns {SortController} Returns this to allow for chaining
         */
        this.setActive = function (active) {
            this.active = active

            return this
        }

        /**
         * Toggles the state of the given item. Activates it if it is not activated and toggles its reverse state
         * otherwise
         *
         * @param {SortItem} item The item to toggle state on
         * @returns {SortController} Returns this to allow for chaining
         */
        this.toggleState = function (item) {
            if (this.active === item) {
                item.toggleReverse()
            } else {
                this.setActive(item)
            }
            this.sort()

            return this
        }

        /**
         * Allows you to subscribe a callback to the sort function
         *
         * @param {Function} callback A function that gets executed when the data is sorted
         */
        this.onSort = function (callback) {
            onSortCallbacks.push(callback)
        }

        /**
         * Updates the preference in the session storage with the active sort property and if its sorted in reverse
         */
        this.updatePreference = function () {
            const preference = {
                identifier: this.active.getIdentifier(),
                reverse: this.active.isReverse(),
            }

            sessionStorage.setItem(getStorageKey(), JSON.stringify(preference))
        }

        /**
         * Gets the preference from the session storage
         *
         * @returns {Object|null} The parsed preference from the storage session, null if the preference was never
         * saved
         */
        this.getPreference = function () {
            return JSON.parse(sessionStorage.getItem(getStorageKey()))
        }

        /**
         * Returns the sort item with the given identifier
         *
         * @param {String} identifier The identifier to check for
         * @return {SortItem|undefined} The sort item corresponding to the identifier
         */
        this.getSortItem = function (identifier) {
            return (
                this.sortItems &&
                this.sortItems.filter(sortItem =>
                    sortItem.identify(identifier))[0]
            )
        }

        this.onSort(() => {
            this.updatePreference()
        })

        const preference = this.getPreference()
        if (preference !== null) {
            const preferedSortItem = this.getSortItem(preference.identifier)

            if (preferedSortItem !== undefined) {
                this.setActive(preferedSortItem)
                this.active.setReverse(preference.reverse)
            }
        }

        return this
    }

    /**
     * An item that can sort a set of data
     *
     * @class
     * @param {SortItemConfig} [config] The sorting item config, defined in the typedef below. If no config is
     * passed, the defaults are used
     * @returns {SortItem} Returns this to allow for chaining
     * @constructor
     */
    function SortItem(config) {
        this.reverse = false

        /**
         * @typedef {Object} SortItemConfig
         * @property {String} [name=null] The name of the sort item. If no property is given the name is mandatory
         * @property {String} [property=null] The property to sort on. This is not required if a custom sort
         * function is passed. If no name is given the property is mandatory
         * @property {SortFunction|String} [sortFunction='string'] The sort function to use for sorting. A string
         * can also be entered for default sorting functions, either 'string', 'stringFast', 'number' or 'date'
         * @property {Boolean} [sortReverse=false] Whether to reverse the sort. This is helpful for sorting where a
         * descending order is the default
         */
        const configDefaults = {
            name: null,
            property: null,
            sortFunction: 'string',
            sortReverse: false,
        }

        /**
         * @typedef {Function} SortFunction
         * @property {Object[]} data The data that needs to be sorted
         * @property {Boolean} reverse Whether to reverse the sort
         * @property {String} [property] The property to sort on. Can be ignored
         * @returns {Object[]} The data array sorted. Sorting happens in-place so the array is modified
         */

        /**
         * Sorts the data by property using localeCompare so it can compare strings with non-ASCII symbols
         *
         * @type {SortFunction}
         */
        function sortByPropertyString(data, reverse, property) {
            return data.sort(function (a, b) {
                return (
                    (a[property] || '').localeCompare(b[property]) *
                    (reverse ? -1 : 1)
                )
            })
        }

        /**
         * Sorts the data by using string comparison built in Javascript. Only works for ASCII symbols. Use
         * {@link sortByPropertyString} for fields with non-ASCII characters
         *
         * @type {SortFunction}
         */
        function sortyByPropertyStringFast(data, reverse, property) {
            return data.sort(function (a, b) {
                const valueA = (a[property] || '').toUpperCase()
                const valueB = (b[property] || '').toUpperCase()

                if (valueA < valueB) {
                    return reverse ? 1 : -1
                }

                if (valueA > valueB) {
                    return reverse ? -1 : 1
                }

                return 0
            })
        }

        /**
         * Sorts the data by using number comparison on the given property
         *
         * @type {SortFunction}
         */
        function sortByPropertyNumber(data, reverse, property) {
            return data.sort(function (a, b) {
                if (reverse) {
                    return b[property] - a[property]
                }
                return a[property] - b[property]
            })
        }

        /**
         * Sorts the data by using date comparison on the given property
         *
         * @type {SortFunction}
         */
        function sortByPropertyDate(data, reverse, property) {
            return data.sort(function (a, b) {
                const dateA = new Date(a[property])
                const dateB = new Date(b[property])

                return reverse ? dateB - dateA : dateA - dateB
            })
        }

        /**
         * Returns a sort function based on a string. Function is case insensitive.
         *
         * @param {String} functionName The name of the function
         * @return {SortFunction} Returns the corresponding sorting function
         */
        function getSortFunction(functionName) {
            switch (functionName) {
            case 'stringFast':
                return sortyByPropertyStringFast
            case 'number':
                return sortByPropertyNumber
            case 'date':
                return sortByPropertyDate
            default:
                return sortByPropertyString
            }
        }

        function convertSortFunction(sortConfig) {
            if (typeof sortConfig.sortFunction === 'string') {
                sortConfig.sortFunction = getSortFunction(sortConfig.sortFunction)
            }

            return sortConfig
        }

        this.config = convertSortFunction(angular.merge({}, configDefaults, config))

        /**
         * Sets the reverse state of the sorting item
         *
         * @param {Boolean} reverse The state to set it to
         * @returns {SortItem} Returns this to allow for chaining
         */
        this.setReverse = function (reverse) {
            this.reverse = reverse
            return this
        }

        /**
         * Toggles the reverse state of the sorting item
         *
         * @returns {SortItem} Returns this to allow for chaining
         */
        this.toggleReverse = function () {
            this.setReverse(!this.reverse)
            return this
        }

        /**
         * Checks if the item is in reverse
         *
         * @return {Boolean} Returns true if the item is in reverse, false otherwise
         */
        this.isReverse = function () {
            return this.reverse
        }

        /**
         * Returns an identifier for the sort item
         *
         * @returns {String} The identifier
         */
        this.getIdentifier = function () {
            return this.config.name || this.config.property
        }

        /**
         * Checks if the given identifier corresponds with this sort item
         *
         * @param {String} identifier The identifier to check for
         * @return {Boolean} Returns true if the identifier corresponds with this sort item, false otherwise
         */
        this.identify = function (identifier) {
            return this.getIdentifier() === identifier
        }

        /**
         * Sorts the given data using the settings and sort function
         *
         * @param {Object[]} data The data to sort
         * @returns {Object[]} The data sorted. Sorts the data in place, so data is modified
         */
        this.sort = function (data) {
            return this.config.sortFunction(
                data,
                this.isReverse() ^ this.config.sortReverse,
                this.config.property,
            )
        }

        return this
    }

    this.Controller = SortController
    this.SortItem = SortItem
})
