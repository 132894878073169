import dock from '../../modules/dock'

dock.directive('uiSelectLink', function () {
    return {
        restrict: 'A',
        require: ['uiSelect', '^^dockFilterBarItem'],
        scope: false,
        link(scope, element, attributes, controllers) {
            const [
                uiSelectController,
                dockFilterBarItemController,
            ] = controllers
            dockFilterBarItemController.registerUiSelect(uiSelectController)
        },
    }
})
