import registry from './registry'
import MetaInfoField from './handlers/MetaInfoField'
import MetaInfoBrand from './handlers/MetaInfoBrand'
import { supportedLanguages } from '../../shared/constants/language'

/**
 * Class that can handle the raw request data from meta info fields
 */
export default class MetaInfo {
    constructor(data) {
        this.brands = new Map()
        this.fields = new Map()
        this.restrictedBrands = []
        this.currentUniqueEans = []

        data.metaInfoVelden.forEach((field) => {
            const key = registry.getAlias(field.code) || field.code
            const Handler = registry.getHandler(key) || MetaInfoField
            this.fields.set(key, new Handler(field))
        })

        data.merkInstellingen.forEach((brand) => {
            this.brands.set(brand.merkCode, new MetaInfoBrand(brand))
        })

        data.merkBeperking.forEach((brand) => {
            this.restrictedBrands.push(brand.brandCode)
        })

        this.currentUniqueEans = data.eanInfoVelden.reduce((uniqueEans, currentItem) => {
            if (!uniqueEans.includes(currentItem.ean)) {
                uniqueEans.push(currentItem.ean)
            }
            return uniqueEans
        }, [])

        this.fields.forEach((field) => {
            field.initialize(this)
        })
    }

    /**
     * Returns the a meta info brand
     *
     * @param {String} code The code identifying the brand
     * @return {Object} Returns a meta info brand
     */
    brand(code) {
        if (!this.brandExists(code)) {
            throw new Error('Trying to get a meta info brand that does not exist.')
        }

        return this.brands.get(code)
    }

    /**
     * Checks if a given brand exists
     *
     * @param {String} code The code identifying the brand
     * @return {Boolean} True if the brand exists, false otherwise
     */
    brandExists(code) {
        return this.brands.has(code)
    }

    /**
     * Checks if a given brand is restricted, i.e.: user cannot add new products to this brand
     *
     * @param {String} code The code identifying the brand
     * @return {Boolean} True if the brand is restricted
     */
    brandIsRestricted(code) {
        return this.restrictedBrands.indexOf(code) !== -1
    }

    /**
     * Returns the handler for a given meta info field
     *
     * @param {String} code The code identifying the field. If an alias is registered for a field, only the alias is a
     * valid code
     * @return {MetaInfoField} Returns a meta info handler instance specific for that field
     */
    field(code) {
        if (!this.fieldExists(code)) {
            throw new Error(`Trying to get ${code} as a meta info field handler which does not exist. Maybe it has 
            been registered under an alias?`)
        }

        return this.fields.get(code)
    }

    /**
     * Checks if the given field exists
     *
     * @param {String} code The code identifying the field. If an alias is registered for a field, only the alias is a
     * valid code
     * @return {Boolean} True if the field exists, false otherwise
     */
    fieldExists(code) {
        return this.fields.has(code)
    }
}
