import dock from '../modules/dock'

dock.service('GetPropertyList', /* @ngInject */ function ($q) {
    this.fromWorker = function (data) {
        return $q((resolve) => {
            const worker = new Worker('../workers/getPropertyList.js')

            worker.postMessage(data)

            worker.onmessage = function (message) {
                resolve(JSON.parse(message.data))
            }
        })
    }

    this.loadFixedFilterOptions = function (filters, objects) {
        const filterProperties = filters.map(filter => filter.property)

        filters.forEach((filter) => {
            filter.setLoading(true)
        })

        this.fromWorker({
            objects: JSON.stringify(objects.getData()),
            properties: JSON.stringify(filterProperties),
        }).then((properties) => {
            filters.forEach((filter) => {
                filter.setOptions(properties[filter.property])
                filter.setLoading(false)
            })
        })
    }
})
