import dock from '../../modules/dock'
import checkboxTemplate from './checkbox.html'
import './checkbox.scss'

dock.directive('checkbox', function () {
    return {
        restrict: 'E',
        scope: {
            isChecked: '=',
            white: '=?',
        },
        transclude: true,
        template: checkboxTemplate,
        link(scope, element, attributes) {
            element.addClass('dock-checkbox')
            scope.inline = 'inline' in attributes

            if (scope.inline) {
                element.addClass('inline')
            }
        },
    }
})
