import { Observable } from 'rxjs'
import uniq from 'lodash/uniq'
import difference from 'lodash/difference'

export default class BatchLoader {
    constructor(batchRequest, { batchSize = 20 }) {
        const observable = new Observable((subscriber) => { this.subscriber = subscriber })
        this.subscribe = (subscriber) => observable.subscribe(subscriber)
        this.batchRequest = batchRequest
        this.batchSize = batchSize
        this.queue = []
        this.loaded = []
        this.activeRequest = null
    }

    add(items) {
        const nonLoaded = difference(items, this.loaded)

        this.queue = uniq([
            ...this.queue,
            ...nonLoaded,
        ])

        this.startLoading()
    }

    addPrioritized(items) {
        const nonLoaded = difference(items, this.loaded)

        this.queue = uniq([
            ...nonLoaded,
            ...this.queue,
        ])

        this.startLoading()
    }

    queueEmpty() {
        return this.queue.length === 0
    }

    isLoading() {
        return this.activeRequest !== null
    }

    itemRegistered(item) {
        return this.queue.includes(item) || this.loaded.includes(item)
    }

    remove(items) {
        this.queue = difference(this.queue, items)
        this.loaded = difference(this.loaded, items)
    }

    async startLoading() {
        if (this.isLoading()) {
            return
        }

        while (!this.queueEmpty()) {
            this.activeRequest = this.loadNextBatch()

            // We actually do want to load the batches in sequence, hence:
            // eslint-disable-next-line no-await-in-loop
            const { failed, succeeded } = await this.activeRequest

            // Update this value before notifying subscribers
            if (this.queueEmpty()) {
                this.activeRequest = null
            }

            succeeded.forEach((item) => this.subscriber.next(item))
            failed.forEach((item) => this.subscriber.error(item))
        }
    }

    async loadNextBatch() {
        const batch = this.queue.slice(0, this.batchSize)
        const response = await this.batchRequest(batch)
        this.queue = difference(this.queue, batch)
        this.loaded = [...this.loaded, ...batch]
        return response
    }
}
