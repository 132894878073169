import MetaInfoField from './MetaInfoField'
import registry from '../registry'

const NO_RESTRICTIONS_FIELD = 'maatvoeringtype.geenRestricties'

/**
 * A meta info handler for the dimensioning field. Adds useful functionality
 */
export class DimensioningHandler extends MetaInfoField {
    constructor(metaInfoField) {
        super(metaInfoField)
        this.noRestrictions = null
    }

    /**
     * This function runs after meta info has ran all the meta info field constructors.
     * @param metaInfo
     */
    initialize(metaInfo) {
        if (metaInfo.fieldExists(NO_RESTRICTIONS_FIELD)) {
            this.noRestrictions = metaInfo
                .field(NO_RESTRICTIONS_FIELD)
                .getValues()
                .map(({ code }) => code)
        }
    }

    /**
     * Returns whether the dimensioning has stock restrictions
     * @param {Object} dimensioning The dimensioning to test
     * @return {Boolean} Returns true if the dimensioning has stock restrictions and false otherwise
     */
    hasStockRestrictions(dimensioning) {
        return !dimensioning || !this.noRestrictions.includes(dimensioning.code)
    }
}

registry.registerAlias('maatvoeringtype', 'dimensioning')
export default registry.registerHandler('dimensioning', DimensioningHandler)
