import module from '../../module'
import html from './photoswipe-container.html'
import './photoswipe-container.scss'

/* eslint-disable object-shorthand */
module.directive('photoswipeContainer', function () {
    return {
        restrict: 'E',
        template: html,
        controller: /* @ngInject */ function ($element, PhotoSwipe) {
            PhotoSwipe.registerPhotoSwipeRoot($element)
        },
    }
})
