import angular from 'angular'
import dock from '../../modules/dock'
import stockEditorTemplate from './stockEditor.html'
import showFormFieldError from '../../shared/functions/forms'

dock.directive('stockEditor', () => ({
    restrict: 'E',
    template: stockEditorTemplate,
    scope: {
        sizes: '=',
        pricePerSize: '<',
        basePrice: '<',
        columns: '<',
        showSku: '<',
        allowEanEdits: '<',
        eanRequired: '<',
        currentUniqueEans: '<',
        product: '<?',
        productBrandName: '<',
        valid: '=?',
        allowPriceEdits: '<',
        hasMsrp: '<',
        disabled: '<',
    },
    controller: /* @ngInject */ function ($scope) {
        $scope.showError = showFormFieldError
        $scope.getSize = getSize
        $scope.items = []
        $scope.showSku = $scope.showSku ?? false
        $scope.allowEanEdits = $scope.allowEanEdits ?? false
        $scope.eanRequired = $scope.eanRequired ?? true
        $scope.currentUniqueEans = $scope.currentUniqueEans ?? []

        $scope.$watch('columns', calculateItems)
        $scope.$watch(() => $scope.sizes && $scope.sizes.map(size => size.maat.code), calculateItems, true)

        $scope.$watch(
            () => $scope.stockEditorForm.$valid,
            () => $scope.valid = $scope.stockEditorForm.$valid,
        )

        function calculateItems() {
            const columns = $scope.columns ?? 1
            const rows = $scope.sizes ? Math.ceil($scope.sizes.length / columns) : 0

            const items = []

            for (let row = 0; row < rows; row++) {
                items[row] = []

                for (let column = 0; column < columns; column++) {
                    const index = row * columns + column
                    items[row][column] = $scope.sizes[index] ?? null
                }
            }

            $scope.items = items
        }

        function getSize(row, column) {
            const index = row * $scope.columns + column

            if (index === $scope.sizes.length) {
                return null
            }

            return $scope.sizes[index]
        }
    },
}))
