import dock from '../modules/dock'
import '../services/Update'

dock.run(/* @ngInject */ function ($rootScope, UpdateService) {
    $rootScope.update = {
        updating: true,
        updateError: null,
    }

    UpdateService.welcome()
    UpdateService.update()
        .catch((error) => {
            $rootScope.update.updateError = error
        })
        .finally(() => {
            $rootScope.update.updating = false
            $rootScope.version = UpdateService.getVersion()
        })
})
