/**
 * A registry that is used to register handlers and aliases for specific meta info fields
 */
export default class MetaInfoRegistry {
    constructor() {
        this.handlers = new Map()
        this.aliases = new Map()
        this.translatable = new Set()
    }

    /**
     * Registers a handler for the given code
     *
     * @param {String} code The code that identifies the field the handler should be registered to. If an alias is
     * registered, only the alias is a valid code
     * @param {MetaInfoField} handler A class extending the MetaInfoField class
     * @return {String} Returns the code for easy exporting
     */
    registerHandler(code, handler) {
        this.handlers.set(code, handler)

        return code
    }

    /**
     * Registers an alias for the given code
     *
     * @param {String} code The code that identifies the meta info field
     * @param {String} alias The new code that should identify the meta info field
     */
    registerAlias(code, alias) {
        this.aliases.set(code, alias)
    }

    /**
     * Registers a meta info field code as translatable
     *
     * @param {String} code The meta info field code that needs to be registered as translatable.
     * If you registered an alias, this should be the alias
     */
    registerTranslatable(code) {
        this.translatable.add(code)
    }

    /**
     * Gets the handler for a given code
     *
     * @param {String} code The code that identifies the field the handler is registered to. If an alias is
     * registered, only the alias is a valid code
     * @return {MetaInfoField|undefined} Returns the handler for the given code. If no handler is found it returns null
     */
    getHandler(code) {
        return this.handlers.get(code)
    }

    /**
     * Gets the alias for a given code
     *
     * @param {String} code The code that identifies the field the alias is registered to
     * @return {String|undefined} Returns the alias for the given code. If no alias is found it returns null
     */
    getAlias(code) {
        return this.aliases.get(code)
    }

    /**
     * Returns a set of all fields codes registered as translatable
     *
     * @return {Set<String>}
     */
    getTranslatableFields() {
        return this.translatable
    }
}
