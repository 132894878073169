import angular from 'angular'
import Papa from 'papaparse'
import FileSaver from 'file-saver'
import dock from '../modules/dock'
import delimiterField from '../factories/metaInfo/fields/delimiterField'
import '../directives/uploadOverlay/uploadOverlay'
import '../directives/uploadStep/uploadStep'
import '../directives/dockFilterBar/dockFilterBarItem'

/* eslint-disable */
// Disable error on unhandled rejection errors for ngFileUpload while waiting for
// https://github.com/danialfarid/ng-file-upload/issues/1911
angular.module('ngFileUpload').config( /* @ngInject */function($qProvider) {
    $qProvider.errorOnUnhandledRejections(false)
})

const controllerName = 'ImportCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    $timeout,
    $translate,
    $q,
    $rootScope,
    api,
    metaInfo,
    commonFilters,
    Filters,
) {
    const STATE = {
        IDLE: 'idle',
        DROPPING: 'dropping',
        UPLOADING: 'uploading',
        VALIDATING: 'validating',
        IMPORTING: 'importing',
        FINISHED: 'finished',
        INVALID: 'invalid',
        PARSE_ERROR: 'parse error',
        VALIDATION_ERROR_SOME: 'validation error some',
        VALIDATION_ERROR_ALL: 'validation error all',
    }

    $scope.STATE = STATE

    const csvDefaults = {
        headers: [],
        errorCount: -1,
        errors: [],
        downloadable: '',
        dataLinesCount: -1,
        erroneousLinesCount: -1,
        successfulLinesCount: -1,
    }

    const parseErrorLineState = -1
    const parseErrorColumnState = -1

    $scope.csv = angular.copy(csvDefaults)

    $rootScope.$on('$translateChangeSuccess', function() {
        generateCsvErrorStrings()
    })

    $scope.steps = [
        STATE.UPLOADING,
        /* STATE.VALIDATING, */ STATE.IMPORTING,
        STATE.FINISHED,
    ]
    $scope.delimiterFilter = getDelimiterFilter()

    $scope.cancelUpload = angular.noop

    $scope.uploadSettings = {
        acceptedFormats: '.csv',
    }

    const uploadDefaults = {
        uploadPercentage: -1,
        validationPercentage: -1,
        importPercentage: -1,
        file: null,
        error: null,
        successfulUploads: null,
    }

    $scope.upload = Object.assign(
        {
            state: STATE.IDLE,
        },
        uploadDefaults,
    )

    $scope.changeState = function(state) {
        if (state === STATE.IDLE) {
            // Wait for the transitions when someone changes to idle
            $timeout(() => {
                $scope.upload = Object.assign($scope.upload, uploadDefaults)
            }, 300)
        }

        $scope.upload.state = state
    }

    $scope.controlDropState = function(isDragging) {
        if (
            isDragging &&
            [STATE.IDLE, STATE.INVALID].includes($scope.upload.state)
        ) {
            $scope.upload.state = STATE.DROPPING
        } else if (!isDragging && $scope.upload.state === STATE.DROPPING) {
            $scope.upload.state = STATE.IDLE
        }

        $scope.$apply()
    }

    $scope.validateSelectedFile = function(files, file) {
        if (files.length === 1) {
            $scope.upload.state = STATE.UPLOADING
            uploadFile(file, $scope.delimiterFilter.getSelected(true, true))
        } else {
            $scope.upload.state = STATE.INVALID
        }
    }

    $scope.downloadErrorCsv = function() {
        FileSaver.saveAs($scope.csv.downloadable, $scope.upload.file.name)
    }

    let uploadFile = ($scope.uploadFiles = function(file, delimiter) {
        $scope.upload = Object.assign($scope.upload, uploadDefaults, {
            uploadPercentage: 0,
        })

        $scope.csv = angular.copy(csvDefaults)

        const uploadRequest = api.import.upload(file, delimiter)

        uploadRequest.then(response => {
            const data = response.data

            if (data.errorCount > 0) {
                setCsvInfo(response.data)
                $scope.changeState(
                    ($scope.csv.dataLinesCount > $scope.csv.erroneousLinesCount)
                        ? STATE.VALIDATION_ERROR_SOME
                        : STATE.VALIDATION_ERROR_ALL
                )
            } else {
                $scope.upload.importPercentage = 0
                $scope.upload.successfulUploads = data.successfulUploads
                $scope.changeState(STATE.FINISHED)

                $scope.cancelUpload = function() {
                    $scope.changeState(STATE.IDLE)
                }
            }
        })

        uploadRequest.progress(updateUploadProgress)

        uploadRequest
            .catch(response => {
                switch (response.status) {
                    case 422:
                        setCsvInfo(response.data)
                        $scope.changeState(
                            ($scope.csv.dataLinesCount > $scope.csv.erroneousLinesCount)
                            ? STATE.VALIDATION_ERROR_SOME
                            : STATE.VALIDATION_ERROR_ALL
                        )
                        break
                    default:
                        $scope.changeState(STATE.PARSE_ERROR)
                        generateParseErrors(response.data)
                        break
                }

                $scope.upload.error = response.data
            })
            .catch(error => {
                console.error(
                    'Something went wrong while generating the error CSV',
                    error,
                )
            })

        $scope.cancelUpload = uploadRequest.abort
    })

    function updateUploadProgress(event) {
        $scope.upload.uploadPercentage = parseInt(
            (100.0 * event.loaded) / event.total,
        )

        // event.config.data.File is a blob, which makes it unaccessable in the templates. Therefore we copy important
        // attributes.
        $scope.upload.file = {
            name: event.config.data.File.name,
        }

        if (
            $scope.upload.uploadPercentage === 100 &&
            $scope.upload.state === STATE.UPLOADING
        ) {
            $scope.changeState(STATE.IMPORTING)
            $scope.upload.importPercentage = 1
        }
    }

    function generateParseErrors(data) {
        $scope.csv.errorCount = data.errorCount
        $scope.csv._rawErrors = data.errors
        $scope.csv.errors = getErrorObjects(data.errors, $scope.csv)
        $scope.csv.errors.forEach(error => error.stringify())
    }

    function setCsvInfo(data) {
        $scope.csv.headers = data.headers
        $scope.csv.errorCount = data.errorCount
        $scope.csv._rawErrors = data.errors
        $scope.csv._rawData = data.csvData
        $scope.csv.errors = getErrorObjects(data.errors, $scope.csv)
        $scope.csv.dataLinesCount = data.csvData.length
        $scope.csv.erroneousLinesCount = $scope.csv.errors.length
        $scope.csv.successfulLinesCount = data.csvData.length - $scope.csv.errors.length

        generateCsvErrorStrings().then(() => {
            const errorCSV = generateErrorCsv()

            $scope.csv.downloadable = new Blob([errorCSV], {
                type: 'text/csv;charset=utf-8',
            })
        })
    }

    function getDelimiterFilter() {
        const delimiterFilterConfig = {
            options: {
                fixed: true,
                displayProperty: 'naam',
                filterProperty: 'code',
            },
            search: false,
            allowClear: false,
            savePreference: false,
        }

        const delimiterFilter = new Filters.Filter(
            'delimiter',
            'import.filters.delimiter',
            'delimiter',
            [],
            delimiterFilterConfig,
        )
        commonFilters
            .loadMetaInfoOptions(delimiterFilter, {
                metaInfoField: delimiterField,
            })
            .then(options =>
                delimiterFilter.setSelected(
                    options.find(option => option.code === 'auto'),
                ),
            )

        return delimiterFilter
    }

    const errorsHeader = 'Errors'
    function generateErrorCsv() {
        const errorColumnIndex = $scope.csv.headers.indexOf(errorsHeader)

        // If found remove errors column in headers and data
        if (errorColumnIndex !== parseErrorColumnState) {
            $scope.csv.headers.splice(errorColumnIndex, 1)

            $scope.csv._rawData.forEach(line => {
                line.splice(errorColumnIndex, 1)
            })
        }

        // Construct data with only the erroneous lines and added error
        const rawDataWithErrors = $scope.csv._rawData.reduce((acc, line, index) => {
            const lineError = $scope.csv.errors.find(error => {
                return error.line === index + 1
            })

            if (lineError !== undefined) {
                line.push(lineError.humanizedWithoutLine)
                acc.push(line);
            }

            return acc
        }, [])

        const headers = $scope.csv.headers

        headers.push(errorsHeader)

        return Papa.unparse({
            fields: headers,
            data: rawDataWithErrors,
        })
    }

    function generateCsvErrorStrings() {
        return Promise.all(
            $scope.csv.errors.map(line => {
                const promise = line.stringify()

                promise.catch(error => {
                    console.error(`Error for line ${line.line}:`, error)
                })

                return promise
            }),
        )
    }

    function getErrorObjects(origErrors, csv) {
        const errors = angular.copy(origErrors)
        const errorsPerLineObject = {}

        errors.forEach(error => {
            const errorsForLine = errorsPerLineObject[error.line]

            if (errorsForLine) {
                errorsForLine.errors.push(error)
            } else {
                errorsPerLineObject[error.line] = {
                    errors: [error],
                }
            }
        })

        return Object.keys(errorsPerLineObject).map(key => {
            const errors = errorsPerLineObject[key].errors

            const columnErrors = errors.map(error => {
                const columnName =
                    error.line === parseErrorLineState
                        ? null
                        : csv.headers[error.column]
                const column = new Column(error.column, columnName)

                const validators = error.validators.map(
                    validator =>
                        new Validator(validator.name, validator.parameters),
                )

                return new ColumnError(column, validators)
            })

            // We need to parse the key since keys are returned as a string
            return new LineError(parseInt(key), columnErrors)
        })
    }

    function combineStringsCommaAnd(strings, and) {
        const comma = ', '
        if (strings.length < 2) {
            return strings.join()
        }
        const lastBit = strings.splice(-2).join(and)
        const firstBit = strings.length === 0 ? '' : strings.join(comma) + comma

        return firstBit + lastBit
    }

    const validationTranslate = 'validation'
    class LineError {
        /**
         * @constructor
         * @param {Number} line
         * @param {ColumnError[]} errors
         */
        constructor(line, errors) {
            this.line = line
            this.errors = errors
            this.humanized = ''
            this.humanizedWithoutLine = ''
        }

        stringify() {
            const self = this
            const promises = this.errors.map(errors => errors.stringify())

            promises.push(
                $translate(`${validationTranslate}.line`, { line: this.line }),
            )
            promises.push($translate(`${validationTranslate}.thisFile`))
            promises.push($translate(`${validationTranslate}.and`))

            return $q
                .all(promises)
                .then(translationStrings => {
                    const and = ` ${translationStrings.pop()} `
                    const thisFile = translationStrings.pop()
                    const line = translationStrings.pop()

                    const stringWithoutLine = `${combineStringsCommaAnd(
                        translationStrings,
                        and,
                    )}.`
                    const fileOrLine =
                        this.line === parseErrorLineState ? thisFile : line
                    const string = `${fileOrLine} ${stringWithoutLine}`

                    self.humanizedWithoutLine = capitalizeFirstLetter(
                        stringWithoutLine,
                    )
                    self.humanized = string

                    return string
                })
                .catch(error => {
                    const errorMessage = `Error while generating validation translation. Translation key: ${error}`
                    console.error(errorMessage)
                    self.humanized = errorMessage
                })
        }

        getErrorString() {
            return this.humanized
        }
    }

    class ColumnError {
        /**
         * @constructor
         * @param {Column} column
         * @param {Validator[]} validators
         */
        constructor(column, validators) {
            this.column = column
            this.validators = validators
        }

        stringify() {
            const promises = this.validators.map(validator =>
                validator.stringify(),
            )

            promises.push(
                $translate(`${validationTranslate}.column`, this.column),
            )
            promises.push($translate(`${validationTranslate}.thisProduct`))
            promises.push($translate(`${validationTranslate}.and`))

            return $q.all(promises).then(translationStrings => {
                const and = ` ${translationStrings.pop()} `
                const thisProduct = translationStrings.pop()
                let column = translationStrings.pop()

                column = this.column.name ? column : thisProduct
                const combinedStrings = combineStringsCommaAnd(
                    translationStrings,
                    and,
                )
                return (
                    (this.column.number === parseErrorColumnState
                        ? ''
                        : `${column} `) + combinedStrings
                )
            })
        }
    }

    class Column {
        /**
         * @constructor
         * @param {Number} number
         * @param {String} name
         */
        constructor(number, name) {
            this.number = number
            this.name = name
        }
    }

    const translationPrefix = `${validationTranslate}.validator`
    class Validator {
        /**
         * @constructor
         * @param {String} name
         * @param {Object} parameters
         */
        constructor(name, parameters) {
            this.name = name
            this.parameters = parameters.map(this._parseRegexParameters)
        }

        stringify() {
            const parameters = this._parametersAsObject()

            return $translate(`${validationTranslate}.or`).then(or => {
                or = ` ${or} `
                Object.keys(parameters).forEach(key => {
                    if (Array.isArray(parameters[key])) {
                        parameters[key] = combineStringsCommaAnd(
                            parameters[key],
                            or,
                        )
                    }
                })

                return $translate(
                    `${translationPrefix}.${this.name}`,
                    parameters,
                )
            })
        }

        _parametersAsObject() {
            const parameters = {}

            this.parameters.forEach(parameter => {
                if (parameters[parameter.key]) {
                    if (!Array.isArray(parameters[parameter.key])) {
                        parameters[parameter.key] = [parameters[parameter.key]]
                    }

                    parameters[parameter.key].push(parameter.value)
                } else {
                    parameters[parameter.key] = parameter.value
                }
            })

            return parameters
        }

        _parseRegexParameters(parameter) {
            if (parameter.key.includes('regex')) {
                parameter.value = parameter.value.replace('\\', '')
            }

            return parameter
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    $scope.$on('$destroy', () => $scope.cancelUpload())
})
