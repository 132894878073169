import dock from '../../modules/dock'
import inputCounterTemplate from './inputCounter.html'
import './inputCounter.scss'

dock.directive('inputCounter', function () {
    return {
        restrict: 'E',
        template: inputCounterTemplate,
        scope: {
            model: '=',
            min: '@',
            max: '@',
        },
    }
})
