import dock from '../../modules/dock'
import JsonTranslationsLoader from './JsonTranslationsLoader'
import MetaInfoTranslationsLoader from './MetaInfoTranslationsLoader'

const onTranslationsCallbacks = []

function combineTranslations(translations) {
    return Object.assign({}, ...translations)
}

export default function onTranslationsLoaded(callback) {
    onTranslationsCallbacks.push(callback)
}

dock.factory('translateRaceLoader', /* @ngInject */ function (
    $q,
    Retailer,
    metaInfo,
) {
    const jsonTranslationsLoader = new JsonTranslationsLoader()
    const metaInfoTranslationsLoader = new MetaInfoTranslationsLoader(metaInfo, Retailer)

    return function (options) {

        const loaders = [jsonTranslationsLoader]
        const language = options.key

        if (Retailer.isLoggedIn()) {
            loaders.push(metaInfoTranslationsLoader)
        }

        // Get the loaders that have cached translations and loaders that haven't been cached and aren't pending
        const cachedLoaders = loaders.filter(loader => loader.isCached(language))
        const freshLoaders = loaders.filter(loader => !loader.isCached(language) && !loader.isPending(language))

        // Get the promises for the loaders that haven't been cached and aren't pending
        const freshPromises = freshLoaders.map(loader => loader.load(language))

        // When a promise resolves, call the on translations callbacks
        freshPromises
            .forEach(promise => promise
                .then(() => onTranslationsCallbacks
                    .forEach(callback => callback())))

        const cachedTranslations = cachedLoaders.map(loader => loader.getCached(language))

        return $q.resolve(combineTranslations(cachedTranslations))
    }
})
