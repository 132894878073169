import dock from '../../modules/dock'

dock.directive('endsWithPeriod', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            ngModel.$validators.endsWithPeriod = function (modelValue) {
                return !modelValue || modelValue.trim().endsWith('.')
            }
        },
    }
})
