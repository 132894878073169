/**
 * A class that adds features to each meta info brand
 */
export default class MetaInfoBrand {
    constructor(brand) {
        this._originalProperties = brand
        this.code = this._originalProperties.merkCode
        this.optionalEan = this._originalProperties.optioneleEAN
    }

    /**
     * Checks if the EAN field is optional for this brand
     *
     * @return {Boolean} True if the EAN field is optional, false otherwise
     */
    eanOptional() {
        return this.optionalEan
    }
}
