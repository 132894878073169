import dock from '../../modules/dock'

dock.directive('cbSwitch', function () {
    return {
        replace: false,
        restrict: 'EA',
        link(scope, elm) {
            elm.after('<div><div></div></div>').addClass('switch tinyswitch green')
        },
    }
})
