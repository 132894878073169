import dock from '../modules/dock'

dock.service('ExpandedOrders', /* @ngInject */ function ($location) {
    this.keyString = 'expandedOrder'

    /**
     * Function that returns an array of all expanded orders according to the route params
     * @returns {String[]} The order ID's of the expanded orders
     */
    this.get = function () {
        const expandedOrders = $location.search()[this.keyString]

        if (Array.isArray(expandedOrders)) {
            return expandedOrders
        } else if (typeof expandedOrders === 'string') {
            return [expandedOrders]
        }
        return []
    }

    /**
     * Adds an order number to the parameter
     *
     * @param {String} orderNumber the order number to add
     */
    this.add = function (orderNumber) {
        const expandedOrders = this.get()

        if (!this.isExpanded(orderNumber)) {
            expandedOrders.push(orderNumber)
            this.set(expandedOrders)
        }
    }

    /**
     * Removes an order number from the parameter
     *
     * @param {String} orderNumber the order number to remove
     */
    this.remove = function (orderNumber) {
        const expandedOrders = this.get()

        if (expandedOrders) {
            const index = expandedOrders.indexOf(orderNumber)

            if (index !== -1) {
                expandedOrders.splice(index, 1)
                this.set(expandedOrders)
            }
        }
    }

    /**
     * Sets the route expanded orders parameter to the given value
     *
     * @param {String[]} orderNumbers The order numbers that are expanded
     */
    this.set = function (orderNumbers) {
        $location.search(this.keyString, orderNumbers)
    }

    /**
     * Returns whether the order with the corresponding order number is expanded
     *
     * @param {String} orderNumber The order number to check
     * @returns Boolean True if it is expanded, false if otherwise
     */
    this.isExpanded = function (orderNumber) {
        return this.get().indexOf(orderNumber) !== -1
    }
})
