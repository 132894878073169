import '../shared/updates'
import dock from '../modules/dock'
import { currentVersion, versions } from '../shared/constants/versions'
import Version from '../shared/classes/Version'
import updateRegistry from '../shared/instances/updateRegistry'

dock.service('UpdateService', function () {
    const versionStorageKey = 'registered_version'
    const self = this

    this.promise = null
    this.updated = false

    function _getVersionString(version) {
        return `You are using version ${version.toString()} of the DOCK front-end.`
    }

    function _getStoredVersionString(version) {
        return version === null
            ? 'No version of the DOCK front-end was registered as last used.'
            : `Version ${version.toString()} of the DOCK front-end was registered as last used.`
    }

    async function _executeUpdates(updateVersions) {
        // for...of loop is actually more readable here
        // eslint-disable-next-line no-restricted-syntax
        for (const updateVersion of updateVersions) {
            try {
                // We actually need to do the updates in sequence, not all at once, so the rule does
                // not apply here.
                // eslint-disable-next-line no-await-in-loop
                await updateRegistry.getUpdate(updateVersion).doUpdate()
                self.setStoredVersion(updateVersion)
                console.log(`Successfully updated to version ${updateVersion.toString()}`)
            } catch (error) {
                console.error(`Error updating to version ${updateVersion}`)
                throw error
            }
        }
    }

    /**
     * Returns the current version
     *
     * @return {Version} The current version
     */
    this.getVersion = function () {
        return currentVersion
    }

    /**
     * Logs the version you're using in the console
     */
    this.welcome = function () {
        this.logVersion(currentVersion)
    }

    /**
     * Executes updates if they are found
     * @return {Promise} Returns a promise that is resolved when the updates are complete and rejected when an error
     * occurs while updating
     */
    this.update = function () {
        if (this.promise !== null) {
            return this.promise
        }

        const storedVersion = this.getStoredVersion()
        this.logStoredVersion(storedVersion)
        let versionsToUpdate = versions.slice()

        if (storedVersion !== null) {
            if (storedVersion.equals(currentVersion)) {
                console.info('Stored version equals this version, so no updates are required.')
                this.promise = Promise.resolve()
                this.updated = true
                return this.promise
            }

            const versionsIndex = versionsToUpdate.findIndex(version =>
                storedVersion.equals(version))

            if (versionsIndex > -1) {
                versionsToUpdate = versionsToUpdate.slice(versionsIndex + 1)
            }
        }

        this.promise = _executeUpdates(versionsToUpdate)
            .then(() => {
                console.log(`All updates were successfully executed, you're now at the current version (version ${currentVersion}).`)
                this.updated = true
            })
            .catch((error) => {
                console.error(
                    'Something went wrong while updating DOCK. Please contact Partner Support and mention the following error:',
                    error,
                )
                throw error
            })

        return this.promise
    }

    /**
     * Retrieves the version stored in the local storage.
     *
     * @return {Version|null} The stored version. If no version was stored or the version couldn't be parsed it returns
     * null.
     */
    this.getStoredVersion = function () {
        const storageVersion = localStorage.getItem(versionStorageKey)

        if (storageVersion === null) {
            return null
        }

        try {
            return new Version(storageVersion)
        } catch (error) {
            console.warn(
                'Unable to read version in storage, assuming no version stored. Error:',
                error,
            )
            return null
        }
    }

    /**
     * Sets version stored in the local storage.
     *
     * @param {Version} version The version to store.
     */
    this.setStoredVersion = function (version) {
        localStorage.setItem(versionStorageKey, version.toString())
    }

    /**
     * Logs a given version as the current version.
     *
     * @param {Version} version The version to log
     */
    this.logVersion = function (version) {
        console.info(_getVersionString(version))
    }

    /**
     * Logs a given version as the stored version.
     *
     * @param {Version} version The version to log
     */
    this.logStoredVersion = function (version) {
        console.info(_getStoredVersionString(version))
    }
})
