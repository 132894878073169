import dock from '../../modules/dock'

const _digitsRegex = /^[0-9]*$/

dock.directive('digitsOnly', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            ngModel.$validators.digits = function (modelValue) {
                return !modelValue || _digitsRegex.test(modelValue)
            }
        },
    }
})
