export default class TranslationsLoader {
    constructor() {
        this.cache = new Map()
        this.pendingCache = new Map()
    }

    getCachedOrPending(language) {
        if (this.isCached(language)) {
            return Promise.resolve(this.getCached(language))
        }

        if (this.isPending(language)) {
            return this.getPending(language)
        }

        return null
    }

    isCached(language) {
        return this.cache.has(language)
    }

    isPending(language) {
        return this.pendingCache.has(language)
    }

    addCached(language, translations) {
        this.cache.set(language, translations)
    }

    getCached(language) {
        return this.cache.get(language)
    }

    addPending(language, promise) {
        this.pendingCache.set(language, promise)
        promise.then(() => { this.removePending(language) })
    }

    getPending(language) {
        return this.pendingCache.get(language)
    }

    removePending(language) {
        this.pendingCache.delete(language)
    }

    handleCaching(language, promise) {
        this.addPending(language, promise)
        promise.then(translations => this.addCached(language, translations))
    }
}

