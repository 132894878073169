import dock from '../../modules/dock'

dock.directive('cannotContainDoubleSpaces', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link(scope, element, attributes, ngModel) {
            ngModel.$validators.cannotContainDoubleSpaces = function (modelValue) {
                return !modelValue || !modelValue.includes('  ')
            }
        },
    }
})
