import dock from '../../modules/dock'
import productsEditorTemplate from './productsEditor.html'
import '../dockLoadingMessage/dockLoadingMessage'
import './productEditor'

/* eslint-disable */
dock.directive('productsEditor', function () {
    return {
        restrict: 'E',
        template: productsEditorTemplate,
        scope: {
            productIds: '=',
            productSizeIds: '=',
            controller: '=',
        },
        controller: /* @ngInject */ function ($scope, $q, Data, api) {
            const getChangedVariationsRegister = []

            const STATE = ($scope.STATE = {
                INITIAL: 'initial',
                LOADING: 'loading',
                LOADING_ERROR: 'loadingError',
                READY: 'ready',
                PROCESSING: 'processing',
                PROCESSED: 'processed',
                PROCESS_ERROR: 'processError',
            })

            $scope.state = STATE.INITIAL
            $scope.loadProducts = loadProducts
            $scope.saveChanges = saveChanges

            const filterController = new Data.Filters.Controller()
            const paginationController = new Data.Pagination.Controller()
            const selectionController = new Data.Selection.Controller()
            const sortingController = new Data.Sorting.Controller()

            $scope.products = new Data.DataController(
                filterController,
                paginationController,
                selectionController,
                sortingController,
            )
            $scope.requestCanceler = null

            // Registers the save changes handler to the controller
            $scope.controller.registerOnSaveHandler(saveChanges)

            loadProducts($scope.productIds)

            /**
             * Handles the get products request
             *
             * @param {String[]} productIds The id's of the products you want to load
             */
            function loadProducts(productIds) {
                $scope.state = STATE.LOADING
                $scope.requestCanceler = $q.defer()

                getProductsRequest(productIds, $scope.requestCanceler)
                    .then(responses => {
                        const products = responses.map(response => {
                            const product = response.data

                            product.variaties = product.variaties.filter(
                                variation =>
                                    $scope.productSizeIds.includes(
                                        variation.id,
                                    ),
                            )

                            return product
                        })

                        $scope.state = STATE.READY
                        $scope.products
                            .setData(products)
                            .filter()
                            .sort()
                    })
                    .catch(error => {
                        $scope.state = STATE.LOADING_ERROR
                    })
            }

            /**
             * Loads all given products and returns the request promise
             *
             * @param {String[]} productIds The id's of the products you want to load
             * @return {Promise} Returns a promise that resolves when all products are loaded
             */
            function getProductsRequest(productIds, requestCanceler) {
                return $q.all(
                    productIds.map(productId => {
                        const product = {
                            id: productId,
                        }

                        return api.products.getProductStock(
                            product,
                            requestCanceler,
                        )
                    }),
                )
            }

            /**
             * Registers a callback to the getChangedVariations registry. This allows this directive to check for each
             * of its children whether one of the product's variations has been changed. The children register
             * their handler here
             *
             * @param {Function} handler The handler to call when this directive needs to get a child's changed
             * variations
             */
            function registerGetChangedVariations(handler) {
                getChangedVariationsRegister.push(handler)
            }

            /**
             * Saves the changes (products or variations) for all of the children products
             *
             * @return {Promise} Returns a promise when all changed products and variations have been saved
             */
            function saveChanges() {
                const changedVariations = getChangedVariations()

                const promises = changedVariations.map(variant =>
                    api.products.updateVariant(
                        variant.productId,
                        variant.id,
                        variant.getRequestVariant(),
                    ),
                )

                return $q.all(promises)
            }

            /**
             * Calls each registerd changed variations product handler to generate an array of changed variations
             *
             * @return {Object[]} A list of changed variations. Returns an empty array if no changed variation was found
             */
            function getChangedVariations() {
                return getChangedVariationsRegister
                    .map(getChangedVariationsFunction =>
                        getChangedVariationsFunction(),
                    )
                    .reduce(
                        (changedVariations, changedVariationsArray) =>
                            changedVariations.concat(changedVariationsArray),
                        [],
                    )
            }

            return {
                registerGetChangedVariations,
            }
        },
    }
})
