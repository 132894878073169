import dock from '../../modules/dock'
import productPriceTemplate from './productPrice.html'
import './productPrice.scss'

dock.directive('productPrice', function () {
    return {
        restrict: 'E',
        scope: {
            product: '<',
        },
        template: productPriceTemplate,
        link(scope) {
            scope.$watch(
                () => scope.product.getPrice(),
                () => { scope.price = scope.product.getPrice() },
                true,
            )
        },
    }
})
