import '../../factories/api'
import dock from '../../modules/dock'
import showFormFieldError from '../../shared/functions/forms'
import getMessagesFromObject from '../../shared/functions/getMessagesFromObject'

/* eslint-disable */
// The possible deficiency form states
export const DeficiencyFormState = Object.freeze({
    IDLE: Symbol('idle'), // The form is ready to be filled in
    PROCESSING: Symbol('processing'), // The deficiency is being processed
    PROCESS_ERROR: Symbol('process_error'), // An error occurred while processing the deficiency
})
const controllerName = 'DeficiencyCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    $q,
    $translate,
    api,
    $uibModalInstance,
    Retailer,
    item,
    toastr,
) {
    $scope.order = item
    $scope.showError = showFormFieldError
    $scope.State = DeficiencyFormState
    $scope.state = DeficiencyFormState.IDLE
    $scope.requestCanceler = null

    $scope.deficiency = {
        retailerEmail: Retailer.info.email,
    }

    $scope.processDeficiency = function(deficiencyForm) {
        if (deficiencyForm.$invalid) {
            return
        }

        $scope.requestCanceler = $q.defer()
        $scope.state = DeficiencyFormState.PROCESSING

        const processRequests = [];

        // Register order item as deficient
        const deficiencyRequest = api.orders.deficient(
            $scope.order,
            $scope.deficiency,
            $scope.requestCanceler,
        )
        processRequests.push(deficiencyRequest);

        if (!$scope.order.product.fromContentApi()) {
            // Get the product variation and update its stock to 0
            const stockRequest = api.products
                .getVariant(
                    $scope.order.product.id,
                    $scope.order.product.variantId,
                    $scope.requestCanceler,
                )
                .then(({ data }) => {
                    data.voorraad = 0
                    return data
                })
                .then(variant =>
                    api.products.updateVariant(
                        $scope.order.product.id,
                        $scope.order.product.variantId,
                        variant,
                        $scope.requestCanceler,
                    ),
                )
            processRequests.push(stockRequest);
        }

        // Wait until both requests are finished
        $q.all(processRequests)
            .then(responses => {
                const deficiencyResponse = responses[0]

                if (
                    deficiencyResponse.data &&
                    deficiencyResponse.data.ok === 'OK'
                ) {
                    $scope.close()

                    const titleRequest = $translate(
                        'overlay.deficient.success.title',
                    )
                    const messageRequest = $translate(
                        'overlay.deficient.success.message',
                    )

                    $q.all([titleRequest, messageRequest]).then(
                        ([title, message]) => {
                            toastr.success(message, title)
                        },
                    )
                }
            })
            .catch(response => {
                $scope.state = DeficiencyFormState.PROCESS_ERROR

                const errorData = response.data
                console.error(errorData)
                $scope.errorMessages = getMessagesFromObject(errorData)
            })
    }

    $scope.close = function() {
        $uibModalInstance.close()
    }

    $scope.$on('$destroy', () => $scope.requestCanceler ? $scope.requestCanceler.resolve() : false)
})
