import angular from 'angular'
import dock from '../modules/dock'

dock.service('Selection', /* @ngInject */ function ($location) {
    /**
     * Controller for the selection of data
     *
     * @class
     * @param {SelectionControllerConfig} [config] The config for the selection controller
     * @param {MassAction[]} [massActions] The mass actions to register
     * @constructor
     */
    function SelectionController(config, massActions) {
        this.data = null
        this.selectable = null
        this.selected = []
        this.massActions = massActions || []
        const self = this

        /**
         * @typedef {Object} SelectionControllerConfig
         * @type {Object}
         * @property {String} identifier The identifier key for each selectable item for persistence
         * @property {String} [itemTranslationKey='selection.itemGeneric'] The translation key to use for one or
         * @property {Function} [filter = () => true] A function that is used to filter which items are selectable and which aren't
         * more selected items
         */
        const configDefaults = {
            identifier: null,
            itemTranslationKey: 'selection.itemGeneric',
            filter: () => true,
        }

        this.config = angular.extend({}, configDefaults, config)

        /**
         * Sets the data that is visible for selection
         *
         * @param {Object[]} data What data to set it to. If no array is given the data will be reset
         * @returns {SelectionController} Returns this to allow for chaining
         */
        this.setData = function (data) {
            if (Array.isArray(data)) {
                const filtered = data.filter(this.config.filter)
                const savedSelection = this.getStorage() || []

                this.select(filtered.filter(item => savedSelection.includes(item[this.config.identifier])))
                this.data = filtered
            } else {
                this.data = null
            }

            this._updateAllSelected()
            this.setSelectable(this.data)
            return this
        }

        /**
         * Sets the data that is selectable
         *
         * @param {Object[]} data What data to set it to
         * @returns {SelectionController} Returns this to allow for chaining
         */
        this.setSelectable = function (data) {
            if (!Array.isArray(data)) {
                this.data = null
                this.selectable = null
                this.selected = []
                this._updateAllSelected()
                return this
            }

            // Make sure that already selected items stay selected if they are part of the new data
            this.selected = this.selected.filter(function (item) {
                return data.includes(item)
            })

            this.selectable = data
            this.data = this.selectable.slice()
            this._updateAllSelected()
            return this
        }

        /**
         * Selects a certain item or multiple items
         *
         * @param {Object|Object[]} items The item(s) to select
         * @param {Boolean} [resetAllSelected=true] Whether to reset the allSelected value
         * @returns {SelectionController} Returns this to allow for chaining
         */
        this.select = function (items, resetAllSelected) {
            if (Array.isArray(items)) {
                items.forEach(function (item) {
                    self.select(item, false)
                })

                if (resetAllSelected !== false) {
                    this._updateAllSelected()
                }

                return this
            }

            const item = items

            if (
                this.selectable.includes(item)
                && !this.selected.includes(item)
            ) {
                this._addToSelected(item)

                if (resetAllSelected !== false) {
                    this._updateAllSelected()
                }
            }

            this.updateStorage()

            return this
        }

        /**
         * Deselects a certain item or multiple items
         *
         * @param {Object|Object[]} items The item(s) to deselect
         * @returns {SelectionController} Returns this to allow for chaining
         */
        this.deselect = function (items) {
            if (Array.isArray(items)) {
                items.forEach(function (item) {
                    self.deselect(item)
                })

                return this
            }

            const item = items
            const itemIndex = this.selected.indexOf(item)

            if (itemIndex !== -1) {
                if (this.data.includes(item)) {
                    this._setAllSelected(false)
                } else {
                    this._updateAllSelected()
                }

                this.selected.splice(itemIndex, 1)
            }

            this.updateStorage()

            return this
        }

        /**
         * Toggles the selected state of an item
         *
         * @param {Object} item The item to toggle the selected state of
         * @returns {SelectionController} Returns this to allow for chaining
         */
        this.toggleSelect = function (item) {
            if (this.selected.includes(item)) {
                this.deselect(item)
            } else {
                this.select(item)
            }
            return this
        }

        /**
         * Returns whether the given item is selected
         *
         * @param {Object} item The item that needs to be checked for selection
         * @returns {Boolean} True if the item is selected, false otherwise
         */
        this.isSelected = function (item) {
            return this.selected.includes(item)
        }

        /**
         * Sets all visible items to selected
         *
         * @returns {SelectionController} Returns this to allow for chaining
         */
        this.selectAll = function () {
            if (this.data.length === this.selectable.length) {
                this.selected = this.data.slice()
                this._updateAllSelected()
            } else {
                this.select(this.data)
            }

            this.updateStorage()

            return this
        }

        /**
         * Deselects all items
         *
         * @returns {SelectionController} Returns this to allow for chaining
         */
        this.deselectAll = function () {
            if (this.data.length === this.selectable.length) {
                this.selected = []
                this._updateAllSelected()
            } else {
                this.deselect(this.data)
            }

            this.updateStorage()

            return this
        }

        /**
         * Toggles the selection of all items
         *
         * @returns {SelectionController} Returns this to allow for chaining
         */
        this.toggleSelectAll = function () {
            if (this.allSelected) {
                this.deselectAll()
            } else {
                this.selectAll()
            }

            return this
        }

        /**
         * Gets the saved selection in the session storage
         * @returns {String[]} Returns the identifiers of the items that were saved to be selected
         */
        this.getStorage = function () {
            return JSON.parse(sessionStorage.getItem(this._getStorageKey()))
        }

        /**
         * Updates the saved selection in the session storage
         */
        this.updateStorage = function () {
            const identifiers = this.selected.map(item => item[this.config.identifier])

            sessionStorage.setItem(
                this._getStorageKey(),
                JSON.stringify(identifiers),
            )
        }

        this._getStorageKey = function () {
            const path = $location.path()
            return `selection_${path.slice(1).replace('/', '_')}`
        }

        this._setAllSelected = function (selected) {
            this.allSelected = selected
        }

        this._updateAllSelected = function () {
            this._setAllSelected(this._areAllSelected())
        }

        this._areAllSelected = function () {
            if (
                this.data === null
                || this.data.length === 0
                || this.selected.length < this.data.length
            ) {
                return false
            }

            if (this.selected.length === this.selectable.length) {
                return true
            }

            const notFound = this.selected.slice()

            for (let i = 0; i < this.data.length; i += 1) {
                const item = this.data[i]
                const notFoundIndex = notFound.indexOf(item)

                if (notFoundIndex === -1) {
                    return false
                }
                notFound.splice(notFoundIndex, 1)

                // No items left not found means all items are found in the selection
                if (notFound.length === 0) {
                    return true
                }
            }

            return true
        }

        this._addToSelected = function (item) {
            this.selected.push(item)
            this._sortSelected()
        }

        this._sortSelected = function () {
            this.selected.sort((a, b) => (this.selectable.indexOf(a) > this.selectable.indexOf(b) ? 1 : -1))
        }
    }

    /**
     * An action that can be executed with multiple items
     *
     * @param {MassActionConfig} config The config for the mass action, look at the typedef below to see the
     * mandatory properties
     * @constructor
     * @class
     */
    function MassAction(config) {
        /**
         * @typedef {Object} MassActionConfig
         * @type {Object}
         * @property {String} translateKey The translation key to use to display the action
         * @property {Function} propertyFunction The function to execute when the user wants to execute the mass
         * action
         * @property {String} [iconClass=''] The icon class(es) to use for the icon
         * @property {String} [additionalClasses=''] Additional classes to give to the selection bar action item
         * @property {String} [analytics.enabled=false] Check if Angulartics events are enabled for this mass action
         * @property {String} [analytics.event=null] Angulartics event identifier
         * @property {String} [analytics.category=null] Angulartics event category
         */
        const configDefaults = {
            translateKey: null,
            executeFunction: null,
            iconClass: '',
            additionalClasses: '',
            disabled: false,
            tooltip: '',
            analytics: {
                enabled: false,
                event: null,
                category: null,
            },
        }

        this.config = angular.extend({}, configDefaults, config)

        /**
         * Executes the mass action with given items
         *
         * @param {Object[]} items The items to execute the mass action with
         */
        this.execute = function (items) {
            this.config.executeFunction(items)
        }
    }

    this.Controller = SelectionController
    this.MassAction = MassAction
})
