import angular from 'angular'
import dock from '../modules/dock'
import seasonField from '../factories/metaInfo/handlers/seasonHandler'
import sizeField from '../factories/metaInfo/handlers/sizeHandler'
import dimensioningField from '../factories/metaInfo/handlers/dimensioningHandler'
import { Product } from '../shared/classes/Product'
import showFormFieldError from '../shared/functions/forms'
import getMessagesFromObject from '../shared/functions/getMessagesFromObject'
import reportMpnModalController from './modals/ReportMpnCtrl'
import reportMpnModalTemplate from '../templates/modal/reportMpn.html'
import restrictiveBrandModalController from './modals/RestrictiveBrandCtrl'
import restrictiveBrandModalTemplate from '../templates/modal/restrictiveBrand.html'
import '../services/PriceEdits'
import '../directives/dockHeader/dockHeader'
import '../directives/validatorMessage/validatorMessage'
import '../directives/currencyInput/currencyInput'
import '../directives/selectGrid/selectGrid'
import '../directives/numberInput/numberInput'
import '../directives/minItemsInStock/minItemsInStock'
import '../directives/stockEditor/stockEditor'
import '../directives/formErrorFocus/formErrorFocus'
import '../directives/inputCounter/inputCounter'
import '../directives/readOnly/checkReadOnly'
import '../directives/readOnly/setReadOnly'
import '../directives/validators/cannotContainBrand'
import '../directives/validators/cannotContainDoubleSpaces'
import '../directives/validators/cannotContainEan'
import '../directives/validators/cannotContainWpn'
import '../directives/validators/cannotContainLongWords'
import '../directives/validators/cannotContainMultipleCommasInSentence'
import '../directives/validators/cannotContainMultipleUppercase'
import '../directives/validators/cannotContainPeriod'
import '../directives/validators/cannotContainTripleSequence'
import '../directives/validators/cannotContainWebParts'
import '../directives/validators/digits-only'
import '../directives/validators/ean'
import '../directives/validators/endsWithPeriod'
import '../directives/validators/notInSet'
import '../directives/validators/uniqueEansPerProduct'
import '../directives/productFormSections/actions/actions'
import '../directives/productFormSections/characteristics/characteristics'
import '../directives/productFormSections/descriptors/descriptors'
import '../directives/productFormSections/error/error'
import '../directives/productFormSections/linking/linking'
import '../directives/productFormSections/linkingWarning/linkingWarning'
import '../directives/productFormSections/photos/photos'
import '../directives/productFormSections/price/price'
import '../directives/productFormSections/searchFields/searchFields'
import '../directives/productFormSections/searchResults/searchResults'
import '../directives/productFormSections/stock/stock'
import '../directives/productFormSections/debug/debug'
import stockAndPriceCache from '../shared/instances/stockAndPriceCache'

/* eslint-disable */
// The possible product form modes
export const ProductFormMode = Object.freeze({
    ADD: Symbol('add'), // The user is adding a new product
    PREFILLED: Symbol('prefilled'), // The user is adding a new product where a couple of fields are prefilled
    LINK: Symbol('link'), // The user is linking an existing product from another retailer
    EDIT: Symbol('edit'), // The user is editing an existing product
})

// The possible product form states
export const ProductFormState = Object.freeze({
    LOADING: Symbol('loading'), // Product information or meta info is loading
    READY: Symbol('ready'), // The form is ready to be filled in
    ERROR: Symbol('error'), // An error occurred while loading product information or meta info
    PROCESSING: Symbol('processing'), // The product is being processed
    PROCESSED: Symbol('processed'), // The product was processed
    PROCESS_ERROR: Symbol('process_error'), // An error occurred while processing the product
})

// All product form sections. Used to determine the order in which they will be displayed. The strings resemble the template names in pages/product/sections
export const ProductFormSection = Object.freeze({
    DEBUG: 'debug',
    LINKING: 'linking',
    LINKING_WARNING: 'linking-warning',
    CHARACTERISTICS: 'characteristics',
    DESCRIPTORS: 'descriptors',
    PRICE: 'price',
    SEARCH_FIELDS: 'search-fields',
    SEARCH_RESULTS: 'search-results',
    STOCK: 'stock',
})

// States for the product matcher when adding new products
export const ProductSearchState = Object.freeze({
    MISSING_INFO: Symbol('missing_info'), // One of the required form fields is not Completed
    SEARCHING: Symbol('loading'), // Actively searching for products
    FINISHED: Symbol('finished'), // Searching is completed and we can display results
    ERROR: Symbol('error'), // Something went wrong while searching for products, show an error
})

const controllerName = 'ProductCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    $route,
    $routeParams,
    $q,
    $location,
    $translate,
    $uibModal,
    api,
    metaInfo,
    commonFilters,
    PartialProduct,
    toastr,
    Language,
    PriceEdits,
) {
    // For debugging purposes
    const forceEdit = false

    $scope.State = ProductFormState
    $scope.Mode = ProductFormMode
    $scope.SearchState = ProductSearchState
    $scope.Section = ProductFormSection
    $scope.state = ProductFormState.READY
    $scope.mode = _determineProductFormMode($route)
    $scope.checkedLinkableProducts = $scope.mode !== ProductFormMode.ADD
    $scope.filters = getSelectionFilters()
    $scope.combinedCategoryProperty = Product.getCombinedCategoryProperty()
    $scope.productId = $routeParams.productId
    $scope.product = new Product({})
    $scope.minStockWhenRestricted = 3
    $scope.sections = []
    $scope.errorMessages = []
    $scope.requestCanceler = null
    $scope.reload = loadFormResources
    $scope.submit = saveProduct
    $scope.linkProduct = linkProduct
    $scope.showError = showFormFieldError
    $scope.confirmViewedSearchResults = setCheckedLinkableProducts
    $scope.searchForMatches = searchForMatches
    $scope.updateSeasonField = updateSeasonField
    $scope.flagMpn = flagMpn
    $scope.restictiveBrand = false
    $scope.currentUniqueEans = []
    $scope.searchEan = ''

    $scope.search = {
        results: [],
        error: null,
        state: ProductSearchState.MISSING_INFO,
        properties: [
            'merk',
            'ean',
            'merkProductNummer',
            'kleur'
        ]
    }

    $scope.options = {
        sizes: [],
    }

    $scope.sectionReadOnly = function (section, mode) {
        return (
            !forceEdit &&
            mode === ProductFormMode.LINK &&
            section !== ProductFormSection.LINKING &&
            section !== ProductFormSection.STOCK
        )
    }

    // Kickstart everything
    loadFormResources()

    /**
     * Loads all resources used to display the product form
     */
    function loadFormResources() {
        $scope.state = ProductFormState.LOADING
        $scope.requestCanceler = $q.defer()
        const promises = [metaInfo.load(Language.get())]

        switch ($scope.mode) {
            case ProductFormMode.PREFILLED:
                promises.push(_getPrefilledProduct())
                break
            case ProductFormMode.EDIT:
                promises.push(
                    _getEditProduct($scope.productId, $scope.requestCanceler),
                    PriceEdits.getLastEdit($scope.productId),
                )
                break
            case ProductFormMode.LINK:
                promises.push(
                    _getLinkProduct($scope.productId, $scope.requestCanceler),
                )
                break
        }

        $q.all(promises)
            .then(([metaInfo, productResponse, lastPriceEdit]) => {
                $scope.state = ProductFormState.READY

                if (productResponse) {
                    $scope.product = new Product(
                        productResponse,
                        $scope.mode === ProductFormMode.LINK,
                    )

                    if ($scope.mode === ProductFormMode.EDIT) {
                        $scope.product.setLastPriceEdit(lastPriceEdit)
                    }
                }

                $scope.sections = _getSections($scope.mode)

                _exposeMetaInfoFunctions(metaInfo)
                _setMetaInfoWatchers(metaInfo)
                _setCategoryWatchers($scope.product)

                $scope.currentUniqueEans = metaInfo.currentUniqueEans

                if ($scope.mode === ProductFormMode.ADD) {
                    _setSearchWatchers()
                }

                _setDefaultFit($scope.product)
            })
            .catch(error => {
                $scope.state = ProductFormState.ERROR
                _handleRequestError(error)
            })
    }

    function saveProduct(productForm, redirectRoute) {
        $scope.state = ProductFormState.READY
        $scope.errorMessages = {}

        if (productForm.$invalid) {
            productForm.$setSubmitted()
            $translate('product.toast.validation')
                .then(toastr.warning)
            return
        }

        $scope.state = ProductFormState.PROCESSING
        $scope.requestCanceler = $q.defer()
        let apiRequestFunction
        let toastTitle

        const requestProduct = $scope.product.getRequestProduct()

        switch ($scope.mode) {
            case ProductFormMode.EDIT:
                apiRequestFunction = api.products.updateProduct
                break
            default:
                apiRequestFunction = api.products.addProduct
                break
        }

        switch ($scope.mode) {
            case ProductFormMode.EDIT:
                toastTitle = 'saved'
                break
            case ProductFormMode.LINK:
                toastTitle = 'linked'
                break
            default:
                toastTitle = 'created'
        }

        const apiRequest = apiRequestFunction(
            requestProduct,
            $scope.requestCanceler,
        )

        apiRequest
            .then(productRequest => {
                if ($scope.mode !== ProductFormMode.EDIT) {

                    $scope.product.variaties.forEach(
                        variant => (variant.productId = productRequest.data.id),
                    )

                    return api.products.addVariantBulk(
                        productRequest.data.id,
                        $scope.product.variaties,
                    )
                } else {
                    if ($scope.product.priceChanged()) {
                        stockAndPriceCache.removeItem($scope.product.getOriginalSku())
                        return PriceEdits.update($scope.product.id)
                    }

                    return $q.resolve()
                }
            })
            .then(() => {
                $scope.state = ProductFormState.PROCESSED
                $location.path(redirectRoute)

                $translate(`product.toast.${toastTitle}`)
                    .then((title) => {
                        toastr.success(title)
                    })
            })
            .catch(error => {
                $scope.state = ProductFormState.PROCESS_ERROR
                _handleRequestError(error)
            })

        return false
    }

    /**
     * Goes to the add product page prefilled with the given product
     *
     * @param {Object} product The product to link, has to have an 'id' property
     */
    function linkProduct(product) {
        $location.path(
            `link/product/${product[Product.getLinkedProductProperty()]}`,
        )
    }

    /**
     * Returns all product filters
     *
     * @returns {Object} An object with all the filters by name
     */
    function getSelectionFilters() {
        const {
            dimensioningFilter: dimensioning,
            photoSizeFilter: photoSize,
        } = commonFilters.getDimensioningAndPhotoSizeFilter()
        const selectionFilters = {
            dimensioning,
            photoSize
        }

        selectionFilters.category = commonFilters.getCombinedCategoryFilter()
        selectionFilters.brand = commonFilters.getBrandFilter(true)
        selectionFilters.color = commonFilters.getColorFilter()
        selectionFilters.season = commonFilters.getSeasonFilter($scope.mode === ProductFormMode.EDIT)
        selectionFilters.fit = commonFilters.getFitFilter()

        _applyCommonFilterConfig(selectionFilters)

        return selectionFilters
    }

    /**
     * The user confirmed it has seen all the search results and they don't match any
     * existing products.
     *
     * Can also be used to display fields when no search results were found.
     */
    function setCheckedLinkableProducts(checked = true) {
        $scope.checkedLinkableProducts = checked
    }

    /**
     * If carry over is set to true we don't show the season field and
     * should also update its value to be null.
     */
    function updateSeasonField() {
        if ($scope.product.carryover) {
            $scope.product.seizoen = null
        }
    }

    /**
     * Checks the query parameters to see if we're in debug mode.
     */
    function isDebugMode() {
        return $location.search().debug === true
    }

    /**
     * Opens a modal which allows the user to report an incorrect brand product number (MPN).
     */
    function flagMpn() {
        $uibModal.open({
            template: reportMpnModalTemplate,
            controller: reportMpnModalController,
            size: 'md',
            resolve: {
                product: () => $scope.product
            },
        })
    }

    /**
     * Applies common configuration for product form filters such as removing the label and adding a placeholder
     * @param {Object} filters An object with filter names as properties and filter objects as values
     * @private
     */
    function _applyCommonFilterConfig(filters) {
        Object.values(filters)
            .forEach(filter => {
                filter.config.label = false
                filter.config.placeholder = true
                filter.config.savePreference = false
            })
    }

    /**
     * Function that looks at Angular's route and determines the edit mode for the product form
     *
     * @param $route Angular's $route
     * @returns {Symbol} A ProductFormMode
     * @private
     */
    function _determineProductFormMode($route) {
        return $route.current.$$route.mode
    }

    /**
     * Function that returns the prefilled product product in a promise
     *
     * @returns {Promise<Product>} Returns a promise that resolves with the partial product
     * @private
     */
    function _getPrefilledProduct() {
        return $q.resolve(PartialProduct.get())
    }

    /**
     * Function that returns the right product in case of editing.
     *
     * @param {String} id The product id
     * @param {Promise} canceler A promise that, when resolves, cancels the request
     * @returns {Promise<Product>} Returns a promise that resolves with the right product (without response data) or fails when the product could not be loaded.
     * @private
     */
    function _getEditProduct(id, canceler) {
        return api.products
            .getProduct({ id }, canceler)
            .then(response => response.data)
    }

    /**
     * Function that returns the right product in case of linking
     *
     * @param {String} id The WSNL product id
     * @param {Promise} canceler A promise that, when resolves, cancels the request
     * @returns {Promise<Product>} Returns a promise that resolves with the right product (without response data) or fails when the product could not be loaded.
     * @private
     */
    function _getLinkProduct(id, canceler) {
        return api.products
            .getWsnlProduct({ id }, canceler)
            .then(response => response.data)
    }

    function _exposeMetaInfoFunctions(metaInfo) {
        $scope.eanRequired = brand =>
            brand
            && (!metaInfo.brandExists(brand.code) || !metaInfo.brand(brand.code)
                .eanOptional())
        $scope.hasStockRestrictions = dimensioning => {
            return (
                $scope.mode !== ProductFormMode.LINK &&
                $scope.mode !== ProductFormMode.EDIT &&
                metaInfo
                    .field(dimensioningField)
                    .hasStockRestrictions(dimensioning)
            )
        }
    }

    /**
     * Opens a modal stating that the currently selected brand doesn't allow for creating new products and user should use "Link Product"
     */
    function _openRestrictiveBrandModal() {
        $uibModal.open({
            template: restrictiveBrandModalTemplate,
            controller: restrictiveBrandModalController,
            size: 'md',
            resolve: {
                brand: () => $scope.product.merk
            },
        })
    }

    /**
     * Sets the watchers for objects that use the meta info fields
     *
     * @param {MetaInfo} metaInfo A meta info fields instance
     * @private
     */
    function _setMetaInfoWatchers(metaInfo) {

        // Update the possible sizes when the dimensioning changes
        $scope.$watch(
            'product.maatvoeringType',
            dimensioning =>
                ($scope.options.sizes = _getSizesFromDimensioning(
                    dimensioning,
                    metaInfo,
                )),
        )

        $scope.$watch(
            'options.sizes',
            sizes => {
                $scope.product.maatOpFoto = _matchSizeOnPhoto($scope.product.maatOpFoto, sizes)
            }
        )

        $scope.$watch(
            'product.merk',
            brand => {
                $scope.restictiveBrand = $scope.product.isTemplateVoorGekoppeldProduct !== false
                    && !$scope.product.id
                    && $scope.product.merk
                    && metaInfo.brandIsRestricted($scope.product.merk.code)

                if ($scope.restictiveBrand && (ProductFormMode.LINK !== $scope.mode)) {
                    _openRestrictiveBrandModal()
                }
            }
        )
    }

    /**
     * Sets the value watchers for a product's combined category property to fill the other category properties in the model
     *
     * @param {Product} product The product with the combined category property you want to watch
     * @private
     */
    function _setCategoryWatchers(product) {
        $scope.$watch(
            () => product[Product.getCombinedCategoryProperty()],
            newValue => {
                if (!newValue) {
                    return
                }

                product.hoofdCategorie = newValue.mainCategory
                product.categorie = newValue.category

                if (newValue.subCategory) {
                    product.subCategorie = newValue.subCategory
                } else if (product.subCategorie) {
                    delete product.subCategorie
                }
            },
        )
    }

    /**
     * Sets watchers on certain search properties which kick-off searches
     * in the database for existing products.
     */
    function _setSearchWatchers() {
        $scope.filters.brand.onSelect(searchForMatches, false)
        $scope.filters.color.onSelect(searchForMatches, false)
    }

    /**
     * Search for products in the database that match current values and
     * would be candidates for linking instead of creating a new product.
     */
    async function searchForMatches() {
        if ($scope.mode !== ProductFormMode.ADD) {
            return
        }

        // Create a key, value map for the properties with its values
        const propertyValues = $scope.search.properties.map(property => ([
            property,
            angular.copy($scope.$eval(`product.${property}`))
        ]))

        const nonEmptyPropertyValues = propertyValues.filter(([, value]) => !!value)

        // If less than 3 of the search properties are empty, ignore this call
        if (
            nonEmptyPropertyValues.length < 2
        ) {
            $scope.search.state = ProductSearchState.MISSING_INFO
            await Promise.resolve()
            $scope.$apply()
            return
        }

        try {
            $scope.search.state = ProductSearchState.SEARCHING
            const body = Object.fromEntries(nonEmptyPropertyValues)
            const response = await api.products.match(body)
            const metaInfoInstance = await metaInfo.load()

            // Add a season property to the matched products
            response.data.producten.forEach(match => {
                match.season = metaInfoInstance
                    .field(seasonField)
                    .getName(match.seizoen)
            })

            $scope.search.results = response.data.producten.map(product => new Product(product, true))
            $scope.search.state = ProductSearchState.FINISHED
            setCheckedLinkableProducts($scope.search.results.length === 0)
            $scope.$apply()
        } catch (error) {
            if (typeof error.data === 'undefined') {
                $scope.search.error = error
            } else {
                $scope.search.error = error.data
            }

            $scope.search.state = ProductSearchState.ERROR
            //$scope.$apply()
        }
    }

    function _getSections(mode) {
        const Section = ProductFormSection

        const debugSections = []

        if (isDebugMode()) {
            debugSections.push(Section.DEBUG)
        }

        switch (mode) {
            // When linking we add the linking section and move the stock section to the top. If the product owner of the product has set a brand price the
            // price is not editable. So only then do we include the price section (hence the filter)
            case ProductFormMode.LINK:
                return [
                    ...debugSections,
                    Section.LINKING,
                    Section.STOCK,
                    Section.LINKING_WARNING,
                    Section.SEARCH_FIELDS,
                    Section.DESCRIPTORS,
                    Section.PRICE,
                    Section.CHARACTERISTICS,
                ].filter(
                    section => !(section === Section.PRICE && !$scope.product.hadMsrp()),
                )

            // When editing we remove the stock section
            case ProductFormMode.EDIT:
                return [
                    ...debugSections,
                    Section.SEARCH_FIELDS,
                    Section.DESCRIPTORS,
                    Section.CHARACTERISTICS,
                    Section.PRICE,
                ]

            // When we use prefilled data from linking, we don't need to implement product search and don't need the search results section
            case ProductFormMode.PREFILLED:
                return [
                    ...debugSections,
                    Section.SEARCH_FIELDS,
                    Section.DESCRIPTORS,
                    Section.PRICE,
                    Section.CHARACTERISTICS,
                    Section.STOCK,
                ]

            // In case we add a new product we do want the search results section
            case ProductFormMode.ADD:
                return [
                    ...debugSections,
                    Section.SEARCH_FIELDS,
                    Section.SEARCH_RESULTS,
                    Section.DESCRIPTORS,
                    Section.PRICE,
                    Section.CHARACTERISTICS,
                    Section.STOCK,
                ]
        }
    }

    /**
     * Sets a default fit for the given product.
     *
     * @param {Product} product
     */
    function _setDefaultFit(product) {
        if (!!product.pasvorm) {
            return
        }

        $scope.product.pasvorm = 'Dit item valt normaal'
    }

    /**
     * Returns the sizes corresponding to a specific dimensioning
     *
     * @param {Object} dimensioning The dimensioning object
     * @param {String} dimensioning.code The dimensioning code specifying the dimensioning
     * @param {MetaInfo} metaInfo A meta info fields instance
     * @returns {Object[]}
     * @private
     */
    function _getSizesFromDimensioning(dimensioning, metaInfo) {
        return metaInfo
            .field(sizeField)
            .getProductSizes(dimensioning ? dimensioning.code : true)
    }

    /**
     * Tries to find the size on photo size in a list of sizes. First trims the string and ignores casing and then removes non-numbers and tries to match the number.
     *
     * @param {String} sizeOnPhoto The current size on photo
     * @param {Object[]} sizes The possible sizes
     * @returns {String | null} The matched size name if found
     */
    function _matchSizeOnPhoto(sizeOnPhoto, sizes) {
        if (!sizeOnPhoto) {
            return null
        }

        // Try to find size without whitesapce and while ignoring casing
        let matchedSize = sizes.find(size => size.maat.naam.toUpperCase()
            .replace(' ', '') === sizeOnPhoto.toUpperCase()
            .replace(' ', ''))

        if (!matchedSize) {

            //Try to find size by only matchin non-digit characters
            const nonDigitRegex = /[^\d.-]/g
            matchedSize = sizes.find(size => size.maat.naam.replace(nonDigitRegex, '') === sizeOnPhoto.replace(nonDigitRegex, ''))
        }

        if (!matchedSize) {
            return null
        }

        return matchedSize.maat.naam
    }

    function _handleRequestError(error) {
        const errorData = error.data
        console.error(errorData)
        $scope.errorMessages = getMessagesFromObject(errorData)
    }

    $scope.$on('$destroy', () => $scope.requestCanceler ? $scope.requestCanceler.resolve() : false)
})
