import dock from '../modules/dock'
import { supportedLanguages, preferredLanguage } from '../shared/constants/language'

const storageKey = 'language'

dock.service('Language', /* @ngInject */ function ($translate, tmhDynamicLocale, amMoment) {
    this.get = () => {
        const localStorageLanguage = localStorage.getItem(storageKey)

        if (supportedLanguages.includes(localStorageLanguage)) {
            return localStorageLanguage
        }

        return preferredLanguage
    }

    this.set = (language) => {
        // Update the language in the local storage
        localStorage.setItem(storageKey, language)

        // Let angular-translate use the current language
        $translate.use(language)

        // Let angular use the correct locale
        tmhDynamicLocale.set(language)

        // Let Moment use the right locale
        amMoment.changeLocale(language)
    }

    this.toggle = () => {
        this.set(this.getNext())
    }

    this.getNext = (language = this.get()) => {
        const languageIndex = supportedLanguages.indexOf(language)
        const newLanguageIndex = languageIndex === supportedLanguages.length - 1 ? 0 : languageIndex + 1
        return supportedLanguages[newLanguageIndex]
    }
})

