/**
 * The update class that can be extended by other updates to be added to the update registry. This is the default class
 * used for versions that don'r require updates
 */
export default class Update {
    /**
     * @param {Version} version
     */
    constructor(version) {
        this.version = version
    }

    /**
     * The function that contains the code for update execution. Needs to be overwritten by every class extending this
     * class
     */
    async doUpdate() {
        console.log(this._getNoUpdateString())
    }

    _getUpdateString() {
        return `Update required for version ${this.version.toString()}. Updating...`
    }

    _getNoUpdateString() {
        return `No update required for version ${this.version.toString()}.`
    }
}
