import angular from 'angular'
import dock from '../modules/dock'

dock.config(/* @ngInject */ function (toastrConfig) {
    // Set toastr defaults
    angular.extend(toastrConfig, {
        positionClass: 'toast-bottom-right',
        target: 'body',
    })
})
