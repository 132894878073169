import dock from '../../modules/dock'
import './uploadOverlay.scss'

dock.directive('uploadOverlay', function () {
    return {
        restrict: 'A',
        scope: false,
        link(scope, element) {
            const overlayClasses = [
                'upload-drop-overlay',
                'dock-layout',
                'dock-layout-full-height',
                'dock-layout-overlay',
                'dock-layout-columns',
                'dock-layout-center-horizontal',
                'dock-layout-center-vertical',
            ]

            element.addClass(overlayClasses.join(' '))
        },
    }
})
