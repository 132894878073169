import angular from 'angular'
import { MDCLinearProgress } from '@material/linear-progress'
import dock from '../../modules/dock'
import rowLoadingTemplate from './rowLoading.html'
import './rowLoading.scss'

/* eslint-disable */
dock.directive('rowLoading', function() {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            loading: '<',
            progress: '<?'
        },
        template: rowLoadingTemplate,
        link(scope, element) {
            if (angular.isUndefined(scope.progress)) {
                scope.progress = null
            }

            const progressElement = element[0].querySelector('.mdc-linear-progress');
            const linearProgress = new MDCLinearProgress(progressElement);
            scope.$watch('progress', (newValue) => {
                if (newValue !== null) {
                    linearProgress.progress = newValue 
                }
            })
        },
    }
})
