import dock from '../../modules/dock'

dock.directive('formErrorFocus', function () {
    return {
        restrict: 'A',
        link(scope, elem) {
            elem.on('submit', () => {
                let first = elem.find('.ng-invalid').first()

                if (
                    first[0] &&
                    first[0].tagName !== 'INPUT' &&
                    first[0].tagName !== 'TEXTAREA'
                ) {
                    first = first.find('input').last()
                }

                first.focus()
            })
        },
    }
})
