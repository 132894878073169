import MetaInfoField from './MetaInfoField'
import registry from '../registry'

/* eslint-disable */
/**
 * A meta info handler for the size field. Adds useful functionality
 */
export class SizeHandler extends MetaInfoField {
    /**
     * Gets the sizes for a specific size type
     *
     * @param {String} [dimensioning] The size type
     * @return {Object[]} The sizes for the specific size type. If no size type was given it returns all sizes
     */
    getValues(dimensioning) {
        if (!dimensioning) {
            return super.getValues()
        }

        return super
            .getValues()
            .filter(this.getSizeInDimensioningFilter(dimensioning))
    }

    getSizeInDimensioningFilter(dimensioning) {
        return size => size.code.startsWith(`${dimensioning}_`)
    }

    getNames(dimensioning) {
        if (!dimensioning) {
            return super.getNames()
        }

        return super
            .getValues()
            .filter(this.getSizeInDimensioningFilter(dimensioning))
            .map(({ naam }) => naam)
    }

    /**
     * Get the sizes for a specific size type in product form
     *
     * @param {String} [dimensioning] The size type
     * @return {Object[]} The sizes for the specific size type in product form. If no size type was given it returns all
     * sizes
     */
    getProductSizes(dimensioning) {
        return this.getValues(dimensioning).map(size => ({
            voorraad: 0,
            maat: size,
            SKU: '',
            price: undefined,
        }))
    }
}

registry.registerAlias('maatcode', 'size')
export default registry.registerHandler('size', SizeHandler)
