import angular from 'angular'
import '../../factories/api'
import '../../directives/currencyInput/currencyInput'
import '../../directives/checkboxSwitch/checkboxSwitch'
import '../../services/PriceEdits'
import '../../directives/validators/ean'
import '../../directives/validators/notInSet'
import '../../directives/validators/uniqueEansPerProduct'
import dock from '../../modules/dock'
import sizeField from '../../factories/metaInfo/handlers/sizeHandler'
import dimensioningField from '../../factories/metaInfo/handlers/dimensioningHandler'
import { Product } from '../../shared/classes/Product'
import { ProductVariant } from '../../shared/classes/ProductVariant'

/* eslint-disable */
/**
 * Class for controlling stock and comparing it to previous stock
 */
export class Stock {
    constructor() {
        this.originalStock = new Map()
    }

    /**
     * Sets the original stock to the given variations
     *
     * @param {Object[]} variations The current stock
     */
    setOriginalStock(variations) {
        this.originalStock.clear()

        variations.forEach(variant => this.setOriginalStockVariant(variant))
    }

    /**
     * Sets the original stock for a specific variant
     *
     * @param {Object} variant The variant with the current stock info
     */
    setOriginalStockVariant(variant) {
        this.originalStock.set(
            this._getVariantIdentifier(variant),
            angular.copy(variant),
        )
    }

    /**
     * Returns a {@link StockQueue} instance for the changes between the current original stock and the given variations
     *
     * @param {Object[]} variations The variations to compare to the original stock
     * @return {StockQueue} A StockQueue instance with create and update queues according to the changes
     */
    getStockQueue(variations) {
        const stockQueue = new StockQueue()

        variations.forEach(variant => {
            const originalVariantStock = this.originalStock.get(
                this._getVariantIdentifier(variant),
            )

            if (
                originalVariantStock === null ||
                originalVariantStock === undefined
            ) {
                stockQueue.addCreated(new ProductVariant(variant))
            } else if (
                originalVariantStock.voorraad !== variant.voorraad ||
                originalVariantStock.price !== variant.price ||
                originalVariantStock.manufacturerSuggestedVariantPrice !== variant.manufacturerSuggestedVariantPrice ||
                originalVariantStock.ean !== variant.ean
            ) {
                stockQueue.addUpdated(new ProductVariant(variant))
            }
        })

        return stockQueue
    }

    /**
     * Returns whether the given variations have changes when comparing them to the original stock
     *
     * @param {Object[]} variations The variations to check against the current stock
     * @return {Boolean} Returns true if there are changes and false otherwise
     */
    hasChanges(variations) {
        const stockQueue = this.getStockQueue(variations)
        return (
            stockQueue.getUpdated().length !== 0 ||
            stockQueue.getCreated().length !== 0
        )
    }

    _getVariantIdentifier(variant) {
        return variant.id
    }
}

/**
 * A queue of stock changes
 */
export class StockQueue {
    constructor() {
        this.createQueue = []
        this.updateQueue = []
    }

    /**
     * Adds a variant to the updated variants queue
     *
     * @param {Object} variant The variant to add
     */
    addUpdated(variant) {
        this.updateQueue.push(variant)
    }

    /**
     * Adds a variant to the created variants queue
     *
     * @param {Object} variant The variant to add
     */
    addCreated(variant) {
        this.createQueue.push(variant)
    }

    /**
     * Gets the updated variants queue
     *
     * @return {Array} An array of updated variants
     */
    getUpdated() {
        return this.updateQueue
    }

    /**
     * Gets the created variants queue
     *
     * @return {Array} An array of created variants
     */
    getCreated() {
        return this.createQueue
    }
}
const controllerName = 'StockCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    $translate,
    $uibModalInstance,
    $q,
    actionList,
    changeTracker,
    metaInfo,
    api,
    Language,
    PriceEdits,
) {
    let stock = new Stock()
    let permissionToClose = false

    const STATE = ($scope.STATE = {
        LOADING: 'loading',
        IDLE: 'idle',
        ERROR: 'error',
        UPDATING: 'updating',
        UPDATE_ERROR: 'updateError',
    })

    $scope.product = actionList[0]
    $scope.requestCanceler = null
    $scope.forms = {}
    $scope.variantNamePrefix = 'variant_'
    $scope.loadVariations = loadVariations
    $scope.state = STATE.IDLE
    $scope.errorInfo = null
    $scope.possibleSizes = []
    $scope._selectedSize = null
    $scope.disabledSizes = []
    $scope.otherSizesPerDimension = []
    $scope.stockEditorValid = false
    $scope.currentUniqueEans = []

    loadVariations()

    $scope.cancel = function ($event) {
        $uibModalInstance.close($event)
    }

    /**
     * Closes the modal
     */
    $scope.close = function (event) {
        if (!stock.hasChanges($scope.product.variaties) ||
            permissionToClose) {
            return
        }

        event.preventDefault()

        $translate('overlay.stock.saveChanges').then(saveChangesPrompt => {
            if (confirm(saveChangesPrompt)) {
                permissionToClose = true
                $uibModalInstance.close()
            }
        })
    }

    $scope.$on('modal.closing', $scope.close)

    $scope.hasChanges = function() {
        return stock.hasChanges($scope.product.variaties)
    }

    /**
     * Saves the variant changes to the server in bulk
     *
     * @return {Promise} Returns a promise that resolves when both the create and update queue are finished
     */
    $scope.saveChanges = function() {
        const queue = stock.getStockQueue($scope.product.variaties)
        let updated = queue.getUpdated()
        let created = queue.getCreated()

        $scope.state = STATE.UPDATING

        let updatePromise = processUpdateQueue(updated, $scope.product)
        let createPromise = processCreateQueue(created, $scope.product)

        createPromise = createPromise.then(response => {
            flushCreateQueue(response.data.variaties || [])
        })

        return $q
            .all([updatePromise, createPromise])
            .then(() => {
                if ($scope.product.priceChanged()) {
                    PriceEdits.update($scope.product.id)
                }

                loadVariations()
            })
            .catch(error => {
                console.error('Error updating product stock', error)
                $scope.state = STATE.UPDATE_ERROR
                $scope.errorInfo = error
            })
            .finally(() => changeTracker.markChanged())
    }

    /**
     * Sets the price for a variant if the variant's price is null
     * @param {Object} variant The variant to edit
     * @return {Object} The variant
     */
    function setPrice(variant) {
        if ($scope.product.manufacturerSuggestedRetailPrice) {
            variant.manufacturerSuggestedVariantPrice = variant.manufacturerSuggestedVariantPrice || $scope.product.manufacturerSuggestedRetailPrice
        } else {
            variant.price = variant.price || $scope.product.prijs
        }

        return variant
    }

    function loadVariations() {
        $scope.state = STATE.LOADING
        $scope.requestCanceler = $q.defer()

        const updatePromise = api.products.getProductStock(
            $scope.product,
            $scope.requestCanceler,
        )
        const metaInfoPromise = metaInfo.load(Language.get())
        const lastPriceEditPromise = PriceEdits.getLastEdit($scope.product.id)

        $q.all([updatePromise, metaInfoPromise, lastPriceEditPromise])
            .then(([response, metaInfo, lastPriceEdit]) => {
                $scope.state = STATE.IDLE
                $scope.product = new Product(response.data)
                $scope.product.setLastPriceEdit(lastPriceEdit)

                const metaInfoSizes = metaInfo
                    .field(sizeField)
                    .getProductSizes($scope.product.maatvoeringType.code)

                $scope.possibleSizes = getSelectableSizes(metaInfoSizes, $scope.product.variaties, $scope.product.maatvoeringType.code)
                $scope.otherSizesPerDimension = getOtherSizesPerDimension(
                    metaInfo,
                    $scope.product.maatvoeringType.code,
                    $scope.product.variaties,
                )
                $scope.disabledSizes = [...$scope.product.variaties]
                $scope.currentUniqueEans = metaInfo.currentUniqueEans
                $scope.eanRequired = brand => {
                    return brand
                        && (!metaInfo.brandExists(brand.code) || !metaInfo.brand(brand.code)
                            .eanOptional())
                }
                stock.setOriginalStock($scope.product.variaties)
            })
            .catch(error => {
                $scope.state = STATE.ERROR
                $scope.errorInfo = error
                console.error('Error loading product stock', error)
            })
    }

    function getMetaInfoOtherDimensions(metaInfo, productDimension) {
        return metaInfo.field(dimensioningField).getValues().filter(sizeType => {
            return sizeType.code !== productDimension
        })
    }

    function getOtherSizesPerDimension(metaInfo, productDimension, variants) {
        const metaInfoOtherDimensions = getMetaInfoOtherDimensions(metaInfo, productDimension)

        return metaInfoOtherDimensions.reduce((sizesPerDimension, dimension) => {
            const sizes = metaInfo
                .field(sizeField)
                .getProductSizes(dimension.code)

            const normalizedSizes = sizes.map(size => {
                return variants.find(
                    variant => (variant.maat.code === size.maat.code)
                        && (variant.voorraad > 0)
                )
            }).filter(variant => variant !== undefined)

            if (normalizedSizes.length) {
                sizesPerDimension.push({
                    dimension: dimension,
                    sizes: normalizedSizes,
                })
            }

            return sizesPerDimension
        }, [])
    }

    function getSelectableSizes(metaInfoSizes, variants) {
        const normalizedSizes = metaInfoSizes.map(size => ({
            ...size,
            productId: $scope.product.id,
        }))

        return normalizedSizes
            .map(size => new ProductVariant(size))
            .map(size => {
                // If we already have a variant that matches a size, replace
                // it in place to preserve ordering
                const matchingVariant = variants.find(
                    variant => variant.maat.code === size.maat.code
                )

                if (matchingVariant === undefined) {
                    return size
                }

                return matchingVariant
            })
    }

    function processUpdateQueue(updateQueue, product) {
        if (updateQueue.length === 0) {
            return $q.resolve({ data: [] })
        }

        return api.products.updateVariantBulk(product.id, updateQueue.map(variant => variant.getRequestVariant()))
    }

    function processCreateQueue(createQueue, product) {
        if (createQueue.length === 0) {
            return $q.resolve({ data: [] })
        }

        return api.products.addVariantBulk(product.id, createQueue.map(variant => variant.getRequestVariant(true)))
    }

    function flushCreateQueue(createdVariants) {
        $scope.product.variaties.forEach((variant, index) => {
            const createdVariant = createdVariants.find(createdVariant => {
                return variant.maat.code === createdVariant.maat.code
            })

            if (createdVariant) {
                $scope.product.variaties[index] = new ProductVariant(createdVariant)
            }
        })
    }

    $scope.$on('$destroy', () => $scope.requestCanceler ? $scope.requestCanceler.resolve() : false)
})
