import dock from '../modules/dock'
import { preferredLanguage } from '../shared/constants/language'
import '../factories/translateRaceLoader/translateRaceLoader'

dock.config(/* @ngInject */ function ($translateProvider) {
    // Set static file loader settings
    $translateProvider.useLoader('translateRaceLoader', { translate: $translateProvider })

    // Let translate service use local storage
    $translateProvider.useLocalStorage()

    // Set fallback language
    $translateProvider.fallbackLanguage([preferredLanguage])

    // Set preferred language
    $translateProvider.preferredLanguage(preferredLanguage)

    // Enable HTML escaping for parameters
    $translateProvider.useSanitizeValueStrategy('escapeParameters')

})
