import { get, set, Store } from 'idb-keyval'
import dock from '../modules/dock'

const priceEditStore = new Store('dock', 'price-edits')

/**
 * Updates a product's last price edit to now
 *
 * @param {String} productId The product ID of the product that had it's price changed
 */
async function update(productId) {
    return set(productId, new Date(), priceEditStore)
}

/**
 * Get's the date a product's price was last edited
 *
 * @param {String} productId The product ID of the product to check
 * @returns {Date} The date of the last edit
 */
async function getLastEdit(productId) {
    return get(productId, priceEditStore)
}

dock.service('PriceEdits', function () {
    return { update, getLastEdit }
})
