import dock from '../../modules/dock'

/**
 * Directive that can be added to a form. When given an array of stock items and a minimum of items
 * that should be in stock it adds a validator to the form that checks if enough items are set to
 * be in stock.
 */
dock.directive('minItemsInStock', /* @ngInject */ function ($document) {
    return {
        restrict: 'A',
        require: 'form',
        scope: {
            items: '=stockItems',
            min: '=minItemsInStock',
            elementQuery: '@stockElement',
        },
        link(scope, element, attr, formController) {
            let stockElement = element

            /**
             * Sets the form's validity
             *
             * @param {Boolean} valid Whether the form should be in a valid or invalid state
             */
            function setValid(valid) {
                formController.$setValidity(
                    'minItemsInStock',
                    valid,
                    formController,
                )
            }

            /**
             * Checks if at least {@link min} stock items given by {@link items} are in stock.
             *
             * @param {Array} items An array with stock items to check
             * @param {Number} min The minimum amount of items that should be in stock
             * @return {Boolean} Returns true if the items in stock are higher than or equal to
             * {@link min}
             */
            function isValid(items, min) {
                return (
                    items &&
                    items.length >= min &&
                    items.reduce(
                        (itemsInStock, item) =>
                            itemsInStock + (item.voorraad > 0),
                        0,
                    ) >= min
                )
            }

            /**
             * Validates the form and sets its state accordingly
             */
            function updateState() {
                const valid = isValid(scope.items, scope.min)
                setValid(valid)
                return valid
            }

            element.on('submit', () => {
                const valid = updateState()

                if (!valid) {
                    stockElement[0].scrollIntoView()
                }
            })

            if (scope.elementQuery) {
                $document.ready(() => {
                    stockElement = element.find(scope.elementQuery)
                })
            }

            scope.$watch('items', updateState, true)
            scope.$watch('min', updateState)
            updateState()
        },
    }
})
