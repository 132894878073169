import angular from 'angular'
import dock from '../modules/dock'
import { flatten, unflatten } from '../shared/functions/flatten'

/**
 * Angular service that provides some help with translating values that are stored as Dutch strings
 * in the database.
 *
 * Example: Assume the database stores a set of colors in the terms ['Red', 'Green, 'Blue'] and we
 * want to add translations for it. We could (in nl.json (the Dutch translation file)) add the
 * following:
 *
 * "Red": "Rood",
 * "Green": "Groen",
 * "Blue": "Blauw"
 *
 * and for selecting a color we could use the values ['Red', 'Green, 'Blue'] as filters, so (for
 * example):
 *
 * <select>
 *   <option ng-repeat="color in colors">{{ color | translate }}</option>
 * </select>
 *
 * But this would have two disadvantages:
 * 1. The value needs to be declared in the outermost scope of the translations file
 * 2. When the back-end teams decides to change the names of the values to i.e. Dutch, or color
 *    codes or whatsoever we'd have to change that value in two places.
 *
 * This service allows you to get rid of both disadvantages. Example:
 *
 * // Setting the constants
 * var C;
 * var constants = ConstantsTranslator.setConstants({
 *   COLOR: {
 *     RED: "Red",
 *     GREEN: "Green",
 *     BLUE: "Blue"
 *   }
 * }, C);
 *
 * // Note that we bound to variable C. In this case the constants values are ['COLOR.RED',
 * // 'COLOR.GREEN', 'COLOR.BLUE'], corresponding to the paths in the translation files.
 * $scope.colorOptions = [C.COLOR.RED, C.COLOR.GREEN, C.COLOR.BLUE];
 *
 * And when translating:
 *
 * <select>
 *   <option ng-repeat="color in colors">{{ color | translate }}</option>
 * </select>
 *
 * Now, if we actually want to check for the value of C.COLOR.RED (if that one was chosen from the
 * options) we'd get the value by using "constants.getValue(C.COLOR.RED)".
 */
dock.service('ConstantsTranslator', function () {
    this.constants = {}
    this.constantsFlattened = {}

    /**
     * Sets the constants and adds properties for translations. The constant key names as strings
     * are available under this. This ensures that the constant name can be used (as string) in the
     * translation files. Therefore the flattened keys directly correspond to the keys in the
     * translations file
     *
     * @param constants The constants to set
     * @param [bindTo] Where to bind the constants to, next to the ConstantsTranslator instance.
     * Note that only the constant names get bound to the bindTo object, not the values. To retrieve
     * the values you need getValue().
     * @return ConstantTranslator this
     */
    this.setConstants = function setConstants(constants, bindTo) {
        const flattenedConstants = flatten(constants)
        this.constantsFlattened = angular.copy(flattenedConstants)

        // Set every constant value to the key (string)
        Object.keys(flattenedConstants).forEach((flattenedKey) => {
            flattenedConstants[flattenedKey] = flattenedKey
        })

        const readyToBindConstants = unflatten(flattenedConstants)

        // Bind to this and bindTo
        const self = this
        Object.keys(readyToBindConstants).forEach((constantKey) => {
            self[constantKey] = readyToBindConstants[constantKey]

            if (bindTo !== undefined) {
                bindTo[constantKey] = readyToBindConstants[constantKey]
            }
        })

        this.constants = constants

        return this
    }

    /**
     * Returns the actual value behind a constant
     *
     * @param constantKey The constant key name
     * @returns {*} The value of the constant
     */
    this.getValue = function getValue(constantKey) {
        return this.constantsFlattened[constantKey]
    }

    /**
     * Function that generates a constant map. Which is a difficult word for reversing (part of)
     * this.constantsFlattened. Example:
     *
     * // Suppose this is the case:
     * this.constantsFlattened = {
     *   'COLOR.RED': 'Red',
     *   'COLOR.BLUE': 'Blue'
     * }
     *
     * // Let constants be the returned value of setConstants()
     * var map = constants.generateConstantMap();
     *
     * // map: {
     * //   'Red': 'COLOR.RED',
     * //   'Blue': 'COLOR.BLUE'
     * // }
     *
     * // Let C be the bindTo argument from setConstants()
     * var reducedMap = constants.generateConstantsMap(C.COLOR.RED);
     *
     * // reducedMap: {
     * //   'Red': 'COLOR.RED'
     * // }
     *
     * @param {...String} [constantRestrictions] The constants to restrict the map to
     * @returns {Object} The constant map
     */
    this.generateConstantMap = function generateConstantMap(
        constantRestrictions,
        ...args
    ) {
        /* eslint-enable */
        let constantKeys
        const constantMap = {}

        // If no arguments are given, make a constant map of all constants
        if (arguments.length === 0) {
            constantKeys = Object.keys(this.constantsFlattened)
        } else {
            constantKeys = [constantRestrictions, ...args]
        }

        const self = this
        constantKeys.forEach(function (constantKey) {
            constantMap[self.constantsFlattened[constantKey]] = constantKey
        })

        return constantMap
    }
})
