import '../../factories/api'
import dock from '../../modules/dock'

const controllerName = 'RestrictedBrandCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    $location,
    $uibModalInstance,
    brand,
) {
    $scope.brand = brand

    $scope.linkProduct = function () {
        $location.path('link')
        $uibModalInstance.dismiss()
    }
})
