import fs from 'fs'
import 'angular-i18n/angular-locale_nl'
import 'angular-i18n/angular-locale_en-gb'
import dock from '../modules/dock'

/* eslint-disable */
const dutchLocale = fs.readFileSync(
    __dirname + '/../../../node_modules/angular-i18n/angular-locale_nl.js'
    , 'utf-8')
const englishLocale = fs.readFileSync(
    __dirname + '/../../../node_modules/angular-i18n/angular-locale_en-gb.js'
    , 'utf-8')
/* eslint-enable */

function base64Encode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str)
        .replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
            return String.fromCharCode(`0x${p1}`)
        }))
}

function base64File(contents) {
    return `data:text/javascript;base64,${base64Encode(contents)}`
}

dock.config(/* @ngInject */ function (tmhDynamicLocaleProvider) {
    const locales = {
        nl: base64File(dutchLocale),
        'en-gb': base64File(englishLocale),
    }

    // Define the locale pattern for Angular
    tmhDynamicLocaleProvider.addLocalePatternValue('base64Locales', locales)
    tmhDynamicLocaleProvider.localeLocationPattern('{{base64Locales[locale]}}')
})
