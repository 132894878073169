import '../factories/api'
import '../directives/dockFilterBar/dockFilterBar'
import '../directives/sortable/sortable'
import '../directives/includeReplace/includeReplace'
import '../directives/productImage/productImage'
import '../services/ConstantsTranslator'
import '../services/Data'
import '../services/GetPropertyList'
import '../services/Tab'
import '../filters/toConstant'
import dock from '../modules/dock'
import { Product } from '../shared/classes/Product'
import Tab from '../shared/classes/Tab'

const controllerName = 'ReturnsCtrl'
export default controllerName

export const ReturnStatus = Object.freeze({
    OPEN: 'open', // A collection of all statuses saying the return is open
    CLOSED: 'completed', // A collection of statuses saying the return is closed
})

export const ReturnsTabs = Object.freeze({
    NEW: new Tab('returns.tabs.new', 'new'),
    CLOSED: new Tab('returns.tabs.closed', 'closed'),
})

export const ReturnsTabStatusMapping = new Map([
    [ReturnsTabs.NEW, ReturnStatus.OPEN],
    [ReturnsTabs.CLOSED, ReturnStatus.CLOSED],
])

export const ReturnsTabStatusFilterOptionsMapping = new Map([
    [ReturnsTabs.NEW, [
        'RETURN.STATUS.received',
        'RETURN.STATUS.authorized',
    ]],
    [ReturnsTabs.CLOSED, [
        'RETURN.STATUS.closed',
    ]],
])

/* eslint-disable */
dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    $q,
    $location,
    $routeParams,
    api,
    ConstantsTranslator,
    GetPropertyList,
    Data,
    Tab,
) {

    const STATE = ($scope.STATE = {
        LOADING: 'loading',
        IDLE: 'idle',
        ERROR: 'error',
    })

    let C = {}
    const today = new Date();
    const constants = ($scope.constants = ConstantsTranslator.setConstants(
        {
            RETURN: {
                TYPE: {
                    EXCHANGE: 'Ruilen',
                    REFUND: 'Terugbetalen',
                },
                STATUS: {
                    "pending": "PENDING",
                    "authorized": "AUTHORIZED",
                    "partially_authorized": "PARTIAL_AUTHORIZED",
                    "received": "RECEIVED",
                    "received_on_item": "RECEIVED_ON_ITEM",
                    "approved": "APPROVED",
                    "approved_on_item": "APPROVED_ON_ITEM",
                    "rejected": "REJECTED",
                    "rejected_on_item": "REJECTED_ON_ITEM",
                    "denied": "DENIED",
                    "closed": "CLOSED",
                    "processed_closed": "PROCESSED_CLOSED",
                    "received_return_center": "RECEIVED_RETURN_CENTER",
                    "processed_return_center": "PROCESSED_RETURN_CENTER"
                },
                DATE: {
                    LAST_7_DAYS: new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - 7
                    ),
                    LAST_14_DAYS: new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - 14
                    ),
                    LAST_30_DAYS: new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - 30
                    ),
                },
            },
        },
        C,
    ))

    $scope.constantMaps = {
        resolutionType: constants.generateConstantMap(
            C.RETURN.TYPE.REFUND,
            C.RETURN.TYPE.EXCHANGE,
        ),
    }

    // These are set by getSortItems();
    $scope.sortItems = {}
    $scope.state = STATE.LOADING
    $scope.requestCanceler = null
    $scope.ReturnsTabs = ReturnsTabs
    $scope.Tab = Tab

    const loadData = function () {
        loadReturns(Tab.active)
    }

    const loadReturns = ($scope.loadReturns = function (tab) {
        if (!tab) {
            return
        }

        $scope.state = STATE.LOADING
        $scope.requestCanceler = $q.defer()
        const status = ReturnsTabStatusMapping.get(tab)
        api.returns
            .getReturns(status, $scope.requestCanceler, filters, $scope.activeSort, $scope.pagination)
            .then(response => {
                if (!response.data.returns) {
                    const returns = response.data;
                    response.data = {
                        returns
                    };
                }
                $scope.pagination.totalCount = response.data.totalCount || response.data.returns.length
                if (response.data.currentPage && returns.pagination.pageModel !== response.data.currentPage) {
                    // override the local page with what we get from the API
                    returns.pagination.pageModel = response.data.currentPage
                    returns.pagination.setPreference('current_page', returns.pagination.pageModel)
                }
                const responseReturns = response.data.returns.map(setReturnMetadata)
                    .map(returnInstance => {
                        // <IWSNL-8881> Add fallback for reference ID's
                        returnInstance.rmaReferenceId = returnInstance.rmaReferenceId ?? returnInstance.id
                        returnInstance.orderReferenceId = returnInstance.orderReferenceId ?? returnInstance.orderId.orderId
                        return returnInstance
                        // </IWSNL-8881>
                    })

                $scope.state = STATE.IDLE
                returns
                    .setData(responseReturns)
                loadFilterOptions(filters, returns)
            })
            .catch(error => {
                console.error(error)
                $scope.state = STATE.ERROR
            })
    })


    // Configuration for the Filter Controller
    const filterControllerConfig = {
        filterOnSelect: false,
        executeCallback: () => {
            $scope.pagination.page = 1
            loadData()
        },
    }
    // Options for the filter bar
    const filterBarOptions = {
        searchButton: false,
        executeButton: true,
    }

    const filterTranslationPrefix = 'returns.headers'
    const filters = getReturnsFilters(Tab.active)
    const sortItems = getReturnsSortItems()
    const filterController = new Data.Filters.Controller(filters, filterControllerConfig);
    const paginationController = new Data.Pagination.Controller()
    const selectionController = new Data.Selection.Controller()
    const sortingController = new Data.Sorting.Controller(sortItems)
    const returns = ($scope.returns = new Data.DataController(
        filterController,
        paginationController,
        selectionController,
        sortingController,
    ))
    $scope.filterBarOptions = filterBarOptions;

    // start with the natural sorting by the backend
    const sortingPreference = returns.sorting.getPreference()
    let sortField = null
    let sortOrder = null
    if (sortingPreference) {
        sortField = sortingPreference.identifier;
        sortOrder = (sortingPreference.reverse) ? 'desc' : 'asc'
    }
    $scope.activeSort = {
        sortField,
        sortOrder
    };
    $scope.pagination = {
        page: returns.pagination.pageModel || 1,
        itemsPerPage: returns.pagination.getPreference('items_per_page') || 20
    }

    sortingController.onSort((data, active) => {
        const sortField = active.getIdentifier();
        const sortOrder = (active.reverse) ? 'desc' : 'asc'

        let shouldReload = false

        if ($scope.activeSort.sortField !== sortField || $scope.activeSort.sortOrder !== sortOrder) {
            shouldReload = true
        }
        $scope.activeSort = {
            sortField,
            sortOrder
        }
        if (shouldReload) {
            loadData();
        }
    });

    $scope.changePage = function () {
        const oldPage = $scope.pagination.page;
        const shouldReload = oldPage !== returns.pagination.pageModel;
        $scope.pagination.page = returns.pagination.pageModel
        if (shouldReload) {
            loadData();
        }
    }

    paginationController.onItemsPerPageChange(itemsPerPage => {
        const oldItemsPerPage = $scope.pagination.itemsPerPage;
        const shouldReload = oldItemsPerPage !== itemsPerPage;
        $scope.pagination.itemsPerPage = itemsPerPage
        if (shouldReload) {
            $scope.pagination.page = 1
            loadData();
        }
    });
    $scope.getDetailsForReturn = function (returnInstance) {
        returnInstance.isCollapsed = !returnInstance.isCollapsed

        if (!returnInstance.isCollapsed) {
            returnInstance.loading = true
            returnInstance.error = false

            api.returns.getReturn(returnInstance.rmaReferenceId)
                .then((response) => {
                    returnInstance.orderItems = response.data.items.map(item => {
                        item.product = new Product(item.product)

                        return item
                    })
                    // <IWSNL-8881> Add fallback for reference ID's
                    returnInstance.rmaReferenceId = returnInstance.rmaReferenceId ?? returnInstance.id
                    returnInstance.orderReferenceId = returnInstance.orderReferenceId ?? returnInstance.orderId.orderId
                    // </IWSNL-8881>
                })
                .catch(() => {
                    returnInstance.error = true
                })
                .finally(() => {
                    returnInstance.loading = false
                })
        }
    }

    function setReturnMetadata(returnInstance) {
        returnInstance.isCollapsed = true
        returnInstance.customerName =
            returnInstance.customer.firstName +
            ' ' +
            returnInstance.customer.lastName
        return returnInstance
    }

    /**
     * Returns the filters for the returns page.
     *
     * @returns {Filter[]} The filter objects specific for the orders page
     */
    function getReturnsFilters(tab) {
        return [
            getTypeFilter(),
            getCustomerFilter(),
            getReturnNumberFilter(),
            getOrderNumberFilter(),
            getReturnDateFilter(),
            getReturnStatusFilter(tab)
        ]
    }

    function getReturnsSortItems() {
        const date = new Data.Sorting.SortItem({
            property: 'dateRequested',
            sortFunction: 'date',
        })

        const customer = new Data.Sorting.SortItem({
            property: 'customerName',
        })

        const returnNumber = new Data.Sorting.SortItem({
            property: 'id',
            sortFunction: 'stringFast',
        })

        const orderNumber = new Data.Sorting.SortItem({
            property: 'orderReferenceId',
            sortFunction: 'stringFast',
        })

        const status = new Data.Sorting.SortItem({
            property: 'statusLabel',
            sortFunction: 'stringFast',
        })

        $scope.sortItems = {
            date: date,
            customer: customer,
            returnNumber: returnNumber,
            orderNumber: orderNumber,
            status: status,
        }

        return [date, customer, returnNumber, orderNumber, status]
    }

    function loadFilterOptions(filters, returns) {
        const unfixedFilters = filters.filter(filter => {
            return !filter.config.options.fixed && !filter.config.searchOnly
        })

        GetPropertyList.loadFixedFilterOptions(unfixedFilters, returns)
    }

    function getTypeFilter() {
        const name = 'type'
        const translationLabel = `${filterTranslationPrefix}.resolution`
        const property = 'resolution'
        const options = [C.RETURN.TYPE.EXCHANGE, C.RETURN.TYPE.REFUND]

        const config = {
            options: {
                fixed: true,
                translate: true,
                mapFunction: filter => {
                    return constants.getValue(filter)
                },
            },
            search: false,
        }

        return new Data.Filters.Filter(
            name,
            translationLabel,
            property,
            options,
            config,
        )
    }

    function getCustomerFilter() {
        const name = 'customer'
        const translationLabel = `${filterTranslationPrefix}.customer`
        const property = 'customerName'
        const options = []
        const config = { searchOnly: true };

        return new Data.Filters.Filter(
            name,
            translationLabel,
            property,
            options,
            config,
        )
    }

    function getReturnNumberFilter() {
        const name = 'returnNumber'
        const translationLabel = `${filterTranslationPrefix}.returnNumber`
        const property = 'id'
        const options = []
        const config = { searchOnly: true };

        return new Data.Filters.Filter(
            name,
            translationLabel,
            property,
            options,
            config,
        )
    }

    function getOrderNumberFilter() {
        const name = 'orderNumber'
        const translationLabel = `${filterTranslationPrefix}.orderNumber`
        const property = 'orderReferenceId'
        const options = []
        const config = { searchOnly: true };

        return new Data.Filters.Filter(
            name,
            translationLabel,
            property,
            options,
            config,
        )
    }

    function getReturnDateFilter() {
        const name = "rmadate";
        const translationLabel = `${filterTranslationPrefix}.rmadate`;
        const property = "rmadate";
        const options = [
            C.RETURN.DATE.LAST_7_DAYS,
            C.RETURN.DATE.LAST_14_DAYS,
            C.RETURN.DATE.LAST_30_DAYS,
        ];

        const config = {
            options: {
                fixed: true,
                translate: true,
                mapFunction: (filter) => {
                    return constants.getValue(filter);
                },
            },
            filterFunction: function (returns, filter) {
                const selected = filter.getSelected(true, true);

                // Add the dateMilliseconds property if it doesn't exist
                if (returns.dateMilliseconds === undefined) {
                    returns.dateMilliseconds = new Date(returns.dateTime).getTime();
                }

                return returns.dateMilliseconds > selected.getTime();
            },
            search: false,
        };

        return new Data.Filters.Filter(
            name,
            translationLabel,
            property,
            options,
            config
        );
    }

    function getReturnStatusFilter(tab) {
        const name = 'rmastatus'
        const translationLabel = `${filterTranslationPrefix}.rmastatus`
        const property = 'rmastatus'
        const options = ReturnsTabStatusFilterOptionsMapping.get(tab)

        const config = {
            options: {
                fixed: true,
                translate: true,
                mapFunction: filter => {
                    return constants.getValue(filter)
                },
            },
            search: false,
        }

        const filter = new Data.Filters.Filter(
            name,
            translationLabel,
            property,
            options,
            config,
        )
        filter.setDisabled(options.length < 2)

        return filter
    }

    $scope.$on('$destroy', () => $scope.requestCanceler ? $scope.requestCanceler.resolve() : false)

    loadReturns(Tab.active)
})
