import angular from 'angular'
import dock from '../../modules/dock'
import productCategoryTemplate from './productCategory.html'
import './productCategory.scss'

dock.directive('productCategory', function () {
    return {
        restrict: 'E',
        scope: {
            product: '=',
        },
        template: productCategoryTemplate,
        link(scope, element) {
            element.addClass('product-category')

            const categoryProperties = [
                [scope.product.mainCategory, scope.product.hoofdCategorie],
                [scope.product.category, scope.product.categorie],
                [scope.product.subCategory, scope.product.subCategorie],
            ]

            scope.categories = categoryProperties
                // Find the active property
                .map((similarProperties) => similarProperties.filter((property) => !!property && !angular.equals(property, {})))
                .filter((similarProperties) => similarProperties.length > 0)
                .map((similarProperties) => similarProperties[0])
                .map(({ code }) => code)
                .filter((code) => code !== null)
        },
    }
})
