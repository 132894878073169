import dock from '../modules/dock'

const controllerName = 'DocumentationCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function ($scope) {
    $scope.documents = [
        {
            titleKey: 'documentation.handbook.header',
            urlKey: 'documentation.handbook.url',
            icon: 'fa-file-text',
            event: 'manual',
        }, {
            titleKey: 'documentation.order.header',
            urlKey: 'documentation.order.url',
            icon: 'fa-truck',
            event: 'packing materials',
        }, {
            titleKey: 'documentation.photo.header',
            urlKey: 'documentation.photo.url',
            icon: 'fa-camera',
            event: 'photo studio schedule',
        }, {
            titleKey: 'documentation.brand.header',
            urlKey: 'documentation.brand.url',
            icon: 'fa-plus',
            event: 'add new brand',
        }, {
            titleKey: 'documentation.product.header',
            urlKey: 'documentation.product.url',
            icon: 'fa-pencil',
            event: 'request product change',
        },
    ]
})
