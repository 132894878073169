import '../../factories/api'
import dock from '../../modules/dock'

/* eslint-disable */
const controllerName = 'SaleStopCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    api,
    $uibModalInstance,
    actionList,
    changeTracker,
) {
    var constants = {
        STATE: {
            READY: 0,
            PROCESSING: 1,
            PROCESSED: 2,
            PROCESS_ERROR: 3,
        },
    }

    $scope.constants = constants
    $scope.actionList = actionList
    $scope.state = constants.STATE.READY

    $scope.errorInfo = {
        errors: [],
        products: [],
    }

    $scope.stopSales = function() {
        $scope.state = constants.STATE.PROCESSING

        stopSales(0, function() {
            if ($scope.errorInfo.errors.length > 0) {
                $scope.state = constants.STATE.PROCESS_ERROR
            } else {
                $scope.state = constants.STATE.PROCESSED
            }
        })
    }

    var stopSales = function(index, callback) {
        if ($scope.actionList[index]) {
            var product = $scope.actionList[index].getRequestProduct()
            product.saleKortingVan = ''
            product.saleKortingTot = ''
            product.saleKortingPercentage = null

            $scope.progress = {
                product: product,
                index: index,
            }

            $scope.progressPerc = Math.floor(
                (index / $scope.actionList.length) * 100,
            )

            api.products
                .updateProduct(product)
                .catch(function(error) {
                    $scope.errorInfo.errors.push(error)
                    $scope.errorInfo.products.push(product)
                })
                .finally(function() {
                    var j = index + 1
                    changeTracker.markChanged()
                    stopSales(j, callback)
                })
        } else {
            callback()
        }
    }

    $scope.close = function() {
        $uibModalInstance.close()
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss()
    }
})
