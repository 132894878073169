import dock from '../modules/dock'
import '../directives/productsEditor/productsEditor'
import ProductsEditorController from '../directives/productsEditor/ProductsEditorController'
import { Product } from '../shared/classes/Product'
import showFormFieldError from '../shared/functions/forms'
import { normalizeSku } from '../shared/functions/sku'
import getMessagesFromObject from '../shared/functions/getMessagesFromObject'

/* eslint-disable */
class _ReturnItemAction {
    /**
     * Creates a new return action
     *
     * @param value The value the return item action has in the backend
     * @param translationKey The translation key for the return item action
     */
    constructor(value, translationKey) {
        this.value = value
        this.translationKey = translationKey
    }
}

class ReturnedItem {
    /**
     * Creates a new returned item object
     *
     * @param {String} orderItemId The order item id
     * @param {String} sku=null The SKU you want to exchange the item with
     */
    constructor(orderItemId, sku = null) {
        this.orderItemId = orderItemId
        this.exchangementSKU = sku
    }
}

// All different types of tables to be displayed
export const TableType = Object.freeze({
    PROCESSED: Symbol('processed'), // The table to display for processed items
    UNPROCESSED: Symbol('unprocessed'), // The table to display for unprocessed items
})

// The types of return we could be dealing with
export const ReturnType = Object.freeze({
    RMA: Symbol('rma'), // There is already a return ID because the customer created the return
    ORDER: Symbol('order'), // There is no return ID and the retailer received a returned package and wants to create a return for an order
})

// The possible product form states
export const ReturnState = Object.freeze({
    LOADING: Symbol('loading'), // The return is loading
    READY: Symbol('ready'), // The return is ready and visible
    ERROR: Symbol('error'), // An error occurred while loading the return
    PROCESSING: Symbol('processing'), // The return is being processed
    PROCESSED: Symbol('processed'), // The return was processed
    PROCESSED_RELOADED: Symbol('processed'), // The return was processed and reloaded
    PROCESS_ERROR: Symbol('process_error'), // An error occurred while processing the return,
    SAVING_PRODUCT: Symbol('saving_product'), // The user is saving an edited product
    SAVE_PRODUCT_ERROR: Symbol('save_product_error'), // An error occurred while saving the updated product
})

// The possible return actions for an unprocessed item
export const ReturnItemAction = Object.freeze({
    REFUND: new _ReturnItemAction('refund', 'return.orders.refund'), // The user gets a refund
    NO_REFUND: new _ReturnItemAction('noRefund', 'return.orders.noRefund'), // The user gets no refund because it was a faulty return
    EXCHANGE: new _ReturnItemAction('exchange', 'return.orders.exchange'), // The user gets the item exchanged
})

const controllerName = 'ReturnCtrl'
export default controllerName

dock.controller(controllerName, /* @ngInject */ function (
    $scope,
    $q,
    $routeParams,
    $route,
    $location,
    $translate,
    toastr,
    api,
) {
    // Add the state and error objects to the scope
    $scope.state = ReturnState.READY
    $scope.State = ReturnState
    $scope.errorMessages = null

    // Add the return type to the scope
    $scope.type = _getReturnType()
    $scope.Type = ReturnType

    // Expose the types of tables to the scope
    $scope.TableType = TableType

    // Expose the return actions and add the exchange action when we're dealing with an RMA and not an order return type.
    $scope.ReturnAction = ReturnItemAction
    $scope.returnActions = [ReturnItemAction.REFUND, ReturnItemAction.NO_REFUND]

    if ($scope.type === ReturnType.RMA) {
        $scope.returnActions.push(ReturnItemAction.EXCHANGE)
    }

    // Expose the form field functions
    $scope.showError = showFormFieldError

    // Create a controller that can handle communication between the products editor directive and this controller
    const productsEditorController = ($scope.productsEditorController = new ProductsEditorController())

    // Create return and refund model
    $scope.return = {
        rmaReferenceId: $routeParams.rmaReferenceId,
        comments: '',
    }

    $scope.refundTotal = 0

    // Load the resources
    $scope.loadResources = function() {
        $scope.state = ReturnState.LOADING
        $scope.requestCanceler = $q.defer()

        let request
        if ($scope.type === ReturnType.ORDER) {
            request = _loadOrder($routeParams.orderReferenceId, $routeParams.orderIdSource, $scope.requestCanceler)
        } else {
            request = _loadReturn(
                $scope.return.rmaReferenceId,
                $scope.requestCanceler,
            )
        }

        request
            .then(() => ($scope.state = ReturnState.READY))
            .catch(_handleError)
    }

    $scope.loadResources()

    // Updates the refund total below the feedback textarea
    $scope.updateRefundTotal = function() {
        $scope.refundTotal = _getRefundTotal()
    }

    // Returns true if there are only refund actions for items
    $scope.hasNoRefundItems = function() {
        return $scope.returnedItems().filter(_filterNoRefund).length > 0
    }

    // Returns the amount of items that were marked as returned
    $scope.returnedItems = function() {
        return $scope.order.items.unprocessed.filter(_filterReturned)
    }

    // Point the browser to the orders page with the given order opened
    $scope.viewOrder = function(orderReferenceId) {
        $location
            .path('/orders/search')
            .search({ query: orderReferenceId, expandedOrder: orderReferenceId })
    }

    // Checks the return form and then calls the API to process the return
    $scope.processReturn = function(returnForm) {
        $scope.state = ReturnState.READY
        $scope.errorMessages = null

        if (returnForm.$invalid) {
            $translate('return.toast.validation').then(toastr.warning)
            return
        }

        const postData = _getRequestPostData()

        $scope.state = ReturnState.PROCESSING
        $scope.requestCanceler = $q.defer()

        api.returns
            .returnAction(
                $scope.order.orderReferenceId,
                $scope.order.source,
                postData,
                $scope.requestCanceler,
            )
            .then(() => {
                // if we have some orderItemsWithoutProductId we process them (set their stock to 0)
                if ($scope.orderItemsWithoutProductId.length) {
                    const variantStock = _getVariantStockForOrderItems($scope.orderItemsWithoutProductId)
                    api.products.updateVariantBulkStock(variantStock)
                }
            })
            .then(() => {
                // if we have no products to display an editor for we reload the return directly
                $translate('return.headerHandled').then(toastr.success)
                if (!$scope.returnedProductVariationIds.length) {
                    return _loadReturn($scope.return.rmaReferenceId, $scope.requestCanceler)
                        .then(() => {
                            $scope.state = ReturnState.PROCESSED_RELOADED
                        })
                } else {
                    $scope.state = ReturnState.PROCESSED
                }
            })
            .catch(_handleError)
    }

    // Returns to
    $scope.goBack = function() {
        $location.path($scope.type === ReturnType.RMA ? 'returns' : 'orders')
    }

    // Emits the on save event on the controller to initiate requests to save changes
    $scope.updateProduct = function() {
        let promises = productsEditorController.emitOnSaveEvent()

        $scope.state = ReturnState.SAVING_PRODUCT
        $q.all(promises)
            .then(() => {
                $translate('product.toast.saved').then(toastr.success)
                $scope.goBack()
            })
            .catch(_handleError)
    }

    // Returns the right translation key for the loading message given the ReturnState
    $scope.getLoadingTextFor = function(state) {
        switch (state) {
            case ReturnState.LOADING:
                return 'return.loading'
            case ReturnState.PROCESSING:
                return 'return.processing'
            case ReturnState.SAVING_PRODUCT:
                return 'product.edit.actions.save'
        }
    }

    // Loads an order and adds its information to the scope
    function _loadOrder(orderReferenceId, orderIdSource, canceler) {
        return api.orders
            .getOrder(orderReferenceId, orderIdSource, canceler)
            .then(response => response.data)
            .then(order => {
                $scope.order = order
                // <IWSNL-8881> Add fallback for reference ID's
                order.items.map(orderItem => {
                    orderItem.orderReferenceId = orderItem.orderReferenceId ?? orderReferenceId
                    return orderItem
                })
                // </IWSNL-8881>
                $scope.order.items = _wrapProductClass($scope.order.items)
                $scope.order.items = _splitReturnedItems(order.items)
                _markAllItemsAsReturned($scope.order.items.unprocessed)
            })
    }

    // Loads a return, splits its items per table type, loads their stock and exposes adds its information to the scope
    function _loadReturn(rmaReferenceId, canceler) {
        return api.returns
            .getReturnWithOrder(rmaReferenceId, canceler)
            .then(response => response.data)
            .then(returnInstance => {
                // <IWSNL-8881> Add fallback for reference ID's
                returnInstance.rmaReferenceId = returnInstance.rmaReferenceId ?? returnInstance.id
                returnInstance.orderReferenceId = returnInstance.orderReferenceId ?? returnInstance.orderId.orderId
                // </IWSNL-8881>

                const stockRequests = $q.all(
                    returnInstance.items
                        .filter(({ product }) => product.id !== null)
                        .map(_getProductStockForItem),
                )

                $scope.order = returnInstance
                $scope.order.items = _wrapProductClass($scope.order.items)
                $scope.order.items = _splitReturnedItems(returnInstance.items)
                _markAllItemsAsReturned($scope.order.items.unprocessed)

                return stockRequests
            })
    }

    // Gets the product stock for an item, doesn't catch to throw the error further up the promise chain
    function _getProductStockForItem(item) {
        return api.products
            .getProductStock(item.product, null, true)
            .then(response => response.data.variaties)
            .then(variants => (item.product.variants = variants))
    }

    function _wrapProductClass(items) {
        return items.map(item => {
            item.product = new Product(item.product)

            return item
        })
    }

    // Splits the items of a return into processed and unprocessed items
    function _splitReturnedItems(items) {
        return {
            processed: items.filter(({ processed }) => processed),
            unprocessed: items.filter(({ processed }) => !processed),
        }
    }

    // Returns true if an item was returned
    function _filterReturned(item) {
        return item.processed
    }

    // Returns true if an item was marked to be refunded
    function _filterRefund(item) {
        return item.action === ReturnItemAction.REFUND
    }

    // Returns true if an item was marked to not be refunded nor be exchanged
    function _filterNoRefund(item) {
        return item.action === ReturnItemAction.NO_REFUND
    }

    // Returns true if an item was marked to be exchanged
    function _filterExchange(item) {
        return item.action === ReturnItemAction.EXCHANGE
    }

    // Returns the total refund costs
    function _getRefundTotal() {
        return $scope
            .returnedItems()
            .filter(_filterRefund)
            .reduce((refundTotal, item) => {
                return refundTotal + item.product.price
            }, 0)
    }

    // Functions that handles an error when caught by a promise
    function _handleError(response) {
        $scope.state = _getErrorStateFor($scope.state)

        const errorData = response.data
        console.error(errorData)
        $scope.errorMessages = getMessagesFromObject(errorData)
    }

    // Returns the correct error state for a given ReturnState
    function _getErrorStateFor(state) {
        switch (state) {
            case ReturnState.PROCESSING:
                return ReturnState.PROCESS_ERROR
            case ReturnState.SAVING_PRODUCT:
                return ReturnState.SAVE_PRODUCT_ERROR
            default:
                return ReturnState.ERROR
        }
    }

    // Gets the return type by looking at the route parameters
    function _getReturnType() {
        return $routeParams.rmaReferenceId ? ReturnType.RMA : ReturnType.ORDER
    }

    function _getRequestPostData() {
        const postData = {
            ...$scope.return,
            totalCashbackAmount: _getRefundTotal(),

            orderItemsReturned: $scope
                .returnedItems()
                .map(_mapToReturnedItem),

            orderItemsCashback: $scope
                .returnedItems()
                .filter(_filterRefund)
                .map(_mapToReturnedItem),

            orderItemsNoRefund: $scope
                .returnedItems()
                .filter(_filterNoRefund)
                .map(_mapToReturnedItem),

            orderItemsExchange: $scope
                .returnedItems()
                .filter(_filterExchange)
                .map(_mapToExchangeItem),
        }

        // Set products editor variables
        $scope.returnedProductIds = $scope
            .returnedItems()
            .filter(_filterReturned)
            .filter(({ product }) => product.id !== null )
            .map(({ product }) => product.id)

        $scope.returnedProductVariationIds = $scope
            .returnedItems()
            .filter(_filterReturned)
            .filter(({ product }) => product.variantId !== null)
            .map(({ product }) => product.variantId)
        
        // Gather returned items without productId. Stock for these products will be
        // updated automatically instead of manually with a product variants editor
        $scope.orderItemsWithoutProductId = $scope
            .returnedItems()
            .filter(_filterReturned)
            .filter(({ product }) => product.id === null )

        // If we have only refund or exchange items
        if (!$scope.hasNoRefundItems()) {
            postData.comments = ''
        }

        return postData
    }

    function _mapToReturnedItem({ orderItemId }) {
        return new ReturnedItem(orderItemId)
    }

    function _mapToExchangeItem({ orderItemId, exchangeVariant }) {
        return new ReturnedItem(orderItemId, exchangeVariant.SKU)
    }

    function _markAllItemsAsReturned($items) {
        $items.forEach(item => {
            item.returned = true;
            item.processed = true
        })
    }
    
    function _getVariantStockForOrderItems($items, quantity = 0) {
        return $items.map(({ product }) => {
            return {
                'sku': product.SKU,
                'quantity': quantity,
            }
        })
    }

    $scope.$on('$destroy', () => $scope.requestCanceler ? $scope.requestCanceler.resolve() : false)
})
