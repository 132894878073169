import { normalizeSku, isContentApiSku } from '../functions/sku'
import { privatePrefix, ResponseWrapper } from './ResponseWrapper'

export class ProductVariant extends ResponseWrapper {
    /**
     * Creates a new product variant model that can be used throughout DOCK
     *
     * @param {Object} data The data returned from a request that needs to be transferred to a product variant
     */

    constructor(data) {
        super(data)
        this.setContentApiProperties()
    }

    getSku() {
        return this[ProductVariant.getContentApiSkuProperty()]
    }

    getOriginalSku() {
        return this.SKU
    }

    getRequestVariant(excludeSku) {
        const excludeProperties = excludeSku ? ['SKU'] : []
        return this.getRequestVersion(excludeProperties)
    }

    priceChanged() {
        const original = this[ProductVariant.getOriginalProperty()]

        return this.price !== original.price
               || this.manufacturerSuggestedVariantPrice !== original.manufacturerSuggestedVariantPrice
    }

    /**
     * Sets some content API related fields using the SKU to check if we're dealing with a content API product
     */
    setContentApiProperties() {
        this[ProductVariant.getContentApiProperty()] = isContentApiSku(this.SKU)
        this[ProductVariant.getContentApiSkuProperty()] = normalizeSku(this.SKU)
    }

    static getContentApiProperty() {
        return `${privatePrefix}fromContentApi`
    }

    static getContentApiSkuProperty() {
        return `${privatePrefix}contentApiSku`
    }
}
